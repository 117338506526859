/* eslint-disable react-hooks/exhaustive-deps */
import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import {
  DepartmentAppType,
  DepartmentType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { LocationContext } from '../../../../contexts/location';
import { UserContext } from '../../../../contexts/user';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseDepartment } from '../../../../hooks/hrd/department/use-department';
import { UseDepartments } from '../../../../hooks/hrd/department/use-departments';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UserRendererInterface } from '../../../common/fragments/user';
import {
  DepartmentFormType,
  renderAddDepartmentForm,
} from './form/add-department';

type RenderDepartment = {
  useDepartments: UseDepartments;
  useDepartment: UseDepartment;
  useEmployees: UseEmployeeProfiles;
  useUserAuthorization: UseUserAuthorization;
  userRenderService: UserRendererInterface;
};
export const renderDepartmentList = ({
  useDepartments,
  useDepartment,
  useEmployees,
  useUserAuthorization,
  userRenderService,
}: RenderDepartment) => {
  return function DepartmentList() {
    const { data: departments, getAll: getDepartments } = useDepartments();

    const { syncSet: setDepartment } = useDepartment();

    const { user: currentUser } = useContext(UserContext);
    const { company, companyGroup } = useContext(LocationContext);

    useEffect(() => {}, [departments]);

    useEffect(() => {
      getDepartments();
      // eslint-disable-next-line
    }, []);

    const { get: getAllowedActions } = useUserAuthorization();

    const Form = renderAddDepartmentForm({
      useEmployees,
      useDepartments,
      useDepartment,
    });

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Departments',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Department',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      saveDepartment(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const onEditDepartment = async (form: DepartmentFormType, id: string) => {
      const finalData = {
        id,
        name: form?.name || '',
        description: form?.description || '',
        hod: form?.hod || ({ id: '', name: '' } as UserIdName),
        parentDepartmentId: form?.parentDepartmentId?.id || null,
        details: {
          expenseType: form.expenseType,
        },
      } as DepartmentAppType;
      await setDepartment(finalData);
      toast('Department edited sucessfully');
      // setModal((ms) => ({ ...ms, isVisible: false }));
      getDepartments();
    };

    const saveDepartment = async (form: DepartmentFormType) => {
      try {
        const finalData = ({
          ...form,
          parentDepartmentId: form?.parentDepartmentId?.id || null,
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          details: {
            expenseType: form.expenseType,
          },
          company: {
            id: company.id,
            name: company.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as unknown) as DepartmentAppType;
        await setDepartment(finalData as DepartmentAppType);
        // setModal((ms) => ({ ...ms, isVisible: false }));
        toast('Data added sucessfully');
        getDepartments();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'HOD' },
        { name: 'Parent Department Name' },
        { name: 'Description' },
        { name: 'Expense Type' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
      ],
    ];

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Edit',
          show: ({ department }: { department: DepartmentType }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.

            return true;
          },
          behaviour: 'modal',
          modal: {
            title: 'Edit',
            content: ({
              data: { department },
              onClose,
            }: {
              data: {
                department: DepartmentType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <Form
                    onSave={async (e) => {
                      await onEditDepartment(e, department.id);
                      onClose();
                    }}
                    data={department as any}
                  />
                </div>
              );
            },
          },
        },
      ];
    }

    const tableBody: TableBody =
      departments?.map((l, idx) => ({
        rowData: {
          department: l,
        },
        cells: [
          { value: l.name },
          { value: l.hod.name },
          {
            value: l?.parentDepartmentId
              ? departments.find((o) => o.id === l.parentDepartmentId)?.name ||
                '-'
              : '-',
          },
          { value: l.description },
          { value: (l.details as any).expenseType || '-' },
          {
            value: (
              <userRenderService.userCard
                link={true}
                size='small'
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={addActions()}
              auth={{ actions: getAllowedActions().actions }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div>
          <Card header={cardHeader} body={cardBody} />
        </div>
      </>
    );
  };
};
