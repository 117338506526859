import { RoleType } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { SalaryType } from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import moment from 'moment';

export function filter({
  employees,
  currentUserRoles,
  month,
  salaries,
}: {
  employees: EmployeeProfileType[];
  currentUserRoles: RoleType[];
  month: string;
  salaries: SalaryType[];
}): EmployeeProfileType[] {
  let filteredEmployees = [...employees];

  filteredEmployees = filteredEmployees.filter(
    (x) => x.details?.onboardToPayroll === 'yes'
  );

  filteredEmployees = filteredEmployees.filter((x) => {
    if (x.details.resigned !== 'yes') {
      return true;
    }

    const resignedDate = x.details.workTimeLines?.find(
      (x) => x.activity === 'resigned'
    )?.date;
    if (resignedDate && resignedDate.startsWith(month)) {
      return true;
    }

    if (
      resignedDate &&
      moment(resignedDate).isAfter(moment(month, 'YYYY-MM'))
    ) {
      return true;
    }

    return false;
  });
  if (
    !currentUserRoles.filter((x) => x.name === 'Executives Salary Officer')
      .length
  ) {
    const executiveLevelGrades = [
      'G13',
      'G14',
      'G15',
      'G16',
      'G17',
      'G18',
      'G19',
      'G20',
      'G21',
    ];
    filteredEmployees = filteredEmployees.filter((e) => {
      return !executiveLevelGrades.includes(e.details.grade?.id);
    });
  }

  // sort resigned and onstirke employees
  const resignedEmployees: EmployeeProfileType[] = [];
  const onStrikeEmployees: EmployeeProfileType[] = [];
  const finalFilteredEmployees: EmployeeProfileType[] = [];
  filteredEmployees
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach((x) => {
      if (x.details.resigned === 'yes') {
        resignedEmployees.push(x);
      } else if (x.details.status === 'on-strike') {
        onStrikeEmployees.push(x);
      } else {
        finalFilteredEmployees.push(x);
      }
    });

  const notCreatedSalaries: EmployeeProfileType[] = [];
  const pendingSalaries: EmployeeProfileType[] = [];
  const approvedNoBatchAssignedSalaries: EmployeeProfileType[] = [];
  const approvedBatchAssignedSalaries: EmployeeProfileType[] = [];
  const sentForReleaseSalaries: EmployeeProfileType[] = [];
  const releasedSalaries: EmployeeProfileType[] = [];

  finalFilteredEmployees.forEach((x) => {
    const sal = salaries.find(
      (y) => y.employee.id.toLowerCase() === x.id.toLowerCase()
    );

    if (!sal) {
      notCreatedSalaries.push(x);
    } else if (sal.status === 'pending') {
      pendingSalaries.push(x);
    } else if (sal.status === 'approved') {
      if (sal.releaseDetails?.batchNumber) {
        approvedBatchAssignedSalaries.push(x);
      } else {
        approvedNoBatchAssignedSalaries.push(x);
      }
    } else if (sal.status === 'sent-for-release') {
      sentForReleaseSalaries.push(x);
    } else if (sal.status === 'released') {
      releasedSalaries.push(x);
    } else {
      console.log('unknown salary status', x);
    }
  });

  return [
    ...notCreatedSalaries,
    ...pendingSalaries,
    ...approvedNoBatchAssignedSalaries,
    ...approvedBatchAssignedSalaries,
    ...sentForReleaseSalaries,
    ...releasedSalaries,
    ...resignedEmployees,
    ...onStrikeEmployees,
  ];
}
