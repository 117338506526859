import { Approval } from '@erp_core/erp-icons/icons/web/approval';
import { Bank } from '@erp_core/erp-icons/icons/web/bank';
import { CasualLeave } from '@erp_core/erp-icons/icons/web/casual-leave';
import { CompensatoryOff } from '@erp_core/erp-icons/icons/web/compensatory-off';
import { ExecutiveFemale } from '@erp_core/erp-icons/icons/web/executive-female';
import { ExecutiveMale } from '@erp_core/erp-icons/icons/web/executive-male';
import { Expire } from '@erp_core/erp-icons/icons/web/expire';
import { FactoryManager } from '@erp_core/erp-icons/icons/web/factory-manager';
import { FactoryWorkerFemale } from '@erp_core/erp-icons/icons/web/factory-worker-female';
import { FactoryWorkerMale } from '@erp_core/erp-icons/icons/web/factory-worker-male';
import { HelperFemale } from '@erp_core/erp-icons/icons/web/helper-female';
import { HelperMale } from '@erp_core/erp-icons/icons/web/helper-male';
import { Hourglass } from '@erp_core/erp-icons/icons/web/hourglass';
import { ManagerFemale } from '@erp_core/erp-icons/icons/web/manager-female';
import { ManagerMale } from '@erp_core/erp-icons/icons/web/manager-male';
import { MaternityLeave } from '@erp_core/erp-icons/icons/web/maternity-leave';
import { OfficeStaffFemale } from '@erp_core/erp-icons/icons/web/office-staff-female';
import { OfficeStaffMale } from '@erp_core/erp-icons/icons/web/office-staff-male';
import { PrivilegeLeave } from '@erp_core/erp-icons/icons/web/privilege-leave';
import { RocketLaunch } from '@erp_core/erp-icons/icons/web/rocket-launch';
import { SickLeave } from '@erp_core/erp-icons/icons/web/sick-leave';
import { Unapproval } from '@erp_core/erp-icons/icons/web/unapproval';
import { UnpaidLeave } from '@erp_core/erp-icons/icons/web/unpaid-leave';
import {
  AttendanceStatus,
  GroupLeavesType,
  LeaveStatus,
  LeaveType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { EmployeeShiftScheduleType } from '@erp_core/erp-types/dist/modules/planning';
import { FlagIcon, TruckIcon } from '@heroicons/react/24/outline';
import { LeavesIcon } from 'modules/hrd/attendance/day-book/components/icons/leaves-icon';
import moment from 'moment';

export const isUuid = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export function TreeIcon({ type }: { type: string }): JSX.Element {
  switch (type) {
    case 'root':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-map-pinned'
        >
          <path d='M18 8c0 4.5-6 9-6 9s-6-4.5-6-9a6 6 0 0 1 12 0' />
          <circle cx='12' cy='8' r='2' />
          <path d='M8.835 14H5a1 1 0 0 0-.9.7l-2 6c-.1.1-.1.2-.1.3 0 .6.4 1 1 1h18c.6 0 1-.4 1-1 0-.1 0-.2-.1-.3l-2-6a1 1 0 0 0-.9-.7h-3.835' />
        </svg>
      );
    case 'stock-group':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-boxes'
        >
          <path d='M2.97 12.92A2 2 0 0 0 2 14.63v3.24a2 2 0 0 0 .97 1.71l3 1.8a2 2 0 0 0 2.06 0L12 19v-5.5l-5-3-4.03 2.42Z' />
          <path d='m7 16.5-4.74-2.85' />
          <path d='m7 16.5 5-3' />
          <path d='M7 16.5v5.17' />
          <path d='M12 13.5V19l3.97 2.38a2 2 0 0 0 2.06 0l3-1.8a2 2 0 0 0 .97-1.71v-3.24a2 2 0 0 0-.97-1.71L17 10.5l-5 3Z' />
          <path d='m17 16.5-5-3' />
          <path d='m17 16.5 4.74-2.85' />
          <path d='M17 16.5v5.17' />
          <path d='M7.97 4.42A2 2 0 0 0 7 6.13v4.37l5 3 5-3V6.13a2 2 0 0 0-.97-1.71l-3-1.8a2 2 0 0 0-2.06 0l-3 1.8Z' />
          <path d='M12 8 7.26 5.15' />
          <path d='m12 8 4.74-2.85' />
          <path d='M12 13.5V8' />
        </svg>
      );
    case 'godown':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-factory'
        >
          <path d='M2 20a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8l-7 5V8l-7 5V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z' />
          <path d='M17 18h1' />
          <path d='M12 18h1' />
          <path d='M7 18h1' />
        </svg>
      );
    case 'item':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-package'
        >
          <path d='m7.5 4.27 9 5.15' />
          <path d='M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z' />
          <path d='m3.3 7 8.7 5 8.7-5' />
          <path d='M12 22V12' />
        </svg>
      );
    case 'item-batch':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-scan-barcode'
        >
          <path d='M3 7V5a2 2 0 0 1 2-2h2' />
          <path d='M17 3h2a2 2 0 0 1 2 2v2' />
          <path d='M21 17v2a2 2 0 0 1-2 2h-2' />
          <path d='M7 21H5a2 2 0 0 1-2-2v-2' />
          <path d='M8 7v10' />
          <path d='M12 7v10' />
          <path d='M17 7v10' />
        </svg>
      );
    default:
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-shield-question'
        >
          <path d='M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z' />
          <path d='M9.1 9a3 3 0 0 1 5.82 1c0 2-3 3-3 3' />
          <path d='M12 17h.01' />
        </svg>
      );
  }
}

export const dateGetter = (date: Date) =>
  `${date.getUTCDate()}/${date.getUTCMonth()}/${date.getUTCFullYear()}`;

export const makeSmallText = (text: string, len: number) => {
  if (text.length <= len) return text;
  return text.slice(0, len);
};

export const makeShortForm = (text: string, maxLen) => {
  if (text.length <= maxLen) return text;
  return text.split(' ').map((a) => {
    for (let i = 0; i < a.length; i += 1) {
      if (a[i] && charIsLetter(a[i])) return a[i].toUpperCase();
    }
    return '';
  });
};

function charIsLetter(char: any) {
  if (typeof char !== 'string') {
    return false;
  }

  return char.toLowerCase() !== char.toUpperCase();
}

export function employeeIconMapper(category: string): string {
  switch (category) {
    case 'executive-manager':
      return '🤵‍♂️'; // ceo, md, president, vice-president
    case 'office-manager':
      return '👨‍💻'; //  project-Manager, hrd-Manager, qc-Manager, finance-manager
    case 'office-staff':
      return '👨‍💼'; //  procurement-officer, sales-officer,
    case 'factory-manager':
      return '👨‍🏫'; //  store-Manager, shift-supervisor
    case 'factory-staff':
      return '👷'; //  machine-operator, floor-person
    case 'helper-staff':
      return '👨‍🌾'; //  watchman, cleaner

    default:
      return '👤';
  }
}

export function employeeIconMapperByGrade({
  gender,
  grade,
  iconSize,
}: {
  grade?: { id: string; name: string };
  gender?: 'male' | 'female';
  iconSize?: 'small' | 'large';
}): string | JSX.Element {
  switch (grade?.id) {
    case 'G21':
    case 'G20':
    case 'G19':
    case 'G18':
    case 'G17': {
      if (gender === 'female') {
        return (
          <ExecutiveFemale
            className={`${
              iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
            } inline stroke-emerald-800 fill-emerald-800`}
          />
        );
      }
      return (
        <ExecutiveMale
          className={`${
            iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
          } inline stroke-emerald-800 fill-emerald-800`}
        />
      ); // ceo, md, president, vice-president
    }
    case 'office-manager':
    case 'G13':
    case 'G14':
    case 'G15':
    case 'G16': {
      //  project-Manager, hrd-Manager, qc-Manager, finance-manager
      if (gender === 'female') {
        return (
          <ManagerFemale
            className={`${
              iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
            } inline fill-fuchsia-800`}
          />
        );
      }
      return (
        <ManagerMale
          className={`${
            iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
          } inline fill-fuchsia-800`}
        />
      );
    }
    case 'factory-manager':
    case 'G12':
    case 'G11':
    case 'G10':
      return (
        <FactoryManager
          className={`${
            iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
          } inline stroke-indigo-800 fill-indigo-800`}
        />
      );
    // return '👨‍🏫';  //  store-Manager, shift-supervisor
    case 'office-staff':
    case 'G08':
    case 'G07':
    case 'G09': {
      if (gender === 'female') {
        return (
          <OfficeStaffFemale
            className={`${
              iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
            } inline fill-teal-800`}
          />
        );
      }
      return (
        <OfficeStaffMale
          className={`${
            iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
          } inline fill-teal-800`}
        />
      );
    }

    // return  '👨‍💼';    //  procurement-officer, sales-officer,
    case 'factory-staff':
    case 'GZ1':
    case 'G04':
    case 'G05':
    case 'G06': {
      if (gender === 'female') {
        return (
          <FactoryWorkerFemale
            className={`${
              iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
            } inline fill-orange-800`}
          />
        );
      }
      return (
        <FactoryWorkerMale
          className={`${
            iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
          } inline fill-orange-800`}
        />
      );
    }
    case 'helper-staff':
    case 'G01':
    case 'G02':
    case 'G03': {
      //  machine-operator, floor-person
      if (gender === 'female') {
        return (
          <HelperFemale
            className={`${
              iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
            } inline stroke-blue-800`}
          />
        );
      }
      return (
        <HelperMale
          className={`${
            iconSize === 'large' ? 'w-14 h-14' : 'w-8 h-8'
          } inline stroke-blue-800`}
        />
      );
    } //  return '👨‍🌾';     //  watchman, cleaner
    default:
      return '👤';
  }
}

export function employeeLeaveStatusMapper(
  leaveStatus: LeaveStatus
): JSX.Element | string {
  switch (leaveStatus) {
    case 'approved':
      return (
        <Approval className='w-4 h-4 fill-green-500 stroke-green-600 inline stroke-0' />
      );
    case 'cancelled':
      return (
        <Unapproval className='w-4 h-4 fill-red-500 stroke-red-600 inline stroke-0' />
      );
    case 'expired':
      return (
        <Expire className='w-4 h-4 fill-gray-600 stroke-gray-600 inline stroke-0' />
      );
    case 'pending':
      return (
        <Hourglass className='w-4 h-4 fill-blue-500 inline stroke-0 stroke-0' />
      );
    case 'redeemed':
      return <RocketLaunch className='w-4 h-4 text-red-500 inline stroke-0' />;
    case 'un-consumed':
      return <Bank className='w-4 h-4 text-green-500 inline stroke-0' />;
    default:
      return '';
  }
}

export function employeeLeaveTypeIconMapper({
  type,
}: {
  type: LeaveType | undefined;
}) {
  switch (type) {
    case 'casual-leave':
      return <CasualLeave className='w-6 h-6 inline fill-blue-500 stroke-0' />;
    case 'sick-leave':
      return <SickLeave className='w-6 h-6 inline fill-amber-700 stroke-0' />;
    case 'privilege-leave':
      return (
        <PrivilegeLeave className='w-6 h-6 inline text-green-700 stroke-0' />
      );
    case 'layoff-leave':
      return '🤷';
    case 'compensatory-off':
      return (
        <CompensatoryOff className='w-6 h-6 inline fill-purple-700 stroke-0' />
      );
    case 'unpaid-leave':
      return (
        <UnpaidLeave className='w-6 h-6 inline fill-pink-700 text-pink-700 stroke-0' />
      );
    case 'on-job-accident-leave':
      return <TruckIcon className='w-6 h-6 inline fill-red-500 stroke-0' />;
    case 'maternity-leave':
      return (
        <MaternityLeave className='w-6 h-6 inline fill-blue-700 stroke-0' />
      );
    case 'special-leave':
      return '🎁';
    case 'retrenchment-leave':
      return '🛋️';
    default:
      return '';
  }
}

export function employeeTrackngStatus({
  grade,
}: {
  grade?: {
    id: string;
    name: string;
  };
}): boolean {
  switch (grade?.id) {
    case 'G21' || 'G20' || 'G19' || 'G18' || 'G17' || 'G16':
      return false; // ceo, md, president, vice-president
    default:
      return true;
  }
}

export const getEmployeeScheduleTime = ({
  trackingStatus,
  employeeSchedule,
}: {
  trackingStatus: boolean;
  employeeSchedule?: EmployeeShiftScheduleType;
}) => {
  if (!trackingStatus) return '';
  if (employeeSchedule)
    return `${moment(employeeSchedule.schedule?.startTime, 'HH:mm:ss').format(
      'hh:mm A'
    )} 
  to ${moment(employeeSchedule.schedule?.endTime, 'HH:mm:ss').format('hh:mm A')}
  at  ${employeeSchedule.schedule?.location}`;
  return 'Not configured';
};

export function EmployeeStatusIconMapper({
  status,
}: {
  status: string;
}): JSX.Element {
  switch (status) {
    case 'on-strike':
      return <FlagIcon className='text-red-500 w-5 h-5 inline-block' />; // on-strike
    default:
      return <div className='hidden' />;
  }
}

function computeStatus({ status }: { status: AttendanceStatus }): JSX.Element {
  switch (status) {
    case 'on-leave':
      return (
        <div className='px-1 border bg-green-50 w-fit font-semibold border-green-300'>
          On Leave
        </div>
      );
    case 'present':
      return (
        <div className='px-1 border bg-green-50 w-fit font-semibold border-green-300'>
          Present
        </div>
      );
    case 'absent':
      return (
        <div className='px-1 border bg-red-50 w-fit font-semibold border-red-300'>
          Absent
        </div>
      );
    case 'present-half-day':
      return (
        <div className='px-1 border bg-orange-50 w-fit font-semibold border-orange-300'>
          Present Half day
        </div>
      );
    case 'worked-overtime':
      return (
        <div className='px-1 border bg-blue-50 w-fit font-semibold border-blue-300'>
          Worked overtime
        </div>
      );
    case 'week-off':
      return (
        <div className='px-1 border bg-pink-50 w-fit font-semibold border-pink-300'>
          Weekly off
        </div>
      );
    case 'worked-on-week-off':
      return (
        <div className='px-1 border bg-pink-50 w-fit font-semibold border-pink-300'>
          Worked on week off
        </div>
      );
    case 'location-off':
      return (
        <div className='px-1 border bg-pink-50 w-fit font-semibold border-pink-300'>
          Location off
        </div>
      );
    case 'worked-on-location-off':
      return (
        <div className='px-1 border bg-pink-50 w-fit font-semibold border-pink-300'>
          Worked on location off
        </div>
      );
    case 'work-from-home':
      return (
        <div className='px-1 border bg-green-50 w-fit font-semibold border-green-300'>
          Present (WFH)
        </div>
      );
    case 'not-determined':
      return (
        <div className='px-1 border bg-gray-50 w-fit font-semibold border-gray-300'>
          Not determined
        </div>
      );
    default:
      return (
        <div className='px-1 border bg-gray-50 w-fit font-semibold border-gray-300'>
          {' '}
          Not determined
        </div>
      );
  }
}
export function EmployeeAttendanceStatusMapper({
  status,
  leaves,
}: {
  status: AttendanceStatus;
  leaves?: GroupLeavesType[];
}): JSX.Element {
  const statusJSx = computeStatus({ status });

  return (
    <div className='flex'>
      <span className='italic'>Status: &nbsp;</span>
      {statusJSx}
      {leaves?.find((x) => x.leave.status === 'redeemed') ? (
        <LeavesIcon empLeaves={leaves} />
      ) : null}
    </div>
  );
}

export function ShiftIcon(shiftType: string): string {
  switch (shiftType) {
    case 'Rotational': {
      return '🔄';
    }
    case 'General': {
      return '💼';
    }
    case 'Fixed': {
      return '⚓';
    }
    case 'Flexible': {
      return '🤸';
    }
    default:
      return '❓';
  }
}
