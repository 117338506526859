import { SalaryAdvanceType } from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
import {
  CardBody,
  CardHeader,
  // LoadingButton,
  MonthSelector,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
// import { Popover } from '@headlessui/react';
import {
  BanknotesIcon,
  // PencilSquareIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
// import { renderAuthWrapper } from '../../../components/auth';
import { UserContext } from '../../../contexts/user';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseSalaryAdvance } from '../../../hooks/hrd/salary-advance/use-salary-advance';
import { UseSalaryAdvances } from '../../../hooks/hrd/salary-advance/use-salary-advances';
import { UserRendererInterface } from '../../common/fragments/user';
import { SalaryAdvanceApprovalFormComponent } from './component/approval-salary-advance';

export type RenderSalaryAdvanceProps = {
  useSalaryAdvance: UseSalaryAdvance;
  useSalaryAdvances: UseSalaryAdvances;
  useEmployees: UseEmployeeProfiles;
  useUserAuthorization: UseUserAuthorization;
  userRendererService: UserRendererInterface;
};

export function renderSalaryAdvances({
  useSalaryAdvance,
  useSalaryAdvances,
  useEmployees,
  useUserAuthorization,
  userRendererService,
}: RenderSalaryAdvanceProps): () => JSX.Element {
  return function SalaryAdvances(): JSX.Element {
    // const AuthWrapper = renderAuthWrapper({ useUserAuthorization });

    const { user: currentUser } = useContext(UserContext);
    const { get: getAllowedActions } = useUserAuthorization();

    const { syncSet: setSalaryAdvance } = useSalaryAdvance();

    const {
      data: salaryAdvances,
      getAll: getSalaryAdvances,
    } = useSalaryAdvances();

    useEffect(() => {}, [salaryAdvances]);

    const [date, setDate] = useState(
      localStorage.getItem('advance-month') || moment.utc().format('YYYY-MM')
    );

    useEffect(() => {
      getSalaryAdvances({ date });
      localStorage.setItem('advance-month', date);
      // eslint-disable-next-line
    }, [date]);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Salary Advances',
      icon: (
        <BanknotesIcon className='w-8 h-8 inline stroke-indigo-900 self-center' />
      ),
      subheading: (
        <span className='text-blue-700 font-bold'>
          {moment(date).format('MMM YYYY')}
        </span>
      ),
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select month</span>
              <MonthSelector
                format='YYYY-MM'
                initialState={date}
                onChange={(m) => setDate(m)}
              />
            </div>
          ),
        },
      ],
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Employee' },
        { name: 'Requested Date' },
        { name: 'Reason' },
        { name: 'Eligible Amount' },
        { name: 'Requested Amount' },
        { name: 'Released Date' },
        { name: 'Released Amount' },
        { name: 'Status' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
        { name: 'Actions' },
      ],
    ];

    // const Modal = renderModal();

    // const SalaryAdvanceAction = ({ r }: { r: SalaryAdvanceType }) => {
    //   return (
    //     <div className='flex flex-col p-2 space-y-2'>
    //       {r.status === 'pending' ? (
    //         <AuthWrapper
    //           action='UI:APR-SAL-ADV:VIEW'
    //           children={
    //             <LoadingButton
    //               behaviorFn={async () => approvalAction(r)}
    //               defaultStyle='mb-1'
    //               text='Approval'
    //             />
    //           }
    //         />
    //       ) : null}
    //     </div>
    //   );
    // };

    const ApprovalForm = SalaryAdvanceApprovalFormComponent();

    // const approvalAction = (r: SalaryAdvanceType) => {
    //   setModal({
    //     ...modal,
    //     title: 'Approval',
    //     isVisible: true,
    //     body: <ApprovalForm data={r} onSave={approveRequest} />,
    //   });
    // };

    const approveRequest = async (data: SalaryAdvanceType) => {
      try {
        const finalData = {
          ...data,
          authorizer: {
            id: currentUser.id,
            name: currentUser.name,
          },
        };
        await setSalaryAdvance(finalData);
        // setModal((ms) => ({ ...ms, isVisible: false }));
        toast('Data updated sucessfully');
        getSalaryAdvances({ date });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableData: TableBody =
      salaryAdvances?.map((r, idx) => ({
        rowData: {
          salaryAdvance: r,
        },
        cells: [
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.employee?.id}
                name={r.employee.name}
              />
            ),
          },
          { value: r.date },
          { value: r.reason },
          { value: r.feasibleAmount },
          { value: r.amount },
          { value: r.details?.releasedDate || '-' },
          { value: r.details?.relasedAmount || '-' },
          { value: r.status },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.lastModifiedBy?.id}
                name={r.lastModifiedBy?.name}
                extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
              />
            ),
          },
          // {
          //   value: (
          //     <Popover className='relative'>
          //       {({ open }) => (
          //         <>
          //           <Popover.Button>
          //             <PencilSquareIcon className='w-6 h-6 text-blue-600 cursor-pointer' />
          //           </Popover.Button>
          //           <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
          //             <SalaryAdvanceAction r={r} />
          //           </Popover.Panel>
          //         </>
          //       )}
          //     </Popover>
          //   ),
          // },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              auth={{ actions: getAllowedActions().actions }}
              actions={[
                {
                  name: 'Approval',
                  auth: 'UI:APR-SAL-ADV:VIEW',
                  behaviour: 'modal',
                  show: ({ salaryAdvance }) =>
                    salaryAdvance.status === 'pending',
                  modal: {
                    title: 'Approval',
                    content: ({ data: { salaryAdvance }, onClose }) => {
                      return (
                        <ApprovalForm
                          data={salaryAdvance}
                          onSave={async (form) => {
                            await approveRequest(form);
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
        {/* <Modal {...modal} /> */}
      </>
    );
  };
}
