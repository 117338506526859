import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../../../contexts/location';
import { UseCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseLocations } from '../../../../hooks/admin/location-admin/use-locations';
import { UseLocationHoliday } from '../../../../hooks/planning/location-holiday/use-location-holiday';
import { UseLocationHolidays } from '../../../../hooks/planning/location-holiday/use-location-holidays';
import { UseShiftSchedule } from '../../../../hooks/planning/shift-schedule/use-shift-schedule';
import { UseShiftSchedules } from '../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { renderLocationHolidaysList } from '../../../planning/location-holidays';
import { renderShiftScheduleV2List } from '../../../planning/shift-schedule';
import { CGConfigRenderer } from '../config-renderer';
import { renderSisterLocationForm } from './forms/sister-location-form';

type CreateInventoryTemplatesPage = {
  useCompanyGroupSettings: UseCompanyGroupSettings;
  useCompanyGroupSetting: UseCompanyGroupSetting;
  useLocations: UseLocations;
  useShiftSchedules: UseShiftSchedules;
  useShiftSchedule: UseShiftSchedule;
  useLocationHoliday: UseLocationHoliday;
  useLocationHolidays: UseLocationHolidays;
};

export const createCompanyGroupSettingPage = ({
  useCompanyGroupSetting,
  useCompanyGroupSettings,
  useLocations,
  useShiftSchedule,
  useShiftSchedules,
  useLocationHoliday,
  useLocationHolidays,
}: CreateInventoryTemplatesPage) => {
  const Card = renderCardComponent();

  return function () {
    const [data, setData] = useState<CompanyGroupSetting | null>();
    const { getAllSync } = useCompanyGroupSettings();
    const { companyGroup: currentCompanyGroup } = useContext(LocationContext);

    const getData = async () => {
      const cgs = await getAllSync();
      if (cgs.length > 0) setData(cgs[0]);
    };

    useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    type Configs = {
      settingName: string;
      settingValue?: string;
      editAction: () => JSX.Element;
    };

    const configs: Configs[] = [
      {
        settingName: 'Sister Locations',
        settingValue:
          Object.keys(
            data?.details.companyGroup?.location?.sisterLocations || {}
          )
            .map((k) => k)
            .join(', ') || '',
        editAction: () => <></>,
      },
    ];

    const ShiftSchedules = renderShiftScheduleV2List({
      useShiftSchedules,
      useCompanyGroupSettings,
      useShiftSchedule,
    });

    const LocationHolidays = renderLocationHolidaysList({
      useCompanyGroupSettings,
      useLocationHolidays,
      useLocationHoliday,
    });

    return (
      <div className='w-full'>
        <Card
          header={{ title: 'Company-Group Config' }}
          body={{
            type: 'jsx-with-behaviour',
            body: {
              content: <CGConfigRenderer configs={configs} loading={false} />,
              behaviour: 'modal',
              modal: {
                title: 'Configure Sister Locations',
                content: ({ onClose }) => {
                  const Form = renderSisterLocationForm({
                    closeModal: onClose,
                    useCompanyGroupSetting,
                    useLocations,
                    refreshData: getData,
                  });
                  return (
                    <Form
                      companyGroupId={currentCompanyGroup.id}
                      comGrpSetDetails={data?.details || {}}
                    />
                  );
                },
              },
            },
          }}
        />

        <ShiftSchedules />
        <LocationHolidays />
      </div>
    );
  };
};
