/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  PurchaseOrder,
  PurchaseOrderFulfilment,
} from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { generateName } from '@erp_core/erp-utils';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../../../../contexts/user';
import { UseTemplate } from '../../../../../../hooks/admin/communication-template/use-template';
import { UseUsers } from '../../../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';
import { UseQuestionnaireQuestions } from '../../../../../../hooks/hrd/questionnaire/questionnairequestion/usequestionnairequestions';
import { UsePurchaseOrderFulfilment } from '../../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';
import { UsePurchaseOrderFulfilments } from '../../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilments';
import { UsePurchaseOrder } from '../../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { ItemInterface } from '../../../../../../models/interfaces/inventory/item';
import { renderGoodsReceived } from '../../../../../../modules/purchase/goods-received';
import { renderCreatePurchaseOrderFulfilmentForm } from './forms/add-fulfilment';
import { AddFulfillment } from './types/add-fulfilment';

export const renderPurchaseOrderFulfilmentDetails = ({
  usePurchaseOrderFulfilment,
  usePurchaseOrder,
  usePurchaseOrderFulfilments,
  itemService,
  useFileTransfer,
  useQuestionnaireQuestions,
  useTemplate,
  useUsers,
}: {
  usePurchaseOrderFulfilment: UsePurchaseOrderFulfilment;
  usePurchaseOrderFulfilments: UsePurchaseOrderFulfilments;
  usePurchaseOrder: UsePurchaseOrder;
  itemService: ItemInterface;
  useQuestionnaireQuestions: UseQuestionnaireQuestions;
  useTemplate: UseTemplate;
  useFileTransfer: UseFileTransfer;
  useUsers: UseUsers;
}) => {
  return function PurchaseOrderFulfilmentDetails({
    purchaseOrder,
    cgs,
  }: {
    purchaseOrder: PurchaseOrder;
    cgs: CompanyGroupSetting;
  }) {
    const { id } = useParams();
    const { syncSet: setFulfillments } = usePurchaseOrderFulfilment();
    const { syncSet: setPurchaseOrder } = usePurchaseOrder();
    const {
      data: fulfillments,
      getAll: getFulfillments,
    } = usePurchaseOrderFulfilments();
    const { getAllSync: getAllFulfilments } = usePurchaseOrderFulfilments();
    const { user: currentUser } = useContext(UserContext);

    useEffect(() => {
      getFulfillments({ purchaseOrderId: id });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddFulfilment = async (
      formData: AddFulfillment & { summary?: string; isApproved?: boolean }
    ) => {
      const { data: grnName } = await generateName({
        pattern: cgs.details.purchase?.grn?.grnNamePattern || '',
        getMany: getAllFulfilments,
      });
      const finalData = {
        purchaseOrder: { id: purchaseOrder.id },
        name: grnName || '',
        status: 'scheduled-for-delivery',
        details: {
          quantity: formData.quantity,
          invoiceNo: formData.invoiceNo,
          arrivedAt: formData.arrivedAt,
          attachments: [],
          remarks: formData.remarks,
          isApproved: formData.isApproved || false,
          grnNumber: grnName,
          invoiceQuantity: formData.invoiceQuantity,
          allowMismatchedQuantity: formData.allowMismatchedQuantity,
          uom: formData.uom,
          reasonForMismatchQuantity: formData.reasonForMismatchQuantity || '',
          lorryReceiptNumber: formData.lorryReceiptNumber || '',
          AWBorBlNumber: formData.AWBorBlNumber || '',
          invoiceDate: formData.invoiceDate,
          eWayBillNumber: formData.eWayBillNumber,
          girirNo: formData.girirNumber,
          totalAmountPayable: formData.totalAmountPayable,
          sealNo: formData.sealNumber,
          transporter: { id: '', name: formData.transporter }, // TODO
          supplier: { id: '', name: formData.supplier },
        },
      };
      const poActivity = {
        action: 'fulfilment-added',
        details: '',
        user: currentUser,
        event: `${currentUser.name} added a fulfilment`,
        time: moment.utc().format(),
      };
      try {
        await setFulfillments(
          (finalData as unknown) as PurchaseOrderFulfilment
        );
        await setPurchaseOrder({
          id,
          details: {
            ...purchaseOrder?.details,
            summary: formData.summary,
            activity: [poActivity, ...(purchaseOrder?.details.activity || [])],
          },
        } as any);
        getFulfillments({ purchaseOrderId: id });
        toast('Added successfully');
      } catch (error) {
        toast((error as any).message);
      }
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Purchase Orders Fulfilments (GRN)',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: `Add PO fulfilment (GRN) for ${purchaseOrder.grade?.name}`,
              content: ({ onClose }) => {
                const addFulfilmentFormData = {
                  total: Number(purchaseOrder.details.quantity),
                  previouslyAdded:
                    fulfillments?.reduce(
                      (a, b) => a + Number(b?.details?.quantity || 0),
                      0
                    ) || 0,
                };
                const Form = renderCreatePurchaseOrderFulfilmentForm();
                return (
                  <Form
                    data={addFulfilmentFormData}
                    purchaseOrder={purchaseOrder}
                    onSave={async (form) => {
                      handleAddFulfilment(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const GoodsReceived = renderGoodsReceived({
      usePurchaseOrderFulfilment,
      usePurchaseOrderFulfilments,
      itemService,
      useQuestionnaireQuestions,
      useTemplate,
      useFileTransfer,
      useUsers,
    });

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <GoodsReceived selectedPO={purchaseOrder} />
        </>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
};
