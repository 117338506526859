import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderPureHistory,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../../../contexts/user';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { renderEmployeeStatusForm } from './forms/update-status';

export const renderEmployeeTimeLine = ({
  data,
  useUserAuthorization,
}: {
  data: EmployeeProfileType;
  useUserAuthorization: UseUserAuthorization;
}) => {
  return function EmployeeTimeLine({
    setEmployee,
  }: {
    setEmployee: (s: EmployeeProfileType) => void;
  }) {
    const { user: currentUser } = useContext(UserContext);
    const { get: getAllowedActions } = useUserAuthorization();

    const getActivity = (savedStatus?: string, currentStatus?: string) => {
      if (savedStatus !== 'on-strike' && currentStatus === 'on-strike')
        return 'went-on-strike';
      if (savedStatus === 'on-strike' && currentStatus !== 'on-strike')
        return 'came-back-from-strike';
      if (currentStatus === 'resigned') return 'resigned';
      return 'joined';
    };

    const handleStatusSave = (form) => {
      const finalData = {
        id: data.id,
        details: {
          ...data?.details,
          status: form.status,
          workTimeLines: [
            {
              activity: getActivity(data?.details?.status, form.status),
              date: form.date,
              updatedBy: {
                id: currentUser.id,
                name: currentUser.name,
              },
              updatedOn: moment.utc(),
            },
            ...(data?.details?.workTimeLines || []),
          ],
        },
      };
      setEmployee(finalData as any);
      // console.log('finalData', form);
      toast('Updated sucessfully');
    };

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Career Highlights',
      subheading: <>{data?.details?.status || 'status'}</>,
      actions: [
        {
          auth: 'UI:EDIT-EMP-CAREER-HL:VIEW',
          type: 'button',
          button: {
            name: 'Update Status',
            behaviour: 'modal',
            modal: {
              title: 'Update Status',
              size: 'large',
              content: ({ onClose }) => {
                const Form = renderEmployeeStatusForm({});
                return (
                  <Form
                    data={{
                      status: data?.details?.status || '',
                      date: moment().format('YYYY-MM-DD'),
                    }}
                    onSave={async (form) => {
                      await handleStatusSave(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const History = renderPureHistory();
    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <History
          logs={
            data?.details?.workTimeLines?.map((t) => ({
              event: `${t.activity} on ${t.date}`,
              time: t.date,
              action: t.activity,
              details: '',
            })) || []
          }
        />
      ),
    };
    return (
      <div>
        <Card
          header={header}
          body={body}
          auth={{ actions: getAllowedActions().actions }}
        />
      </div>
    );
  };
};
