import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/user';
import { UseDepartments } from '../../../../hooks/hrd/department/use-departments';
import { ChangeAssesmentType } from '../../../../models/types/quality-assurance/change-assesment';
import { ccCategories } from '../../units/categories';

// eslint-disable-next-line

export type ChangeReqFormType = {
  department: IdName;
  docNo: string;
  docName: string;
  typeOfChange: string;
  changeRelatedTo: Array<IdName>;
  currentStatus: string;
  proposedChange: string;
  reasonForChange: string;
  status: string;
  commentsOfHOD: string;
};

type RenderAddChangeControlReqForm = {
  useDepartments: UseDepartments;
};

export function renderAddChangeControlReqForm({
  useDepartments,
}: RenderAddChangeControlReqForm) {
  // eslint-disable-next-line
  const { data: departments, getAll: getDepartments } = useDepartments();
  // eslint-disable-next-line
  useEffect(() => {
    getDepartments();
    // eslint-disable-next-line
  }, []);

  return function AddChangeControlReqForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    isEdit?: boolean;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const { user: currentUser } = useContext(UserContext);

    const formProps: FormProps<ChangeReqFormType> = {
      fieldsData: [
        {
          groupName: 'Basic-Information',
          property: 'department',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useDepartments,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
          required: true,
          label: 'Department',
        },
        {
          groupName: 'Basic-Information',
          type: 'searchable-multi-select',
          searchOptions: {
            useSearch: useCCCategories,
            onSearchValueSelect: (e) => {
              console.log('abc', e);
            },
          },
          label: 'Change Related To',
          property: 'changeRelatedTo',
        },
        // {
        //   property: 'changeRelatedTo',
        //   type: 'input',
        //   required: true,
        //   label: 'Change Related To',
        // },
        {
          groupName: 'Basic-Information',
          property: 'docNo',
          type: 'input',
          required: true,
          label: 'Document No',
        },
        {
          groupName: 'Basic-Information',
          property: 'docName',
          type: 'input',
          required: true,
          label: 'Document Name',
        },
        {
          groupName: 'Basic-Information',
          property: 'typeOfChange',
          type: 'select',
          options: [
            { text: 'select', value: '' },
            { text: 'temporary', value: 'temporary' },
            { text: 'permanent', value: 'permanent' },
          ],
          required: true,
          label: 'Type Of Change',
        },
        {
          groupName: 'Detailed-Information',
          property: 'currentStatus',
          type: 'text-area',
          required: true,
          label: 'Current Status',
        },
        {
          groupName: 'Detailed-Information',
          property: 'proposedChange',
          type: 'text-area',
          required: true,
          label: 'Proposed Change',
        },
        {
          groupName: 'Detailed-Information',
          property: 'reasonForChange',
          type: 'text-area',
          required: true,
          label: 'Reason For Change',
        },
        {
          groupName: 'HOD-Approval',
          property: 'commentsOfHOD',
          type: 'text-area',
          label: 'Comments',
          dependentOn: (formData) => {
            return data?.status === 'draft';
          },
        },
        {
          groupName: 'HOD-Approval',
          property: 'status',
          type: 'select',
          label: 'Status',
          options: [
            { value: 'select', text: 'select' },
            { value: 'approved', text: 'approved' },
            { value: 'rejected', text: 'rejected' },
          ],
          dependentOn: (formData) => {
            return data?.status === 'draft';
          },
        },
      ],
      initialFormState: {
        department: data?.details.department || [],
        docNo: data?.details.docNo || '',
        docName: data?.details.docName || '',
        // changeRelatedTo: [],
        changeRelatedTo:
          data?.details.changeRelatedTo?.map((x) => {
            const c = ccCategories.find((y) => y.name === x.name);
            if (c) {
              return {
                id: c.name,
                name: c.name,
              };
            }

            return { id: '', name: '' };
          }) || [],

        typeOfChange: data?.details.typeOfChange || '',
        currentStatus: data?.details.currentStatus || '',
        proposedChange: data?.details.proposedChange || '',
        reasonForChange: data?.details.reasonForChange || '',
        commentsOfHOD: data?.details.commentsOfHOD || '',
        status: data?.status || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        let hod;
        if (f.department) {
          hod = departments?.find((x) => x.name === f.department.name)?.hod;
        } else {
          hod = departments?.find(
            (x) => x.name === data?.details.department.name
          )?.hod;
        }

        await onSave(({
          details: {
            ...data?.details,
            employeeSeekingChange:
              {
                id: currentUser.id,
                name: currentUser.name,
              } || data?.details.employeeSeekingChange,
            department: f.department || data?.details.department,
            docNo: f.docNo || data?.details.docNo,
            docName: f.docName || data?.details.docName,
            typeOfChange: f.typeOfChange || data?.details.typeOfChange,
            changeRelatedTo: f.changeRelatedTo || data?.details.changeRelatedTo,
            currentStatus: f.currentStatus || data?.details.currentStatus,
            proposedChange: f.proposedChange || data?.details.proposedChange,
            reasonForChange: f.reasonForChange || data?.details.reasonForChange,
            hod:
              {
                id: hod?.id,
                name: hod?.name,
              } || data?.details.hod,
            commentsOfHOD: f.commentsOfHOD || data?.details.commentsOfHOD,
          },
          status: (f.status === undefined ? 'draft' : f.status) || data?.status,
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<ChangeReqFormType>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<ChangeReqFormType>): ChangeReqFormType {
      return (b as unknown) as ChangeReqFormType;
    }
  };
}

export function useCCCategories() {
  const [filtered, setFiltered] = useState<{ name: string }[]>(ccCategories);
  return {
    data: filtered,
    getAll: (filter) => {
      console.log(filter, 'aya');
      if (filter.search) {
        setFiltered(
          ccCategories.filter((x) =>
            x.name.toLowerCase().includes((filter.search || '').toLowerCase())
          )
        );
      } else {
        setFiltered(ccCategories);
        console.log('filtered', filtered);
      }
    },
  };
}
