import { PurchaseOrder } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  downloadPdfFile,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import {
  ArrowDownTrayIcon,
  ClockIcon,
  PaperClipIcon,
} from '@heroicons/react/24/outline';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocationContext } from '../../../../contexts/location';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseGrade } from '../../../../hooks/inventory/grade/use-grade';
import { UseItem } from '../../../../hooks/inventory/item/use-item';
import { UsePaginatedPurchaseOrders } from '../../../../hooks/inventory/purchase/purchase-order/use-paginated-purchase-orders';
import { UsePurchaseOrders } from '../../../../hooks/inventory/purchase/purchase-order/use-purchase-orders';
import { UseVendors } from '../../../../hooks/inventory/purchase/vendors/use-vendors';
import { PurchaseOrderFilterType } from '../../../../models/interfaces/order/purchase/purchase-order';
import { renderPEName } from '../../../../modules/common/fragments/purchase-enquiry';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderVendor } from '../../../common/fragments/vendor';
import { downloadLatestSpec } from '../../../inventory/items/utils/item-spec-downloader';

export function renderPurchaseOrder({
  usePurchaseOrders,
  usePaginatedPurchaseOrders,
  useUsers,
  useVendors,
  useItem,
  useGrade,
  useFileTransfer,
  userRendererService,
}: {
  usePurchaseOrders: UsePurchaseOrders;
  usePaginatedPurchaseOrders: UsePaginatedPurchaseOrders;
  useUsers: UseUsers;
  useVendors: UseVendors;
  useItem: UseItem;
  useGrade: UseGrade;
  useFileTransfer: UseFileTransfer;
  userRendererService: UserRendererInterface;
}): () => JSX.Element {
  return function PurchaseOrder() {
    const PeName = renderPEName();
    const { getAll: getOrders } = usePurchaseOrders();
    const { get: getPdf } = useFileTransfer();
    const { getSync: getGrade } = useGrade();
    const { list, get: getFile } = useFileTransfer();
    const {
      company: currentCompany,
      companyGroup: currentCompanyGroup,
    } = useContext(LocationContext);
    const navigate = useNavigate();

    useEffect(() => {
      getOrders();
      // eslint-disable-next-line
    }, []);

    const Vendor = renderVendor();

    const Table = renderTableWithMapperComponent<
      PurchaseOrder,
      PurchaseOrderFilterType
    >();

    const handleEnquiryPdfDownload = async (enqId, vendorId) => {
      const path = `${currentCompanyGroup.id}/${
        currentCompany.id
      }/purchase/enquiries/${enqId.toLowerCase()}/${vendorId}.pdf`;

      const res = await getPdf(path, true);
      const fName = `enquiry-${enqId}-${vendorId}.pdf`;

      downloadPdfFile({ result: res, fileName: fName });
    };

    const handleQuoteDownload = async (path, vendorId, quoteNo) => {
      const res = await getPdf(`${path}.pdf`, true);
      const fName = `quote-${quoteNo}-${vendorId}.pdf`;
      downloadPdfFile({ result: res, fileName: fName });
    };

    const handleOrderPdfDownload = async (orderId: string) => {
      const res = await getPdf(
        `${currentCompanyGroup.id}/${currentCompany.id}/purchase/orders/${orderId}.pdf`,
        true
      );
      // const res = await getPdf(`${currentCompanyGroup.id}/${currentCompany.id}/purchase/orders/test-id.pdf`, true)
      const fName = `order-${orderId}.pdf`;
      downloadPdfFile({ result: res, fileName: fName });
    };
    const tableHeader: TableHeader = [
      [
        { name: 'Name', rowSpan: 2 },
        { name: 'Vendor', rowSpan: 2 },
        { name: 'Item', rowSpan: 2 },
        { name: 'Quantity', rowSpan: 2 },
        { name: 'Eta', rowSpan: 2 },
        { name: 'Rate', rowSpan: 2 },
        { name: 'Created By', rowSpan: 2 },
        { name: 'Status', rowSpan: 2 },
        { name: 'Summary', rowSpan: 2 },
        {
          name: <PaperClipIcon className='w-5 inline text-white fill-black' />,
        },
      ],
    ];

    const bodyMapper = (item: PurchaseOrder) => {
      const cells: Array<TableCell> = [
        {
          value: item.name,
          link: `/purchase/registers/purchase-order/${item.id}/details`,
        },
        { value: <Vendor id={item.vendor.id} name={item.vendor.name} /> },
        {
          value: (
            <>
              <div className='font-semibold'>{item?.grade?.name}</div>
              <div className='font-thin'>{item?.grade?.item?.name || ''}</div>
            </>
          ),
          link: item.details?.itemDetails?.id
            ? `/inventory/masters/items/${item.details.itemDetails.id}/properties`
            : '',
        },
        {
          value: `${item.details?.quantity} ${item.details?.itemDetails?.uom}`,
        },
        {
          value: (
            <span>
              <ClockIcon className='w-5 inline text-blue-800' />
              {item.details?.eta || '--'}
            </span>
          ),
        },
        { value: item.details?.rate },

        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={item.createdBy?.id}
              name={item.createdBy.name}
            />
          ),
        },
        { value: item.status },
        {
          value: (
            <div>
              <div>{item.details.summary}</div>
              {item?.purchaseEnquiry ? (
                <div>
                  <PeName
                    id={item?.purchaseEnquiry?.id}
                    name={item?.purchaseEnquiry?.name}
                  />
                </div>
              ) : null}
            </div>
          ),
        },
        {
          value: (
            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button>
                    <ArrowDownTrayIcon className='h-6 w-6' />{' '}
                  </Popover.Button>
                  <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                    <div>
                      <div
                        className='flex cursor-pointer'
                        onClick={
                          async () => {
                            console.log(item);
                            if (
                              item.purchaseEnquiry?.purchaseRequisitions[0]
                                ?.grade?.id
                            ) {
                              const gradeDetails = await getGrade(
                                item.purchaseEnquiry.purchaseRequisitions[0]
                                  ?.grade.id
                              );
                              console.log(gradeDetails);
                              // const itemDetails = await getItemDetails(
                              //   item.purchaseEnquiry.purchaseRequisitions[0]?.grade?.item?.id
                              // );
                              if (gradeDetails) {
                                downloadLatestSpec({
                                  companyGroupId: currentCompanyGroup.id,
                                  stockGroupId: gradeDetails.item.stockgroup.id,
                                  itemId: gradeDetails.item.id,
                                  itemName: gradeDetails.item.name,
                                  list: list,
                                  getFile: getFile,
                                });
                              }
                            }
                          }
                          // downloadItemSpec({
                          //   companyGroupId: currentCompanyGroup.id,
                          //   stockGroupId: (
                          //     await getItemDetails(
                          //       item.purchaseEnquiry?.purchaseRequisitions[0]
                          //         ?.itemDetails.id ||
                          //         item.purchaseEnquiry?.purchaseRequisitions[0]
                          //           ?.itemDetails.id ||
                          //         ''
                          //     )
                          //   )?.stockgroup.id,
                          //   item: await getItemDetails(
                          //     item.purchaseEnquiry?.purchaseRequisitions[0]
                          //       ?.itemDetails.id ||
                          //       item.purchaseEnquiry?.purchaseRequisitions[0]
                          //         ?.itemDetails.id ||
                          //       ''
                          //   ),
                          //   getSpecTemplate: getPdf,
                          // })
                        }
                      >
                        {' '}
                        <ArrowDownTrayIcon className='h-6 w-6' />
                        Item spec
                      </div>
                      {item.purchaseEnquiry && (
                        <div
                          className='flex cursor-pointer'
                          onClick={() =>
                            handleEnquiryPdfDownload(
                              item.purchaseEnquiry?.id,
                              item.vendor.id
                            )
                          }
                        >
                          <ArrowDownTrayIcon className='h-6 w-6' />
                          {item.vendor.name} Enquiry
                        </div>
                      )}

                      {item.purchaseEnquiry && (
                        <div
                          className='flex cursor-pointer'
                          onClick={() =>
                            handleQuoteDownload(
                              item.purchaseEnquiry?.enquiryQuotes?.find(
                                (eq) => eq.id === item.details.quoteId
                              )?.details?.quoteDetails?.[0]?.attachments?.[0],
                              item.vendor.id,
                              item.purchaseEnquiry?.enquiryQuotes?.find(
                                (eq) => eq.id === item.details.quoteId
                              )?.details?.quoteDetails?.[0]?.quoteNo
                            )
                          }
                        >
                          <ArrowDownTrayIcon className='h-6 w-6' />
                          {item.vendor.name} Quote{' '}
                          {
                            item.purchaseEnquiry?.enquiryQuotes?.find(
                              (eq) => eq.id === item.details.quoteId
                            )?.details?.quoteDetails?.[0]?.quoteNo
                          }
                        </div>
                      )}

                      {item.purchaseEnquiry && (
                        <div
                          className='flex cursor-pointer'
                          onClick={() => handleOrderPdfDownload(item.id)}
                        >
                          <ArrowDownTrayIcon className='h-6 w-6' />
                          Order Pdf
                        </div>
                      )}
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          ),
        },
      ];

      return {
        cells,
        rowData: {
          purchaseOrder: item,
        },
      };
    };

    const filter: Filter<PurchaseOrderFilterType> = {
      sortFields: [
        {
          key: 'Name',
          value: 'name',
          defaultOrder: 'asc',
        },
        // {
        //   key: 'Item',  // TODO Sortfilter is not woking
        //   value: 'item',
        //   defaultOrder: 'asc',
        // },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'status',
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'ALL', value: 'ALL' },
            { text: 'OPEN', value: 'OPEN' },
            { text: 'CLOSED', value: 'CLOSED' },
          ],
        },
        {
          key: 'vendor',
          type: 'search-select',
          value: '',
        },
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'lastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: PurchaseOrderFilterType) => {
        const filterData: PurchaseOrderFilterType = {};

        if (filterSelection.status !== 'ALL') {
          filterData.status = filterSelection.status;
        }

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }

        if (filterSelection.vendor) {
          filterData.vendor = filterSelection.vendor;
        }

        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }

        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }

        return filterData as PurchaseOrderFilterType;
      },
      filterResources: {
        vendor: {
          searchOptions: {
            useSearch: useVendors,
            onSearchValueSelect: (u) => {},
          },
        },
        createdBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
        lastModifiedBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Purchase Orders',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          bodyMapper={bodyMapper}
          useResources={usePurchaseOrders}
          filter={filter}
          actions={[
            {
              name: 'View / Add Fulfilments',
              show: (p) => true,
              behaviour: 'click',
              onClick: async (p) => {
                navigate(
                  `/purchase/registers/purchase-order/${p.purchaseOrder.id}/fulfilments`
                );
              },
            },
            {
              name: 'Close Order',
              show: (p) => true,
              behaviour: 'click',
              onClick: async (p) => {},
            },
          ]}
          pagination={{
            enabled: true,
            usePaginatedResources: usePaginatedPurchaseOrders,
          }}
        />
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
