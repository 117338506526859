import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { CGConfigRenderer } from '../config-renderer';
// import { GenericEditPatternFormProps } from '../purchase-config';
import { renderClassifiers } from './classifiers';
import { renderEditItemBatchNamePatternForm } from './forms/edit-item-batch-name-pattern';
import { renderEditMinimumOrderQuantityForm } from './forms/edit-minimum-order-quantity';
import { renderEditMinimumSalesQuantityForm } from './forms/edit-minimum-sales-quantity';
import { renderEditMinimumStockLevelForm } from './forms/edit-minimum-stock-level';
import { renderOperations } from './operations';

type CreateInventoryConfigPage = {
  useCompanyGroupSetting: UseCompanyGroupSetting;
  useCompanyGroupSettings: UseCompanyGroupSettings;
};

export const createInventoryConfigPage = ({
  useCompanyGroupSetting,
  useCompanyGroupSettings,
}: CreateInventoryConfigPage) => {
  const Classifiers = renderClassifiers();
  const Operations = renderOperations();
  const Card = renderCardComponent();
  return function () {
    // const {data, get: getCompanyGroupSetting} = useCompanyGroupSetting();
    const { data: allData, getAll, loading } = useCompanyGroupSettings();
    const { syncSet: setCGS } = useCompanyGroupSetting();

    const [data, setData] = useState<CompanyGroupSetting | null>();

    useEffect(() => {
      setData(
        (allData || []).length > 0 ? (allData ? allData[0] : null) : null
      );
    }, [allData]);

    useEffect(() => {
      getAll();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditMinimumOrderQuantity = ({
      onClose,
    }: {
      onClose: () => void;
    }) => {
      const EditMinimumOrderQuantityForm = renderEditMinimumOrderQuantityForm({
        comGrpSetDetails: data?.details || {},
        closeModal: onClose,
        useCompanyGroupSetting,
      });

      return <EditMinimumOrderQuantityForm />;
    };

    const handleEditMinimumSalesQuantity = ({
      onClose,
    }: {
      onClose: () => void;
    }) => {
      const EditMinimumSalesQuantityForm = renderEditMinimumSalesQuantityForm({
        comGrpSetDetails: data?.details || {},
        closeModal: onClose,
        useCompanyGroupSetting,
      });
      return <EditMinimumSalesQuantityForm />;
    };

    // const closeModalAndRefresh = () => {
    //   setModalState((ms) => ({ ...ms, isVisible: false }));
    //   getAll();
    // };

    // const handleEditPattern: () => (p: {
    //   comGrpSetDetails: CompanyGroupSettingDetails;
    // }) => JSX.Element = () =>
    //   // genericEditPatternForm: (
    //   //   // {
    //   //   //   closeModal,
    //   //   //   useCompanyGroupSetting,
    //   //   // }: GenericEditPatternFormProps
    //   // ) => (data: {
    //   //   comGrpSet?: CompanyGroupSetting;
    //   // }) => JSX.Element,
    //   // title: string
    //   {
    //     const NewForm = renderEditItemBatchNamePatternForm({
    //       comGrpSetDetails: data?.details || {},
    //       id: data?.id || '',
    //       closeModal: () => {},
    //       useCompanyGroupSetting,
    //     });
    //     // const Form = genericEditPatternForm({
    //     //   closeModal: closeModalAndRefresh,
    //     //   useCompanyGroupSetting,
    //     // });
    //     return <NewForm />;
    //   };

    const handleEditMinimumStockLevel = ({
      onClose,
    }: {
      onClose: () => void;
    }) => {
      const EditMinimumStockLevel = renderEditMinimumStockLevelForm({
        comGrpSetDetails: data?.details || {},
        closeModal: onClose,
        useCompanyGroupSetting,
      });
      return <EditMinimumStockLevel />;
    };

    type Configs = {
      settingName: string;
      settingValue?: string;
      settingValueSuffix?: string;
      editAction: ({ onClose }: { onClose: () => void }) => JSX.Element;
    };

    const configs: Configs[] = [
      {
        settingName: 'Minimum order quantity',
        settingValue: data?.details?.inventory?.item?.minimumOrderQuantity?.toString(),
        settingValueSuffix: '% of godown capacity',
        editAction: handleEditMinimumOrderQuantity,
      },
      {
        settingName: 'Minimum Sales quantity: ',
        settingValue: data?.details?.inventory?.item?.minimumSalesQuantity?.toString(),
        settingValueSuffix: '% of godown capacity',
        editAction: handleEditMinimumSalesQuantity,
      },
      {
        settingName: 'Minimum Stock Level quantity: ',
        settingValue: data?.details?.inventory?.item?.minimumStockLevel?.toString(),
        settingValueSuffix: '% of godown capacity',
        editAction: handleEditMinimumStockLevel,
      },
      {
        settingName: 'Item batch name pattern: ',
        settingValue: data?.details?.inventory?.itemBatch?.itemBatchNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const NewForm = renderEditItemBatchNamePatternForm({
            comGrpSetDetails: data?.details || {},
            id: data?.id || '',
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <NewForm />;
        },
        // (
        //   // renderEditItemBatchNamePatternForm,
        //   // 'Edit Item batch Name pattern'
        // )
      },
    ];

    return (
      <div className='w-full'>
        <div className='flex flex-wrap'>
          {configs.map((config) => (
            <div className='w-1/2' key={config.settingName}>
              <Card
                key={config.settingName}
                header={{
                  title: config.settingName,
                }}
                body={{
                  type: 'jsx-with-behaviour',
                  body: {
                    content: (
                      <CGConfigRenderer configs={[config]} loading={loading} />
                    ),
                    behaviour: 'modal',
                    modal: {
                      title: config.settingName,
                      content: ({ onClose }) => {
                        const Comp = config.editAction({ onClose });
                        return Comp;
                      },
                    },
                  },
                }}
              />
            </div>
          ))}
        </div>

        <Classifiers config={data?.details.inventory || {}} setCGS={setCGS} />
        <Operations config={data?.details.inventory || {}} setCGS={setCGS} />
      </div>
    );
  };
};
