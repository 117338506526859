import { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../../contexts/location';
import { UseCompanyGroupSettings } from '../company-group-setting/use-company-group-settings';

export type UseSisterLocation = () => {
  data: string | undefined;
  // get: (id: string) => Promise<void>
  // getSync: (id: string) => Promise<string | undefined>
};

type CreateUseSisterLocation = {
  useCompanyGroupSettings: UseCompanyGroupSettings;
};

export const createUseSisterLocation = ({
  useCompanyGroupSettings,
}: CreateUseSisterLocation): UseSisterLocation => {
  return function () {
    const { getAllSync } = useCompanyGroupSettings();
    const { location: currentLocation } = useContext(LocationContext);
    const [data, setData] = useState<string>();

    const get = async () => {
      const name = await getSync();
      if (name) setData(name);
    };

    const getSync = async () => {
      const cgs = await getAllSync();
      if (cgs.length === 0) return;
      const sisterLocations =
        cgs[0].details.companyGroup?.location?.sisterLocations?.[
          currentLocation?.name?.trim()?.toLowerCase()
        ];
      const sisterLocation = sisterLocations?.find(
        (s) => s.location?.id === currentLocation.id
      );
      return sisterLocation?.location?.name;
    };

    useEffect(() => {
      if (currentLocation) {
        get();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (currentLocation) {
        get();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation]);

    return {
      data: data,
      // get: get,
      // getSync: getSync
    };
  };
};
