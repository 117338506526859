import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
// import { EducationDetailsType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
// import { ActionButton } from '@erp_core/erp-ui-components';
// import { ActionContextMenu } from '@erp_core/erp-ui-components';
// import { renderCardComponent } from '@erp_core/erp-ui-components';
// import {
//   CardBody,
//   CardHeader,
// } from '@erp_core/erp-ui-components';
// import { Modal } from '@erp_core/erp-ui-components';
// import { renderTableComponent } from '@erp_core/erp-ui-components';
// import {
//   TableBody,
//   TableCell,
//   TableHeader,
// } from '@erp_core/erp-ui-components';
// import { useState } from 'react';
// import toast from 'react-hot-toast';
// import { downloadPdfFile } from '@erp_core/erp-ui-components';
// import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';
// import { renderFileViewerUploader } from '../../../../../../components/file-viewer-uploader';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';
// import { renderAddEducationForm } from '../../education/education-add-form';
// import { renderEditEducationForm } from '../../education/education-edit-form';

export function renderEducation(
  useFileTransfer: UseFileTransfer
  // eslint-disable-next-line
): ({
  id,
  setEmployee,
  employee,
}: {
  id: string;
  setEmployee: (s: EmployeeProfileType) => void;
  employee: EmployeeProfileType;
  isHr: boolean;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
}) => JSX.Element {
  // const FileViewerUploader = renderFileViewerUploader();

  return function Education({
    id,
    setEmployee,
    employee,
    actions,
    isHr,
  }: {
    id: string;
    setEmployee: (s: EmployeeProfileType) => void;
    employee: EmployeeProfileType;
    isHr: boolean;
    actions: {
      [key: string]: {
        action: boolean;
        locations?: string[] | undefined;
      };
    };
  }) {
    // const { get: getPrivateFile } = useFileTransfer();
    // const { data: company } = useCurrentCompany();
    // const { currentCompanyGroup: companyGroup } = useCurrentCompanyGroup();

    // const [modalState, setModalState] = useState({
    //   visible: false,
    //   title: 'Edit Education',
    //   content: <>Edit Education form</>,
    // });

    // const [actionsState, setActionsState] = useState({
    //   visible: false,
    //   xPosition: 200,
    //   yPosition: 200,
    //   menu: ['Edit'],
    // });

    // const [activeDataId] = useState<string>('');

    // const handleSaveEdit = async ({ data, onClose }:{ data: Partial<EducationDetailsType>; onClose: () => void; }) => {
    //   const activeEducation = employee.details.educationDetails?.find(
    //     (i) => i.degreeTitle === activeDataId
    //   );

    //   if (activeEducation) {
    //     if (data.statusOfEducation) {
    //       activeEducation.statusOfEducation = data.statusOfEducation;
    //     }
    //     if (data.degreeTitle) {
    //       activeEducation.degreeTitle = data.degreeTitle;
    //     }
    //     if (data.college) {
    //       activeEducation.college = data.college;
    //     }
    //     if (data.yearOfPassing) {
    //       activeEducation.yearOfPassing = data.yearOfPassing;
    //     }
    //     if (data.marks) {
    //       activeEducation.marks = data.marks;
    //     }
    //     // activeEducation.document = data.document;
    //   }

    //   const finalData = {
    //     id: employee.id,
    //     details: {
    //       educationDetails: employee.details.educationDetails,
    //     },
    //   };

    //   onClose();
    //   try {
    //     setEmployee((finalData as unknown) as EmployeeProfileType);
    //   } catch (error) {
    //     toast((error as any).message);
    //   }
    // };

    // const handleActionItemClick = async (action: string) => {
    //   if (action === 'Edit') {
    //     const activeEducation = employee.details.educationDetails?.find(
    //       (i) => i.degreeTitle === activeDataId
    //     );
    //     if (activeEducation) {
    //       const EducationEditFormData: EducationDetailsType = {
    //         statusOfEducation: activeEducation.statusOfEducation,
    //         degreeTitle: activeEducation.degreeTitle,
    //         college: activeEducation.college,
    //         yearOfPassing: activeEducation.yearOfPassing,
    //         marks: activeEducation.marks,
    //         document: activeEducation.document,
    //       };

    //       const Form1 = renderEditEducationForm({
    //         // employeeId: id,
    //         // useFileTransfer,
    //         // useCurrentCompanyGroup,
    //         // useCurrentCompany,
    //       });
    //       setModalState({
    //         ...modalState,
    //         visible: true,
    //         title: 'Edit Education',
    //         content: (
    //           <Form1 data={EducationEditFormData} onSave={handleSaveEdit} />
    //         ),
    //       });
    //     }
    //   }
    // };
    // const handleEducationAdd = async (data: Partial<EducationDetailsType>) => {
    //   const finalData = {
    //     id: employee.id,
    //     details: {
    //       educationDetails: [
    //         ...(employee.details.educationDetails || []),
    //         data,
    //       ],
    //     },
    //   };

    //   setEmployee((finalData as unknown) as EmployeeProfileType);
    //   setModalState((ms) => ({ ...ms, visible: false }));
    // };

    // const handleActionClick = (e: any, id: string) => {
    //   setActionsState({
    //     ...actionsState,
    //     visible: true,
    //     xPosition: e.pageX,
    //     yPosition: e.pageY,
    //   });
    //   setActiveDataId(id);
    // };

    // const Form = renderAddEducationForm({
    //   // employeeId: id,
    //   // useFileTransfer,
    //   // useCurrentCompanyGroup,
    //   // useCurrentCompany,
    // });
    // const handleAddEducation = () => {
    //   setModalState({
    //     ...modalState,
    //     visible: true,
    //     title: 'Add Education',
    //     content: (
    //       <Form
    //         onSave={handleEducationAdd}
    //         data={{
    //           statusOfEducation: 'Below-V',
    //           degreeTitle: '',
    //           college: '',
    //           yearOfPassing: '',
    //           marks: 0,
    //           document: '',
    //         }}
    //       />
    //     ),
    //   });
    // };

    // const EducationCard = renderCardComponent();
    // const EducationCardHeader: CardHeader = {
    //   title: 'Education Details',
    //   actions: [
    //     {
    //       auth: 'HRD:EMP-PROFILE:EDIT',
    //       type: 'button',
    //       button: {
    //         name: 'Add',
    //         style: 'float-right',
    //         onClick: () => handleAddEducation(),
    //       },
    //     },
    //   ],
    // };

    // const EducationTable = renderTableComponent();
    // const educationTableHeader: TableHeader = [
    //   [
    //     { name: 'Status of Education',  },
    //     { name: 'Degree Title',  },
    //     { name: 'College',  },
    //     { name: 'Year Of Passing',  },
    //     { name: 'Marks',  },
    //     { name: 'Documents',  },
    //     { name: 'Actions', colSpan: 2, },
    //   ],
    // ];

    // const EducationTableBody: TableBody = employee.details?.educationDetails
    //   ? employee.details.educationDetails.map((f, idx) => {
    //       const cells: Array<TableCell> = [];
    //       cells.push(
    //         { value: f.statusOfEducation },
    //         { value: f.degreeTitle },
    //         {
    //           value: f.college,
    //         },
    //         { value: f.yearOfPassing },
    //         { value: f.marks },
    //         {
    //           value: f.degreeTitle ? (
    //             <FileViewerUploader
    //               mode={isHr ? 'upload': 'view'}
    //               url={f.document}
    //               useFileTransfer={useFileTransfer}
    //               path={`${companyGroup.id}/${
    //                 company.id
    //               }/employees/education-details/${id}/${
    //                 f.statusOfEducation || 'unknown'
    //               }-${f.degreeTitle}.pdf`.replaceAll(' ', '-')}
    //               type='private'
    //               onUpload={async (path) => {
    //                 const educationDetails = employee.details?.educationDetails;
    //                 educationDetails[idx].document = path.url;
    //                 setEmployee({
    //                   id: employee.id,
    //                   details: {
    //                     educationDetails: educationDetails,
    //                   },
    //                 } as any);
    //               }}
    //             />
    //           ) : (
    //             ''
    //           ),

    //           // <ArrowDownCircleIcon
    //           //   className="inline w-5 h-5 m-1 cursor-pointer"
    //           //   onClick={
    //           //     async () => {
    //           //       const res = await getPrivateFile(
    //           //         `${companyGroup.id}/${company.id}/employees/education-details/${id}/${f.statusOfEducation}-${f.degreeTitle}.pdf`
    //           //         , true);
    //           //       const fName = `${f.statusOfEducation}-${f.degreeTitle}.pdf`;

    //           //       downloadPdfFile({ result: res, fileName: fName })
    //           //     }
    //           //   }
    //           // /> :

    //           //  ''
    //         },
    //         {
    //           value: isHr ? (
    //             <ActionButton
    //               handleActionClick={handleActionClick}
    //               id={f.degreeTitle}
    //             />
    //           ) : '',
    //         }
    //       );

    //       return { cells };
    //     })
    //   : [];

    // const EducationCardBody: CardBody = {
    //   type: 'jsx-component',
    //   body: (
    //     <div>
    //       <div className='w-full'>
    //         <EducationTable
    //           header={educationTableHeader}
    //           body={EducationTableBody}
    //         />
    //       </div>
    //     </div>
    //   ),
    // };

    return (
      <>
        Education Details
        {/* <EducationCard header={EducationCardHeader} body={EducationCardBody} 
          auth={{ actions }}
        /> */}
        {/* {actionsState.visible && (
          <ActionContextMenu
            actionsState={actionsState}
            setActionsState={setActionsState}
            handleActionItemClick={handleActionItemClick}
          />
        )} */}
        {/* <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal> */}
      </>
    );
  };
}
