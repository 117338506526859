import { renderLogs } from '@erp_core/erp-ui-components';
import { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../../../contexts/location';
import { CompanyGroupSettingInterface } from '../../../../models/interfaces/admin/company-group-settings';

type CreateCompanyGroupSettingLogs = {
  companyGroupSettingService: CompanyGroupSettingInterface;
};

export const createCompanyGroupSettingLogs = ({
  companyGroupSettingService,
}: CreateCompanyGroupSettingLogs) => {
  return function CompanyGroupSettingLogs() {
    const [data, setData] = useState<any>([]);
    const { companyGroup } = useContext(LocationContext);
    const fetchLogs = async () => {
      const resp = await companyGroupSettingService.getLogs(companyGroup.id);
      setData(resp);
    };

    useEffect(() => {
      companyGroup.id && fetchLogs();
      // eslint-disable-next-line
    }, [companyGroup]);

    const Logs = renderLogs();

    return <Logs title='History' data={data} />;
  };
};
