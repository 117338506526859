import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCompanyGroupSettings } from '../../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseUserAuthorization } from '../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UseItem } from '../../../../../hooks/inventory/item/use-item';
import { UseEnquiryQuote } from '../../../../../hooks/inventory/purchase/enquiry-quotes/use-quote';
import { UseEnquiryQuotes } from '../../../../../hooks/inventory/purchase/enquiry-quotes/use-quotes';
import { UsePurchaseEnquiry } from '../../../../../hooks/inventory/purchase/purchase-enquiry/use-purchase-enquiry';
import { UseVirtualItemAllocate } from '../../../../../hooks/inventory/purchase/purchase-order/use-allocate-virtual-item';
import { UsePurchaseOrder } from '../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { UsePurchaseOrders } from '../../../../../hooks/inventory/purchase/purchase-order/use-purchase-orders';
import { UsePurchaseRequisition } from '../../../../../hooks/inventory/purchase/purchase-requisition/use-purchase-requisition';
import { UseStockGroup } from '../../../../../hooks/inventory/stockgroup/use-stockgroup';
import { UseBom } from '../../../../../hooks/order/work-order/bom/use-bom';
import { renderPRName } from '../../../../../modules/common/fragments/purchase-requisition';
import { renderEnquiryProfile } from './enquiry-flow';
import { renderEnquiryQuotes } from './enquiry-quote';

export type RenderPRDetailsProp = {
  hooks: {
    usePurchaseEnquiry: UsePurchaseEnquiry;
    useEnquiryQuote: UseEnquiryQuote;
    useEnquiryQuotes: UseEnquiryQuotes;
    usePurchaseOrder: UsePurchaseOrder;
    useUserAuthorization: UseUserAuthorization;
    useVirtualItemAllocate: UseVirtualItemAllocate;
    useFileTransfer: UseFileTransfer;
    useBom: UseBom;
    useItem: UseItem;
    useCompanyGroupSettings: UseCompanyGroupSettings;
    useStockGroup: UseStockGroup;
    usePurchaseOrders: UsePurchaseOrders;
    usePurchaseRequisition: UsePurchaseRequisition;
  };
};

export function renderEnquiryDetails({
  hooks: {
    useEnquiryQuote,
    useEnquiryQuotes,
    usePurchaseOrder,
    useUserAuthorization,
    useVirtualItemAllocate,
    usePurchaseEnquiry,
    useCompanyGroupSettings,
    useFileTransfer,
    useBom,
    useItem,
    useStockGroup,
    usePurchaseOrders,
    usePurchaseRequisition,
  },
}: RenderPRDetailsProp): () => JSX.Element {
  return function PRDetails(): JSX.Element {
    const { id } = useParams();
    const PrName = renderPRName();
    const {
      data: enquiry,
      get: getEnquiry,
      syncSet: setPurchaseEnquiry,
    } = usePurchaseEnquiry();
    const NewTabs = renderTabsV2Component();
    const Card = renderCardComponent();

    useEffect(() => {
      if (id) {
        getEnquiry(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${enquiry.name || id}`,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            Status:{' '}
            <span className='border rounded-md bg-indigo-100 text-indigo-900 px-1 py-0.5'>
              {enquiry.status || 'open'}
            </span>{' '}
            {enquiry?.purchaseRequisitions ? (
              <>
                Item:{' '}
                <a
                  className='font-bold'
                  href={
                    '/inventory/items' // `/inventory/items/${data?.purchaseRequisitions[0]?.itemDetails?.id}/profile`
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  {enquiry.purchaseRequisitions &&
                    enquiry?.purchaseRequisitions[0]?.itemDetails?.name}
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp; Quantity:{' '}
                <b>
                  {(enquiry.purchaseRequisitions &&
                    enquiry?.purchaseRequisitions[0]?.details?.quantity) ||
                    '??'}{' '}
                  {enquiry.purchaseRequisitions &&
                    enquiry?.purchaseRequisitions[0]?.itemDetails?.uom}
                </b>
                &nbsp;&nbsp;|&nbsp;&nbsp; PRs:{' '}
                <PrName
                  id={enquiry?.purchaseRequisitions[0]?.id}
                  name={enquiry?.purchaseRequisitions[0]?.name}
                />
              </>
            ) : null}
          </div>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${enquiry.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {enquiry.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${enquiry.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {enquiry.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(enquiry.lastModifiedAt).fromNow()}
          </div>
        </>
      ),
    };

    const newCategories: DataArray = [
      {
        name: 'details',
        Fn: renderEnquiryProfile({
          enquiry,
        }),
      },
      {
        name: 'quotes',
        Fn: renderEnquiryQuotes({
          enquiry,
          setPurchaseEnquiry,
          useEnquiryQuote,
          useCompanyGroupSettings,
          useEnquiryQuotes,
          usePurchaseOrder,
          useUserAuthorization,
          useVirtualItemAllocate,
          useFileTransfer,
          useBom,
          useItem,
          useStockGroup,
          usePurchaseOrders,
          usePurchaseRequisition,
        }),
      },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return <Card header={header} body={body} />;
  };
}
