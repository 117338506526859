import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  AdvancedLoadingButton,
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';

type CreateEmployeeAuthoriserCardType = {
  useEmployeeProfiles: UseEmployeeProfiles;
};

export function renderEmployeeAuthorisers({
  useEmployeeProfiles,
}: CreateEmployeeAuthoriserCardType): ({
  id,
  employee,
  setEmployee,
  loading,
  isHr,
}: {
  id: string;
  employee: EmployeeProfileType;
  setEmployee: (s: EmployeeProfileType) => Promise<EmployeeProfileType>;
  loading: boolean;
  isHr: boolean;
}) => JSX.Element {
  return function Authoriser({
    id,
    employee,
    setEmployee,
    loading,
    isHr,
  }: {
    id: string;
    employee: EmployeeProfileType;
    setEmployee: (s: EmployeeProfileType) => Promise<EmployeeProfileType>;
    loading: boolean;
    isHr: boolean;
  }): JSX.Element {
    // const [modalState, setModalState] = useState({
    //   visible: false,
    //   title: 'Edit Authoriser',
    //   content: <>Edit Authoriser form</>,
    // });

    function UpdateAuthorization(prop: {
      onClose: () => void;
      name: string;
      description: string;
      property: string;
    }) {
      const Form = renderFormV2<{ authorizer: any }>({
        formName: `Edit ${prop.name}`,
        initialFormState: {
          authorizer: employee?.details?.authorizations?.[prop.property] || {
            id: '',
            name: '',
          },
        },
        mapTToU: (d) => {
          return d as any;
        },
        fieldsData: [
          {
            property: 'authorizer',
            label: 'Authorizer',
            type: 'searchable-select',
            searchOptions: {
              useSearch: useEmployeeProfiles,
              filter: { crossGroup: true },
              onSearchValueSelect: () => {},
            },
          },
        ],
        onSubmit: async (p) => {
          const authorizations = employee.details?.authorizations || {};
          authorizations[prop.property] = p.authorizer;
          await setEmployee({
            id: id,
            details: {
              authorizations,
            },
          } as any);
          prop.onClose();
          // setModalState({ ...modalState, visible: false });
        },
      });
      return (
        <div>
          <Form />
        </div>
      );
      // setModalState({
      //   ...modalState,
      //   title: `Edit ${prop.name}`,
      //   content: (
      //   ),
      //   visible: true,
      // });
    }

    const AuthoriserCard = renderCardComponent();
    const AuthoriserCardHeader: CardHeader = {
      title: 'Authoriser Details',
    };
    const authorizations = [
      {
        name: 'Attendance Supervisor',
        description:
          'This person will verify and manage attendance. Do not set this in case the current employee has rotational shifts',
        property: 'attendanceSupervisor',
      },
      {
        name: 'Attendance Authorizer',
        description:
          'This person will authorize gatepasses, overtimes, offsites and leaves for the person',
        property: 'attendanceAuthorizer',
      },
      {
        name: 'Supervisor Monitor',
        description: 'This person will monitor the supervisor',
        property: 'attendanceSupervisorMonitor',
      },
      {
        name: 'Insurance Authorizer',
        description: 'This person will authorize insurance requests',
        property: 'insuranceAuthorizer',
      },
      {
        name: 'Loans Authorizer',
        description: 'This person will authorize loans requests',
        property: 'loanAuthorizer',
      },
      {
        name: 'Salary Bank Account Authorizer',
        description: 'This person will authorize Salary Bank Account details',
        property: 'salaryBankAccountAuthorizer',
      },
      {
        name: 'Salary Advance Authorizer',
        description: 'This person will authorize salary advance requests',
        property: 'salaryAdvanceAuthorizer',
      },
      {
        name: 'Salary Revisions Authorizer',
        description: 'This person will authorize salary revision requests',
        property: 'salaryRevisionAuthorizer',
      },
      {
        name: 'Disciplinary Authorizer',
        description: 'This person will authorize disciplinary requests',
        property: 'disciplinaryActionsAuthorizer',
      },
    ];

    const AuthoriserCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full flex flex-wrap'>
            {authorizations.map((x, idx) => (
              <div key={idx} className='w-1/3 p-2 bg-slate-50'>
                <div className='border border-gray-200 rounded-lg m-1 h-full'>
                  <div className='text-center text-xl'>{x.name}</div>
                  <div className='text-center italic text-gray-800'>
                    {x.description}
                  </div>
                  <div
                    className='text-center text-lg font-semibold'
                    // onClick={() => {
                    //   if (isHr) {
                    //     updateAuthorization(x);
                    //   }
                    // }}
                  >
                    {loading ? (
                      <span>loading</span>
                    ) : (
                      <>
                        {employee?.details?.authorizations?.[x.property]
                          ?.name || 'not-set'}
                        {isHr ? (
                          <AdvancedLoadingButton
                            text={'Edit'}
                            behaviour='modal'
                            modal={{
                              title: `Edit Authorizer ${x.name}`,
                              content: ({ onClose }) => {
                                return (
                                  <UpdateAuthorization
                                    name={x.name}
                                    property={x.property}
                                    description={x.description}
                                    onClose={onClose}
                                  />
                                );
                              },
                            }}
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    };

    return (
      <>
        <AuthoriserCard
          header={AuthoriserCardHeader}
          body={AuthoriserCardBody}
        />
        {/* <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal> */}
      </>
    );
  };
}
