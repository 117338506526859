import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { InvEntityType } from '../../../../models/types/inventory/inventory-properties';

export function renderSoftEditProperty<T extends InvEntityType>({
  itemProperties,
  entity,
  setEntity,
  classifiers,
}: {
  itemProperties: ItemProperty[];
  entity: T;
  setEntity: (s: T) => Promise<T>;
  classifiers: {
    name: string;
    description: string;
  }[];
}) {
  return function SoftEditProperty({
    idx,
    onClose,
  }: {
    idx: number;
    onClose: () => void;
  }): JSX.Element {
    const obj = itemProperties[idx];

    const indexInEntity = entity.details.properties.findIndex(
      (x) => x.name === obj.name
    );

    if (indexInEntity > -1) {
      const Form = renderFormV2({
        initialFormState: {
          classifiers:
            obj.classifiers?.map((x) => {
              const c = classifiers.find((y) => y.name === x.name);
              if (c) {
                return {
                  id: c.name,
                  name: c.name,
                  description: c.description,
                };
              }

              return { id: '', name: '' };
            }) || [],
          displayName: obj.displayName,
          sequenceNumber: obj.sequenceNumber as any,
        },
        fieldsData: [
          {
            property: 'displayName',
            label: 'Display Name',
            type: 'input',
          },
          {
            property: 'sequenceNumber',
            label: 'Sequence Number',
            type: 'number',
          },
          {
            property: 'classifiers',
            type: 'searchable-multi-select',
            searchOptions: {
              useSearch: () => {
                const newClassifiers = classifiers.map((x) => ({
                  ...x,
                  id: x.name,
                }));
                const [filtered, setFiltered] = useState<
                  { name: string; description: string }[]
                >(newClassifiers);
                return {
                  data: filtered,
                  getAll: (filter) => {
                    if (filter.search) {
                      console.log(filter);
                      setFiltered(
                        newClassifiers.filter((x) =>
                          x.name
                            .toLowerCase()
                            .includes((filter.search || '').toLowerCase())
                        )
                      );
                    } else {
                      setFiltered(newClassifiers);
                    }
                  },
                };
              },
              onSearchValueSelect: (e) => {
                // console.log(e);
              },
            },
            label: 'Classifiers',
          },
        ],
        mapTToU: (f) => f,
        onSubmit: async (f) => {
          if (f.classifiers) {
            f.classifiers = (f.classifiers as any[]).map((x) => ({
              name: x.name,
            }));
          }

          if (f.sequenceNumber) {
            f.sequenceNumber = parseInt(`${f.sequenceNumber}`);
          }

          entity.details.properties[indexInEntity] = {
            ...obj,
            ...f,
          };
          await setEntity(({
            id: entity.id,
            details: {
              properties: entity.details.properties,
            },
          } as unknown) as T);
          onClose();
        },
      });

      return <Form />;
      // setModal({
      //   ...modal,
      //   isVisible: true,
      //   title: 'Soft Edit Property',
      //   body: <Form />,
      // });
    }

    return <></>;
  };
}
