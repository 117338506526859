/* eslint-disable react-hooks/exhaustive-deps */
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { DeliveryModesShortForm } from '@erp_core/erp-types/dist/modules/constants';
import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import {
  AdvancedLoadingButton,
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { generateName } from '@erp_core/erp-utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  renderSelectVendorContact,
  VendorsToSendEnquiry,
} from '../../../../../../components/select-vendor-contact';
import { deliveryModesData } from '../../../../../../datalayer/repository/in-memory/admin/delivery-mode';
import { UseCompanyGroupSettings } from '../../../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseDeliveryTerms } from '../../../../../../hooks/constants/delivery-term/use-delivery-terms';
import { UsePaymentTerms } from '../../../../../../hooks/constants/payment-term/use-payment-terms';
import { UsePurchaseEnquiries } from '../../../../../../hooks/inventory/purchase/purchase-enquiry/use-purchase-enquiries';
import { UseVendors } from '../../../../../../hooks/inventory/purchase/vendors/use-vendors';
import { VendorInterface } from '../../../../../../models/interfaces/order/purchase/vendors';
import { EnquiryFormData } from '../../types/enquiry-form';
import { DeliverySchedule, PEDeliverySchedule } from './delivery-schedule';
import { PaymentSchedule, PEPaymentSchedule } from './payment-schedule';

type CreateEnquiryFormProps = {
  useVendors: UseVendors;
  useCompanyGroupSettings: UseCompanyGroupSettings;
  useDeliveryTerms: UseDeliveryTerms;
  usePaymentTerms: UsePaymentTerms;
  usePurchaseEnquiries: UsePurchaseEnquiries;
  efData: EnquiryFormData;
  onSave: any;
  vendorService: VendorInterface;
};

export type CreateEnquiryFormType = {
  name: string;
  item: string;
  deliveryMode: DeliveryModesShortForm;
  prQuantity: string;
  validity: string;
  targetDate: string;
  deliveryTerm: { id: string; name: string };
  paymentTerm: { id: string; name: string };
  vendors: VendorsToSendEnquiry;
  suppliers: any;
};

export function renderCreateEnquiryForm({
  useVendors,
  usePurchaseEnquiries,
  useDeliveryTerms,
  useCompanyGroupSettings,
  usePaymentTerms,
  efData,
  onSave,
  vendorService,
}: CreateEnquiryFormProps): () => JSX.Element {
  return function CreateEnquiryForm(): JSX.Element {
    const [vendorDetails] = useState<Vendor[]>([]);
    const [supplierAddresses] = useState<{ id: string; index: number }[]>([]);
    const [vendorsToSentEnquiries, setVendorsToSentEnquiries] = useState<
      VendorsToSendEnquiry
    >([]);

    const [currentState] = useState<CreateEnquiryFormType | undefined>();
    const [deliverySchedule, setDeliverySchedule] = useState<
      DeliverySchedule[]
    >([]);
    const [paymentSchedule, setPaymentSchedule] = useState<PaymentSchedule[]>(
      []
    );

    const { data: allData, getAll } = useCompanyGroupSettings();

    const { getAllSync: getAllEnquiries } = usePurchaseEnquiries();
    useEffect(() => getAll(), []);

    const [
      companySettings,
      setCompanySettings,
    ] = useState<CompanyGroupSetting | null>();

    useEffect(() => {
      setCompanySettings(
        (allData || []).length > 0 ? (allData ? allData[0] : null) : null
      );
    }, [allData]);

    const onSubmit = async (f: Partial<CreateEnquiryFormType>) => {
      const form = {
        ...f,
        deliverySchedule: deliverySchedule,
        paymentSchedule: paymentSchedule,
      };
      // Validation
      if (!form.deliveryTerm?.id) {
        toast('Delivery Term is required');
        return;
      }
      if (!form.paymentTerm?.id) {
        toast('Payment Term is required');
        return;
      }
      if (form.vendors?.length === 0) {
        toast('Vendors are required');
        return;
      }

      const vendors: any[] = [];
      for (let fv = 0; form.vendors && fv < form.vendors?.length; fv++) {
        const v = vendorsToSentEnquiries.find(
          (t) => form.vendors && t.id === form.vendors[fv].id && t.vendorItemId
        );
        if (!v) {
          toast(`Select contact for vendor ${form.vendors[fv].name}`);
          return;
        }
        vendors.push(v);
      }

      // Dynamically calculating name
      const name = await generateName({
        pattern:
          companySettings?.details.purchase?.purchaseEnquiry?.peNamePattern ||
          '',
        getMany: async (fil?: any) => {
          const data = await getAllEnquiries(fil, {
            enableLoading: false,
            enableResource: false,
          });
          return data;
        },
      });
      form['name'] = `PE-${moment().utc().format('YYYY-MM-DD')}`;
      if (name.success && name.data) form.name = name.data;

      // Getting vendors contact details
      for (let i = 0; form.vendors && i < form.vendors?.length; i++) {
        const contacts = vendorDetails.find(
          (ven) => form.vendors && ven.id === form.vendors[i].id
        )?.details.contact;
        const hasContact = contacts && contacts.length > 0;
        if (!hasContact) {
          toast(`contact not fount for vendor ${form.vendors[i].name}`);
          return;
        }
        const suppliers = vendorDetails
          .find((ven) => form.vendors && ven.id === form.vendors[i].id)
          ?.details.locations?.filter((x) => x.type === 'supplier');
        const hasSupplier = suppliers && suppliers.length > 0;
        if (!hasSupplier) {
          toast(`Supplier not found for vendor ${form.vendors[i].name}`);
          return;
        }
      }
      form.vendors = vendors;

      form.suppliers = form.vendors.map((v) => ({
        ...v,
        contactIndex:
          supplierAddresses.find((vc) => vc.id === v.id)?.index || 0,
      }));

      // Saving form
      onSave(form);
    };

    // const handleFormChange = async (data: { vendors: { id: string }[] }) => {
    //   const svd: Vendor[] = await Promise.all(
    //     data.vendors.map(async (v) => {
    //       const vd = await vendorService.get(v.id);
    //       return {
    //         ...vd,
    //         items: vd.items.filter((vi) => vi.item.id === efData.itemId),
    //       };
    //     })
    //   );
    //   setVendorDetails(svd);
    // };

    const formProps: FormProps<Partial<CreateEnquiryFormType>> = {
      fieldsData: [
        { property: 'item', label: ' Item', type: 'input', readonly: true },
        {
          property: 'prQuantity',
          label: 'PR Quantity',
          type: 'input',
          readonly: true,
        },
        {
          property: 'targetDate',
          label: 'Target Date',
          type: 'date',
          readonly: true,
        },
        { property: 'validity', label: 'Validity', type: 'number' },
        {
          property: 'deliveryMode',
          label: 'Delivery Mode',
          type: 'select',
          required: true,
          options: [
            { value: '', text: 'Select' },
            ...deliveryModesData.map((d) => ({ value: d.id, text: d.name })),
          ],
        },
        {
          property: 'deliveryTerm',
          label: 'Delivery Term',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useDeliveryTerms,
            onSearchValueSelect(u) {},
            filter: {
              // deliveryMode: currentState?.deliveryMode || 'all'
            },
          },
        },
        {
          property: 'paymentTerm',
          label: 'Payment Term',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: usePaymentTerms,
            onSearchValueSelect(u) {},
            filter: {
              // deliveryMode: currentState?.deliveryMode || 'all'
            },
          },
        },
        {
          property: 'vendors',
          label: 'Vendors',
          type: 'searchable-multi-select',
          searchOptions: {
            useSearch: useVendors,
            onSearchValueSelect(u) {},
            filter: {
              itemId: efData.itemId,
            },
          },
        },
      ],
      button: { style: 'w-fit' },
      initialFormState: {
        item: efData.item,
        prQuantity: efData.quantity,
        targetDate: efData.targetDate,
        validity:
          companySettings?.details.purchase?.purchaseEnquiry?.defaultValidity ||
          '',
        deliveryMode: '',
        deliveryTerm: { id: '', name: '' },
        paymentTerm: { id: '', name: '' },
        vendors: [],
      },
      allowUnchanged: true,
      mapTToU: mapPFDToP,
      onSubmit: onSubmit,
      // onChange: (data) => {
      //   setCurrentState(data);
      //   handleFormChange(data);
      // },
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<Partial<CreateEnquiryFormType>>(formProps);

    const SelectVendorContact = renderSelectVendorContact();

    return (
      <div>
        <SelectVendorContact
          vendorDetails={vendorDetails}
          vendorsToSentEnquiries={vendorsToSentEnquiries}
          setVendorsToSentEnquiries={setVendorsToSentEnquiries}
        />

        <div className='flex space-x-3'>
          <div>Schedule</div>
          <AdvancedLoadingButton
            defaultStyle='bg-transparent hover:drop-shadow-md text-blue-600 font-semibold hover:text-blue-900 rounded'
            behaviour='modal'
            modal={{
              title: `${
                deliverySchedule.length === 0 ? 'Add' : 'Edit'
              } Delivery Schedule`,
              content: ({ onClose }) => {
                return (
                  <PEDeliverySchedule
                    closeModal={onClose}
                    setDeliverySchedule={setDeliverySchedule}
                    deliverySchedule={deliverySchedule}
                    totalQuantity={efData.quantity}
                  />
                );
              },
            }}
            text={`Delivery${
              deliverySchedule.length > 0 ? `(${deliverySchedule.length})` : ''
            }`}
          />
          <AdvancedLoadingButton
            defaultStyle='bg-transparent text-blue-600 font-semibold hover:text-blue-900 rounded'
            behaviour='modal'
            modal={{
              title: `${
                paymentSchedule.length === 0 ? 'Add' : 'Edit'
              } Payment Schedule`,
              content: ({ onClose }) => {
                return (
                  <PEPaymentSchedule
                    closeModal={onClose}
                    setPaymentSchedule={setPaymentSchedule}
                    paymentSchedule={paymentSchedule}
                  />
                );
              },
            }}
            text={`Payment${
              paymentSchedule.length > 0 ? `(${paymentSchedule.length})` : ''
            }`}
          />
        </div>

        <Form currentState={currentState} />
      </div>
    );

    function mapPFDToP(
      b: FormDataType<Partial<CreateEnquiryFormType>>
    ): CreateEnquiryFormType {
      return b as CreateEnquiryFormType;
    }
  };
}
