import { StatutoryReportBuilderType } from '@erp_core/erp-types/dist/types/modules/hrd/statutory-returns/report-builder';
import { ReportEditValueForm } from './edit-value-form';

export function ReportEdit({
  onClose,
  obj,
  propName,
  formType,
  options,
  showTextArea,
  statutoryReport,
  setStatutoryReport,
}: {
  onClose: () => void;
  obj: any;
  propName: string;
  formType: 'text' | 'select';
  options?: Array<string>;
  showTextArea?: boolean;
  setStatutoryReport: (value: any) => void;
  statutoryReport: StatutoryReportBuilderType;
}): JSX.Element {
  return (
    <ReportEditValueForm
      formType={formType}
      options={options}
      showTextArea={showTextArea}
      onClose={(val) => {
        obj[propName] = val;
        setStatutoryReport({ ...statutoryReport });
        onClose();
      }}
      initialValue={obj[propName]}
      propName={propName}
    />
  );
  // setModal({
  //   ...modal,
  //   isVisible: true,
  //   body: (

  //   ),

  //   title: `Edit ${propName.toUpperCase()}`,
  // });
}
