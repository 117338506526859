import { Approval } from '@erp_core/erp-icons/icons/web/approval';
import { Unapproval } from '@erp_core/erp-icons/icons/web/unapproval';
import {
  CurrentUserType,
  RoleType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceType,
  CombinedAttDataType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { TableBody, TableBodyRow } from '@erp_core/erp-ui-components';
import { BoltIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { renderAuthWrapper } from '../../../../../../components/auth';
import { UseUserAuthorization } from '../../../../../../hooks/admin/user-authorization/use-user-authorization';
import { AttendanceFilter } from '../../../../../../models/interfaces/hrd/attendance';
import {
  EmployeeAttendanceStatusMapper,
  EmployeeStatusIconMapper,
  employeeTrackngStatus,
} from '../../../../../../utils/common';
import { UserRendererInterface } from '../../../../../common/fragments/user';
import { renderGroup } from '../../../util/segregate-punches';
import { GatePassIcon } from '../icons/gatepass-icon';
import { LeavesIcon } from '../icons/leaves-icon';
import { OffSiteIcon } from '../icons/offsite-icon';
import { OvertimeIcon } from '../icons/overtime-icon';
import { ShiftInfo } from '../shift-info';
import { renderSummary } from '../summary';

export type AttendanceBodyProps =
  | {
      type: 'pending-actions';
      list: Array<{ attendance: CombinedAttDataType; reason: string }>;
    }
  | {
      type: 'daybook';
      list: Array<{ attendance: CombinedAttDataType }>;
    };

export function renderAttendanceBody({
  userRendererService,
  currentUser,
  getAllAttendanceSync,
  setAttendance,
  currentUserRoles,
  useUserAuthorization,
  fetchData,
}: {
  userRendererService: UserRendererInterface;
  currentUser: CurrentUserType;
  getAllAttendanceSync: (
    filter?: AttendanceFilter
  ) => Promise<AttendanceType[]>;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  currentUserRoles: RoleType[];
  useUserAuthorization: UseUserAuthorization;
  fetchData: () => Promise<void>;
}) {
  const Summary = renderSummary();

  return function AttendanceBody({
    type,
    list,
  }: AttendanceBodyProps): TableBody {
    const AuthWrapper = renderAuthWrapper({ useUserAuthorization });

    return list.map((pr) => {
      const row: TableBodyRow = {
        cells: [],
        rowData: {
          attendance: pr.attendance,
        },
      };
      if (type === 'pending-actions') {
        row.cells.push({
          value: pr?.attendance?.date || '-',
          style: 'hidden md:table-cell',
        });
      }

      row.cells.push({
        value: (
          <div>
            <div className='text-md flex'>
              <div className='my-auto bg-slate-50'>
                {
                  <span
                    className='text-3xl'
                    title={pr.attendance?.employeeProfile?.details?.grade?.name}
                  >
                    <userRendererService.profilePic
                      size='large'
                      id={pr.attendance.employee.id}
                    />
                  </span>
                }
              </div>
              <div>
                <div>
                  <a
                    href={`/users/profile/${pr.attendance?.employee.id}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {pr.attendance?.finalized ? (
                      <span
                        title={`Finalized by ${pr.attendance?.finalizedBy?.name} as ${pr.attendance?.status}`}
                        className='border-r font-bold bg-green-200 border-green-300'
                      >
                        {pr.attendance?.employee.name}
                        {pr.attendance?.details?.summary?.autofinalized ? (
                          <BoltIcon className='text-amber-500 animate-bounce w-4 inline' />
                        ) : null}
                      </span>
                    ) : (
                      <span className='font-bold'>
                        {pr.attendance?.employee.name}
                      </span>
                    )}
                  </a>
                  {
                    <span
                      title='Attendance device emp-id'
                      className='font-semibold border ml-1 px-1 border-gray-500 rounded text-xs'
                    >{` #${
                      pr.attendance?.employeeProfile?.details.employeeId || '?'
                    }`}</span>
                  }
                  {
                    <span
                      title={pr.attendance?.employeeProfile?.details.status}
                    >
                      <EmployeeStatusIconMapper
                        status={
                          pr.attendance?.employeeProfile?.details?.status || ''
                        }
                      />
                    </span>
                  }
                  <div className='inline-flex'>
                    <GatePassIcon
                      d={pr.attendance}
                      gatePasses={pr.attendance?.gatePasses || []}
                    />
                    <OvertimeIcon d={pr.attendance} />
                    {pr.attendance?.isOnWeeklyOff && (
                      <span title='On weekly off'>🏡</span>
                    )}
                    <LeavesIcon empLeaves={pr.attendance?.empLeaves} />
                    <OffSiteIcon d={pr.attendance} />
                    {!employeeTrackngStatus({
                      grade: pr.attendance?.employeeProfile?.details?.grade,
                    }) && <span title={'No tracking'}>🙈</span>}
                  </div>
                </div>
                <div className='text-xs font-thin'>
                  {pr.attendance?.employeeProfile?.company.name}
                </div>
                <ShiftInfo
                  d={pr.attendance}
                  date={pr.attendance.date}
                  attendances={[]}
                  employee={pr.attendance?.employeeProfile}
                  rotationalShiftDays={[]}
                  shiftSchedules={[]}
                />
              </div>
            </div>
            <div className='md:hidden'>
              <div className='flex'>
                <div>{pr?.attendance?.date}</div>
                <div>
                  Check-In
                  {renderGroup({
                    list: pr.attendance?.punchResult?.checkInList || [],
                    date: pr.attendance?.date,
                    type: 'check-in',
                    isFinalized: pr.attendance?.finalized,
                    currentUser: currentUser,
                    getAllAttendanceSync: getAllAttendanceSync,
                    setAttendance: setAttendance,
                    getData: async () => {
                      await fetchData();
                    },
                    d: pr.attendance,
                    currentUserRoles: currentUserRoles || [],
                    useUserAuthorization,
                  })}
                </div>
                <div>
                  Check-out
                  {renderGroup({
                    list: pr.attendance?.punchResult?.checkOutList || [],
                    date: pr.attendance?.date,
                    type: 'check-out',
                    isFinalized: pr.attendance?.finalized,
                    currentUser: currentUser,
                    getAllAttendanceSync: getAllAttendanceSync,
                    setAttendance: setAttendance,
                    getData: async () => {
                      await fetchData();
                    },
                    d: pr.attendance,
                    currentUserRoles: currentUserRoles || [],
                    useUserAuthorization,
                  })}
                </div>
              </div>
              <div>
                Summary
                <Summary
                  pr={pr}
                  useUserAuthorization={useUserAuthorization}
                  key={pr.attendance?.employee?.id}
                  d={pr.attendance}
                  punchResult={pr.attendance?.punchResult}
                  punchRemarks={''}
                  autoFinalizeFilter={''}
                />
              </div>
              <div>
                <AuthWrapper
                  action='UI:BTN-ATTEN-FINALIZE:VIEW'
                  children={
                    <>
                      {!pr.attendance?.finalized &&
                      pr.attendance?.safeToAutoFinallize ? (
                        <div className='px-1 border bg-red-50 w-fit font-semibold border-amber-500 text-black'>
                          Auto-Finalizable{' '}
                          <BoltIcon className='text-amber-500 animate-pulse inline h-5' />
                        </div>
                      ) : null}
                      <EmployeeAttendanceStatusMapper
                        status={pr.attendance?.status}
                        leaves={pr.attendance?.empLeaves}
                      />
                    </>
                  }
                />
                {pr.attendance?.verified?.verified === 'yes' ? (
                  <div
                    title={`Verified at ${moment(
                      pr.attendance?.verified?.verifiedAt
                    ).format('LLL')}`}
                    className='px-1 border bg-green-50 w-fit border-green-300 text-green-800'
                  >
                    <Approval className='inline w-5 h-5 stroke-green-800' />{' '}
                    {pr.attendance?.verified?.verifiedBy?.name}
                  </div>
                ) : (
                  <div
                    title='Verification pending'
                    className='px-1 border bg-red-50 w-fit border-red-300 text-red-800'
                  >
                    <Unapproval className='inline w-5 h-5 stroke-red-800' />{' '}
                    {pr.attendance?.employeeProfile?.details?.authorizations
                      ?.attendanceSupervisor?.name ||
                      pr.attendance?.shiftDay?.supervisor?.name ||
                      'unknown'}
                  </div>
                )}
              </div>
              <div>
                Reason:
                {pr.reason}
              </div>
            </div>
          </div>
        ),
      });

      row.cells.push({
        style: 'hidden md:table-cell',
        value: renderGroup({
          list: pr.attendance?.punchResult?.checkInList || [],
          date: pr.attendance?.date,
          type: 'check-in',
          isFinalized: pr.attendance?.finalized,
          currentUser: currentUser,
          getAllAttendanceSync: getAllAttendanceSync,
          setAttendance: setAttendance,
          getData: fetchData,
          d: pr.attendance,
          currentUserRoles: currentUserRoles || [],
          useUserAuthorization,
        }),
      });

      row.cells.push({
        style: 'hidden md:table-cell',
        value: renderGroup({
          list: pr.attendance?.punchResult?.checkOutList || [],
          date: pr.attendance?.date,
          type: 'check-out',
          isFinalized: pr.attendance?.finalized,
          currentUser: currentUser,
          getAllAttendanceSync: getAllAttendanceSync,
          setAttendance: setAttendance,
          getData: fetchData,
          d: pr.attendance,
          currentUserRoles: currentUserRoles || [],
          useUserAuthorization,
        }),
      });

      row.cells.push({
        style: 'hidden md:table-cell',
        value: (
          <Summary
            useUserAuthorization={useUserAuthorization}
            key={pr.attendance?.employee?.id}
            d={pr.attendance}
            pr={pr}
            punchResult={pr.attendance?.punchResult}
            punchRemarks={''}
            autoFinalizeFilter={''}
          />
        ),
      });

      // row.cells.push({
      //   style: 'hidden md:table-cell',
      //   value: (
      //     <div>
      //       <AuthWrapper
      //         action='UI:BTN-ATTEN-FINALIZE:VIEW'
      //         children={
      //           <>
      //             {!pr.attendance?.finalized &&
      //             pr.attendance?.safeToAutoFinallize ? (
      //               <div className='px-1 border bg-red-50 w-fit font-semibold border-amber-500 text-black'>
      //                 Auto-Finalizable{' '}
      //                 <BoltIcon className='text-amber-500 animate-pulse inline h-5' />
      //               </div>
      //             ) : null}
      //             {pr.attendance.finalized &&
      //             pr.attendance.details?.summary?.autofinalized ? (
      //               <span className='border border-gray-200 rounded-full py-1 px-2'>
      //                 <BoltIcon className='text-amber-500 animate-bounce w-4 inline' />
      //                 <span className='mx-1'>
      //                   {pr.attendance.details?.summary?.autofinalizeReason}
      //                 </span>
      //               </span>
      //             ) : null}
      //             {pr.attendance.finalized ? (
      //               <span className='border border-gray-200 rounded-full py-1 px-2'>
      //                 <CurrencyRupeeIcon className='text-green-500 animate-ping w-4 inline' />
      //                 <span className='mx-1'>
      //                   {pr.attendance.details?.summary?.payableWorkHours} Hours
      //                 </span>
      //               </span>
      //             ) : null}
      //             <EmployeeAttendanceStatusMapper
      //               status={pr.attendance?.status}
      //               leaves={pr.attendance?.empLeaves}
      //             />
      //           </>
      //         }
      //       />
      //       {pr.attendance?.verified?.verified === 'yes' ? (
      //         <div
      //           title={`Verified at ${moment(
      //             pr.attendance?.verified?.verifiedAt
      //           ).format('LLL')}`}
      //           className='px-1 border bg-green-50 w-fit border-green-300 text-green-800'
      //         >
      //           <Approval className='inline w-5 h-5 stroke-green-800' />{' '}
      //           {pr.attendance?.verified?.verifiedBy?.name}
      //         </div>
      //       ) : (
      //         <div
      //           title='Verification pending'
      //           className='px-1 border bg-red-50 w-fit border-red-300 text-red-800'
      //         >
      //           <Unapproval className='inline w-5 h-5 stroke-red-800' />{' '}
      //           {pr.attendance?.employeeProfile?.details?.authorizations
      //             ?.attendanceSupervisor?.name ||
      //             pr.attendance?.shiftDay?.supervisor?.name ||
      //             'unknown'}
      //         </div>
      //       )}
      //     </div>
      //   ),
      // });

      if (type === 'pending-actions') {
        row.cells.push({
          style: 'hidden md:table-cell',
          value: pr.reason,
        });
      }

      return row;
    });
  };
}
