import {
  Company,
  CurrentUserType,
  RoleType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  DropDown,
  DropDownOption,
  LoadingButton,
} from '@erp_core/erp-ui-components';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
// import initials from 'initials';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Notification from '../../../components/notification';
import { LocationContext } from '../../../contexts/location';
import { UserContext } from '../../../contexts/user';
import { UseCompanies } from '../../../hooks/admin/company-admin/use-companies-admin';
import { UseCurrentLocation } from '../../../hooks/admin/location-admin/use-current-location';
import { UseLocations } from '../../../hooks/admin/location-admin/use-locations';
import { UseCurrentUserRoles } from '../../../hooks/admin/role-admin/use-current-user-roles';
import { NotificationInterface } from '../../../models/interfaces/notification';
import { UserRendererInterface } from '../../../modules/common/fragments/user';
import { CompanyGroupLocalStorage } from '../../../utils/token-helper';
import { getNavigation } from './navigation-links';

type NavBarProps = {
  logoutUser: any;
  useLocations: UseLocations;
  useCurrentLocation: UseCurrentLocation;
  useCompanies: UseCompanies;
  notificationService: NotificationInterface;
  useCurrentUserRoles: UseCurrentUserRoles;
  userRendererService: UserRendererInterface;
};

export function Navbar({
  logoutUser,
  useLocations,
  useCurrentLocation,
  useCompanies,
  notificationService,
  userRendererService,
  useCurrentUserRoles,
}: NavBarProps) {
  const {
    location,
    setLocation,
    company: currentCompany,
    setCompany,
  } = useContext(LocationContext);
  const { user } = useContext(UserContext);
  // const { data: currentCompany, setCurrentCompany } = useCurrentCompany();
  const [navigation, setNavigation] = useState<
    { name: string; path: string; access: boolean }[]
  >([]);
  const [companyGroupLogo, setCompanyGroupLogo] = useState(
    '/company-logo/recat-logo.svg'
  );

  const { data: currentUserRoles } = useCurrentUserRoles();

  useEffect(() => {
    if (currentUserRoles) {
      setNavigation(getNavigation(currentUserRoles));
    }
  }, [currentUserRoles]);

  const { setCurrentLocation } = useCurrentLocation();
  const {
    location: currentLocation,
    companyGroup: currentCompanyGroup,
  } = useContext(LocationContext);

  const { data: locations, getAll: getAllLocations } = useLocations();

  let { pathname } = useLocation();

  const { data: companies, getAll: getAllCompanies } = useCompanies();
  useEffect(() => {
    getAllCompanies();
    getAllLocations({
      type: 'real',
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllLocations({
      type: 'real',
    });
    // eslint-disable-next-line
  }, [currentCompany]);

  const comLocal = new CompanyGroupLocalStorage();
  useEffect(() => {
    if (locations && locations.length > 0) {
      const loc =
        comLocal?.get()?.[user.id]?.[currentCompanyGroup.id]?.currentLocation
          ?.id || ''; // [currentCompanyGroup.id]?.[currentCompany.id];
      const location = locations.find((l) => l.id === loc);
      setCurrentLocation(location || locations[0]);
    }
    // eslint-disable-next-line
  }, [locations, currentCompany?.id]);

  useEffect(() => {
    setCompanyGroupLogo(`/company-logo/${currentCompanyGroup.id}.svg`);
  }, [currentCompanyGroup]);

  const [companyList, setCompanyList] = useState<{
    selected: DropDownOption;
    list: Array<DropDownOption>;
  } | null>(null);

  useEffect(() => {
    if (!user?.id || !currentCompanyGroup?.id) return;
    const options =
      companies?.map((c) => ({
        text: c.name,
        id: c.id,
        shortname: c.shortName,
        icon: c.logo ? (
          <img alt='' src={c.logo} className='h-6 mr-2' />
        ) : (
          <span className='mr-1 text-blue-400'>{c.shortName}</span>
        ),
      })) || [];

    const companyId = comLocal.get()?.[user.id]?.[currentCompanyGroup.id]
      ?.currentCompany?.id;

    const selectedCompany = currentCompany
      ? options.find((c) => c.id === currentCompany.id || c.id === companyId) ||
        options[0]
      : options[0];
    setCompany(
      companies?.find((c) => c.id === selectedCompany.id) ||
        (companies?.[0] as Company)
    );
    setCompanyList({
      selected: selectedCompany,
      list: options,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  const handleCompanyChange = (value: DropDownOption) => {
    const newCompanyList = companyList;
    if (newCompanyList) {
      newCompanyList.selected = value;
    }
    setCompanyList(newCompanyList);
    const com = companies?.find((c) => c.id === newCompanyList?.selected.id);
    if (com) {
      setCompany(com);
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const setSelectedLocation = (value: DropDownOption) => {
    const newSelected = locations && locations.find((f) => f.id === value.id);
    if (newSelected) {
      setCurrentLocation(newSelected);
      setLocation(newSelected);
    }

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const locationsOptions =
    locations?.map((l) => ({
      text: l.name,
      value: l.id,
      shortname: l.shortName,
      id: l.id,
    })) || [];

  const selectedLocation = locationsOptions.find((l: any) => {
    return l.text === location.name || currentLocation.id === l.id;
  });

  return (
    <Disclosure as='nav' className='bg-indigo-900 sticky top-0 z-50'>
      {({ open, close }) => (
        <>
          <div className='max-w-8xl mx-auto px-2 sm:px-6 lg:px-4'>
            <div className='relative flex sm:items-center sm:justify-between h-16'>
              <div className='inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>

              <div className='flex-1 flex items-center justify-start sm:items-stretch sm:justify-start'>
                <div className='sm:min-w-[220px]'>
                  <div className='flex-shrink-0 flex items-center hover:bg-indigo-800 hover:cursor-pointer'>
                    <div className='fixed top-0 left-14 z-10 bg-indigo-900 p-0.5'>
                      {currentCompany?.id ? (
                        <img
                          onError={() => {
                            setCompanyGroupLogo('/company-logo/recat-logo.svg');
                          }}
                          alt={`${currentCompany.name}`}
                          src={companyGroupLogo}
                          className='h-4 inline'
                        />
                      ) : (
                        <img
                          alt='re-cat'
                          src='/company-logo/recat-logo.svg'
                          className='h-4 inline'
                        />
                      )}
                      <span className='text-xs pl-1 text-white'>
                        {currentCompanyGroup.name
                          ? currentCompanyGroup.name.toUpperCase()
                          : 'RECAT'}
                      </span>
                    </div>
                    {companyList && companyList.selected ? (
                      <DropDown
                        // eslint-disable-next-line react/style-prop-object
                        style={
                          'w-4/5 ml-1 sm:w-full border border-slate-200 rounded-lg'
                        }
                        selected={{
                          icon: (
                            <>
                              <div className=''>
                                {companyList.selected.icon}
                              </div>
                            </>
                          ),
                          text: companyList.selected.text,
                          id: companyList.selected.id,
                        }}
                        options={companyList.list}
                        onChange={handleCompanyChange}
                      />
                    ) : null}
                  </div>
                </div>

                <div className='hidden sm:block sm:ml-6'>
                  <div className='flex space-x-1'>
                    {navigation.map((item, idx) => {
                      if (item.access) {
                        return (
                          <Link
                            key={idx}
                            to={item.path}
                            className={`${
                              pathname.startsWith(item.path)
                                ? 'text-indigo-900 bg-slate-50'
                                : 'text-slate-100 hover:bg-indigo-800'
                            } px-2 py-2 rounded-md text-sm font-medium`}
                          >
                            {item.name}
                          </Link>
                        );
                      }

                      return null;
                    })}
                  </div>
                </div>
              </div>

              <LocationDropdown
                userRendererService={userRendererService}
                renderInDropdown={false}
                locationsOptions={locationsOptions}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                user={user}
                currentUser={user}
                currentUserRoles={currentUserRoles}
                logoutUser={logoutUser}
                notificationService={notificationService}
              />
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1'>
              <LocationDropdown
                userRendererService={userRendererService}
                renderInDropdown={true}
                locationsOptions={locationsOptions}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                user={user}
                currentUser={user}
                currentUserRoles={currentUserRoles}
                logoutUser={logoutUser}
                notificationService={notificationService}
              />
              {navigation.map((item) => {
                if (item.access) {
                  return (
                    <Disclosure.Button
                      key={item.name}
                      as='div'
                      // href={item.path}
                      className={`
                        ${
                          pathname.startsWith(item.path)
                            ? 'text-white  bg-indigo-800'
                            : 'text-gray-300 hover:bg-indigo-700 hover:text-white'
                        } block px-3 py-2 rounded-md text-base font-medium`}
                      aria-current={
                        pathname.startsWith(item.path) ? 'page' : undefined
                      }
                    >
                      <Link
                        onClick={() => {
                          close();
                        }}
                        to={item.path}
                      >
                        {item.name}
                      </Link>
                    </Disclosure.Button>
                  );
                }
                return null;
              })}

              <div className='border-t mt-8 py-4'>
                <Disclosure.Button
                  as='div'
                  className={`
                    ${
                      pathname.startsWith(`/users/profile/${user.id}`)
                        ? 'text-white bg-indigo-700'
                        : 'text-gray-300 hover:bg-indigo-700 hover:text-white'
                    } block px-3 py-2 rounded-md text-base font-medium`}
                >
                  <Link
                    onClick={() => {
                      close();
                    }}
                    to={`/users/profile/${user.id}`}
                  >
                    Your Profile
                  </Link>
                </Disclosure.Button>

                <Disclosure.Button
                  as='div'
                  className='text-gray-300 hover:bg-indigo-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  <Link
                    onClick={() => {
                      close();
                    }}
                    to={`/hrd/emp-job-task/${user.id}`}
                  >
                    Your Work Logs
                  </Link>
                </Disclosure.Button>
                <Disclosure.Button as='div'>
                  <div className='w-full space-x-4 flex items-center p-2'>
                    <LoadingButton
                      behaviorFn={async () => {
                        close();
                        logoutUser();
                      }}
                      defaultStyle='p-2 rounded bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600'
                      text='Sign out'
                    />
                    <div className='text-white'>{user.name}</div>
                  </div>
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

function LocationDropdown({
  locationsOptions,
  selectedLocation,
  setSelectedLocation,
  user,
  currentUser,
  currentUserRoles,
  logoutUser,
  notificationService,
  renderInDropdown,
  userRendererService,
}: {
  userRendererService: UserRendererInterface;
  locationsOptions: {
    text: string;
    value: string;
    shortname: string;
    id: string;
  }[];
  selectedLocation?: {
    text: string;
    value: string;
    shortname: string;
    id: string;
  };
  setSelectedLocation: (value: DropDownOption) => void;
  user: CurrentUserType;
  currentUser: CurrentUserType;
  currentUserRoles?: RoleType[];
  logoutUser: () => void;
  notificationService: NotificationInterface;
  renderInDropdown: boolean;
}): JSX.Element {
  const styles = renderInDropdown
    ? '' // for dropdown in the mobile menu
    : 'invisible md:visible absolute inset-y-0 right-0'; // for bigger nav
  return (
    <div className={`${styles} flex items-center pr-2`}>
      <div className='mx-1'>
        {locationsOptions?.length && selectedLocation ? (
          <DropDown
            selected={{
              icon: (
                <>
                  <span className='mr-1 text-blue-400'>
                    {selectedLocation.shortname}:{' '}
                  </span>
                </>
              ),
              text: selectedLocation.text,
              id: selectedLocation.id,
            }}
            options={locationsOptions.map((op) => ({
              id: op.value,
              icon: (
                <span className='mr-1 text-blue-400'>{op.shortname}: </span>
              ),
              text: op.text,
            }))}
            onChange={setSelectedLocation}
          />
        ) : null}
      </div>

      {/* Profile dropdown */}
      <Menu as='div' className='hidden sm:block ml-3 relative z-50'>
        <div>
          <Menu.Button className='p-1 flex text-sm border border-gray-200 rounded-full'>
            {/* <span className='sr-only'>Open user menu</span>
            <div className='rounded-full w-5 h-5  bg-slate-600 text-blue-500 m-auto'>
              {initials(user.name)}
            </div>
            <div className='flex-auto pl-1'>
              <div>
                <span className='text-md text-white'>{` ${user.name}`}</span>
              </div>
            </div> */}
            <userRendererService.userCard
              size='medium'
              link={false}
              id={user.id}
              name={user.name}
              headerStyle='text-md text-white mx-1'
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`/hrd/resources/employees/${currentUser.id}/profile`}
                  className={`${
                    active ? 'bg-gray-100' : ''
                  } block px-4 py-2 text-sm text-gray-700`}
                >
                  Your Profile
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div>
                  <div
                    className={`
                  ${
                    active ? 'bg-gray-100' : ''
                  } block px-4 py-2 text-sm text-gray-700
                `}
                  >
                    <div className='font-semibold'>Roles</div>
                    <ul className='block px-4 py-2 text-xs text-gray-700'>
                      {currentUserRoles?.map((x) => (
                        <li key={x.id}>{x.name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`/hrd/department/emp-job-task/${currentUser.id}`}
                  className={`
                ${
                  active ? 'bg-white text-indigo-700' : 'text-gray-700'
                } block px-4 py-2 text-sm'`}
                >
                  Your Work Log
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {() => (
                <span
                  className='block px-4 py-2 text-sm text-gray-700 hover:text-blue-500 hover:bg-gray-100 hover:cursor-pointer'
                  onClick={logoutUser}
                >
                  Sign out
                </span>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>

      {/* Notification dropdown */}
      <Notification notificationService={notificationService} />
    </div>
  );
}
