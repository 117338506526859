import { SalaryParamGroup } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param-group';
import { AdvancedLoadingButton } from '@erp_core/erp-ui-components';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

export function UpDownDelete({
  idx,
  list,
  setSalParams,
  salParams,
  textSize,
  resourceName,
}: {
  idx: number;
  list: Array<any>;
  setSalParams: React.Dispatch<React.SetStateAction<SalaryParamGroup>>;
  salParams: SalaryParamGroup;
  textSize: number;
  resourceName: string;
}): JSX.Element {
  return (
    <div className='flex w-18'>
      {idx !== 0 ? (
        <div>
          <ArrowUpIcon
            className={`w-${textSize} h-${textSize} text-gray-500 cursor-pointer`}
            onClick={() => {
              const deleted = list.splice(idx, 1);
              list.splice(idx - 1, 0, deleted[0]);

              setSalParams({ ...salParams });
            }}
          />
        </div>
      ) : null}
      {idx !== list.length - 1 ? (
        <div>
          <ArrowDownIcon
            className={`w-${textSize} h-${textSize} text-gray-500 cursor-pointer`}
            onClick={() => {
              const deleted = list.splice(idx, 1);
              list.splice(idx + 1, 0, deleted[0]);

              setSalParams({ ...salParams });
            }}
          />
        </div>
      ) : null}
      <div>
        <AdvancedLoadingButton
          defaultStyle='bg-white p-0'
          text={
            <XMarkIcon
              className={`w-${textSize} h-${textSize} inline text-red-500 cursor-pointer`}
            />
          }
          behaviour='confirm'
          onConfirm={() => {
            return {
              title: `Are you sure you want to delete ${resourceName} ${
                list[idx].name || ''
              } ?`,
              onConfirm: async () => {
                list.splice(idx, 1);
                setSalParams({ ...salParams });
              },
            };
          }}
        />
      </div>
    </div>
  );
}
