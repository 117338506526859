import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  Congratulations,
  MonthSelector,
  renderCardComponent,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../../contexts/location';
import { UserContext } from '../../../contexts/user';
import { UseCompanyGroupSettings } from '../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseCurrentUserRoles } from '../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseAttendance } from '../../../hooks/hrd/attendance/use-attendance';
import { UseAttendances } from '../../../hooks/hrd/attendance/use-attendances';
import { UseGatePass } from '../../../hooks/hrd/employee/profile/gate-pass/use-gate-pass';
import { UseOvertime } from '../../../hooks/hrd/employee/profile/overtime/use-overtime';
import { UseOvertimes } from '../../../hooks/hrd/employee/profile/overtime/use-overtimes';
import { UseEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseLeave } from '../../../hooks/hrd/leave/use-leave';
import { UseLeaves } from '../../../hooks/hrd/leave/use-leaves';
import { UseOffSiteWorkHour } from '../../../hooks/hrd/off-site-work-hours/use-off-site-work-hour';
import {
  AttendanceInterface,
  EmployeePendingActionsRes,
} from '../../../models/interfaces/hrd/attendance';
import { LeaveInterface } from '../../../models/interfaces/hrd/leave';
import { LogRecordInterface } from '../../../models/interfaces/planning/log-record';
import { UserRendererInterface } from '../../common/fragments/user';
import { renderActions } from '../attendance/day-book/components/table/table-actions';
import { renderAttendanceBody } from '../attendance/day-book/components/table/table-body';
import { Header } from '../attendance/day-book/components/table/table-header';
import { saveNewOvertime } from '../attendance/util/add-overtime';

export function renderEmployeePendingActionsPage({
  attendanceService,
  leaveService,
  useUserAuthorization,
  useEmployees,
  useLeaves,
  useEmployeeProfile,
  useGatePass,
  useOffSiteWorkHour,
  useOvertimes,
  useOvertime,
  useAttendance,
  useAttendances,
  useCompanyGroupSettings,
  useCurrentUserRoles,
  userRendererService,
  logRecordService,
  useLeave,
}: {
  attendanceService: AttendanceInterface;
  leaveService: LeaveInterface;
  useUserAuthorization: UseUserAuthorization;
  useEmployees: UseEmployeeProfiles;
  useLeaves: UseLeaves;
  useEmployeeProfile: UseEmployeeProfile;
  useGatePass: UseGatePass;
  useOffSiteWorkHour: UseOffSiteWorkHour;
  useOvertimes: UseOvertimes;
  useOvertime: UseOvertime;
  useAttendance: UseAttendance;
  useAttendances: UseAttendances;
  useCompanyGroupSettings: UseCompanyGroupSettings;
  useCurrentUserRoles: UseCurrentUserRoles;
  userRendererService: UserRendererInterface;
  logRecordService: LogRecordInterface;
  useLeave: UseLeave;
}): () => JSX.Element {
  let Table = renderTableComponent();
  let Card = renderCardComponent();

  return function EmployeePendingActionsPage(): JSX.Element {
    const { user: currentUser } = useContext(UserContext);
    const [pendingActionsData, setPendingActionsData] = useState<
      EmployeePendingActionsRes[]
    >([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [date, setDate] = useState<{
      mode: 'date' | 'month';
      value: string;
    }>(
      JSON.parse(
        localStorage.getItem('employee-pending-action-month') ||
          JSON.stringify({
            mode: 'month',
            value: moment.utc().format('YYYY-MM'),
          })
      )
    );
    const { get: getAllowedActions } = useUserAuthorization();
    const { getSync: getEmployeeSync } = useEmployeeProfile();
    const { syncSet: setGatePass } = useGatePass();
    const { syncSet: setOffsite } = useOffSiteWorkHour();
    const { getAllSync: getLeavesSync } = useLeaves();
    const { syncSet: setLeave } = useLeave();
    const { getAllSync: getAllAttendanceSync } = useAttendances();
    const { syncSet: setAttendance } = useAttendance();
    const { syncSet: setOvertime } = useOvertime();
    const { company: currentCompany } = useContext(LocationContext);
    const { getAllSync: getCompanyGroupSettings } = useCompanyGroupSettings();
    const { data: currentUserRoles } = useCurrentUserRoles();
    const [companyGroupSetting, setCompanyGroupSetting] = useState<
      CompanyGroupSetting
    >();
    const [isHr, setIsHr] = useState<boolean>(false);
    const [isSystemAdmin, setIsSystemAdmin] = useState<boolean>(false);

    useEffect(() => {
      if (
        currentUserRoles?.length &&
        currentUserRoles.find((x) => x.name === 'HR Officer')
      ) {
        setIsHr(true);
      }

      setIsSystemAdmin(
        currentUserRoles?.find((x) => x.name === 'System-Admin') ? true : false
      );
    }, [currentUserRoles]);

    async function empPendingActnsData({
      month,
      userId,
      toRefresh,
    }: {
      month: string;
      userId: string;
      toRefresh?: string;
    }) {
      setLoading(true);
      const empActnsData = await attendanceService.getEmployeePendingActions({
        month,
        employee: userId,
        toRefresh,
      });
      setPendingActionsData(empActnsData);
      setLoading(false);
    }

    useEffect(() => {
      if (date.value && currentUser.id) {
        fetchData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser.id, date]);

    useEffect(() => {
      getCompanyGroupSettings().then((cgs) => {
        if (cgs.length > 0) {
          setCompanyGroupSetting(cgs[0]);
        }
      });
      // eslint-disable-next-line
    }, []);

    let sortDateActionsData = pendingActionsData.sort(function (a, b) {
      if (a.attendance && b.attendance) {
        return moment(a.attendance.date).diff(moment(b.attendance.date));
      }

      return -1;
    });

    const filtered: Array<string> = [];

    const uniqData = sortDateActionsData.filter((x) => {
      if (!moment(x.attendance.date).isValid()) {
        return false;
      }
      if (
        x.attendance?.date &&
        moment(x.attendance.date).isValid() &&
        moment(x.attendance.date).isSameOrAfter(
          moment(moment().format('YYYY-MM-DD'))
        )
      ) {
        return false;
      }

      const search = filtered.find(
        (f) =>
          f ===
          `${x.attendance?.date}-${x.attendance?.employee?.id}-${
            x.attendance.id || ''
          }`
      );
      if (search) {
        const existingEntry = sortDateActionsData.find(
          (y) =>
            `${y.attendance?.date}-${y.attendance?.employee?.id}-${
              y.attendance.id || ''
            }` ===
            `${x.attendance?.date}-${x.attendance?.employee?.id}-${
              x.attendance.id || ''
            }`
        );

        if (existingEntry && !existingEntry.reason.includes(x.reason)) {
          existingEntry.reason += `. ${x.reason}`;
        }
        return false;
      }

      filtered.push(
        `${x.attendance?.date}-${x.attendance?.employee?.id}-${
          x.attendance.id || ''
        }`
      );

      return true;
    });

    async function fetchData() {
      empPendingActnsData({
        month: date.value,
        userId: currentUser.id,
      });
    }

    const AttBody = renderAttendanceBody({
      userRendererService,
      currentUser,
      getAllAttendanceSync,
      setAttendance,
      currentUserRoles: currentUserRoles || [],
      useUserAuthorization,
      fetchData,
    });

    const tableBody: TableBody = AttBody({
      type: 'pending-actions',
      list: uniqData,
    });

    const Actions = renderActions({
      isHr,
      setAttendance,
      leaveService,
      currentUser,
      useEmployees,
      useLeaves,
      getEmployeeSync,
      setGatePass,
      setOffsite,
      useOvertimes,
      saveNewOvertime,
      setOvertime,
      currentCompany,
      companyGroupSetting,
      fetchData,
      isSystemAdmin,
      attendanceService,
      logRecordService,
      getLeavesSync,
      setLeave,
    });

    const actions = Actions();

    function renderBody() {
      if (loading) {
        return (
          <div className='text-center'>
            <Recat className='h-5 inline animate-pulse mx-4' />
          </div>
        );
      }

      if (pendingActionsData.length) {
        return (
          <Table
            isDataLoading={loading}
            header={Header({ type: 'pending-action' })}
            body={tableBody}
            auth={{ actions: getAllowedActions().actions }}
            actions={actions}
          />
        );
      }

      return (
        <div>
          <Congratulations message='Seems like you have completed all your computed pending actions! Great Job! Come again after sometime for more tasks..' />
        </div>
      );
    }

    return (
      <>
        <Card
          header={{
            title: `${currentUser?.name || 'Employee'} Pending Actions`,
            subheading: (
              <div>
                <span className='text-blue-700 font-bold'>
                  <span className='capitalize text-black'>{date.mode}: </span>{' '}
                  {moment(
                    date.value,
                    date.mode === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD'
                  ).format(
                    date.mode === 'date' ? 'ddd MMM DD YYYY' : 'MMM, YYYY'
                  )}
                </span>
              </div>
            ),
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
                    <span className='font-bold truncate'>
                      Select {date.mode}
                    </span>
                    <MonthSelector
                      format='YYYY-MM'
                      initialState={date.value}
                      onChange={(m) =>
                        setDate({
                          mode: date.mode,
                          value: m,
                        })
                      }
                    />
                  </div>
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: renderBody(),
          }}
        />
      </>
    );
  };
}
