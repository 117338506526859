import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
// import { renderModal } from '@erp_core/erp-ui-components';
import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { CGConfigRenderer, CGConfigs } from '../config-renderer';
import { renderEditSENamePatternForm } from './forms/edit-se-name-pattern';
import { renderEditSONamePatternForm } from './forms/edit-so-name-pattern';

type Props = {
  useCompanyGroupSetting: UseCompanyGroupSetting;
  useCompanyGroupSettings: UseCompanyGroupSettings;
};

export const createSalesConfigPage = ({
  useCompanyGroupSetting,
  useCompanyGroupSettings,
}: Props) => {
  const Card = renderCardComponent();
  return function () {
    const { data: allData, getAll, loading } = useCompanyGroupSettings();

    const [data, setData] = useState<CompanyGroupSetting>();

    useEffect(() => {
      setData(
        (allData || []).length > 0
          ? allData
            ? allData[0]
            : undefined
          : undefined
      );
    }, [allData]);

    useEffect(() => {
      getAll();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const [modalState, setModalState] = useState({
    //   title: '',
    //   isVisible: false,
    //   onClose: () => setModalState((ms) => ({ ...ms, isVisible: false })),
    //   body: (s: { comGrpSet?: CompanyGroupSetting }) => (
    //     <div className='hidden' />
    //   ),
    // });

    // const closeModalAndRefresh = () => {
    //   setModalState((ms) => ({ ...ms, isVisible: false }));
    //   getAll();
    // };

    // type GenericEditPatternFormProps = {
    //   closeModal: () => void;
    //   useCompanyGroupSetting: UseCompanyGroupSetting;
    // };

    // type Props = {
    //   comGrpSet?: CompanyGroupSetting;
    // };
    // const handleEditPattern = (
    //   genericEditPatternForm: ({
    //     closeModal,
    //     useCompanyGroupSetting,
    //   }: GenericEditPatternFormProps) => (data: Props) => JSX.Element,
    //   title: string
    // ) => {
    //   const Form = genericEditPatternForm({
    //     closeModal: closeModalAndRefresh,
    //     useCompanyGroupSetting,
    //   });
    //   setModalState((ms) => ({
    //     ...ms,
    //     title,
    //     isVisible: true,
    //     body: Form,
    //   }));
    // };

    // const Modal = renderModal();

    const configs: CGConfigs[] = [
      {
        settingName: 'Sales Order Name Pattern',
        settingValue: data?.details?.sales?.salesOrder?.soNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const NewForm = renderEditSONamePatternForm({
            // comGrpSetDetails: data?.details || {},
            comGrpSet: data,
            // id: data?.id || '',
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <NewForm />;
        },
      },
      {
        settingName: 'Sales Enquiry Name Pattern',
        settingValue: data?.details?.sales?.salesEnquiry?.seNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const NewForm = renderEditSENamePatternForm({
            // comGrpSetDetails: data?.details || {},
            comGrpSet: data,
            // id: data?.id || '',
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <NewForm />;
        },
      },
    ];

    return (
      <div className='w-full'>
        <div className='flex flex-wrap'>
          {configs.map((config) => (
            <div key={config.settingName} className='w-1/2'>
              <Card
                header={{ title: config.settingName }}
                body={{
                  type: 'jsx-with-behaviour',
                  body: {
                    behaviour: 'modal',
                    content: (
                      <CGConfigRenderer configs={[config]} loading={loading} />
                    ),
                    modal: {
                      title: config.settingName,
                      content: ({ onClose }) => {
                        return config.editAction({ onClose });
                      },
                    },
                  },
                }}
              />
            </div>
          ))}
        </div>
        {/* <Modal
          title={modalState.title}
          isVisible={modalState.isVisible}
          body={<div />}
          onClose={modalState.onClose}
        >
          <modalState.body comGrpSet={data} />
        </Modal> */}
      </div>
    );
  };
};
