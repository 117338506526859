import {
  Client,
  SalesEnquiry,
  SalesEnquiryDetails,
  SalesEnquiryItem,
  SalesOrder,
} from '@erp_core/erp-types/dist/modules/order';
import { SalesEnquiryFilter } from '@erp_core/erp-types/dist/types/modules/order/sales/enquiry';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import toast from 'react-hot-toast';
import { UseItems } from '../../../hooks/inventory/item/use-items';
import { UseClients } from '../../../hooks/order/sales/client/use-clients';
import { UseSalesEnquiries } from '../../../hooks/order/sales/enquiry/use-enquiries';
import { UseSalesEnquiry } from '../../../hooks/order/sales/enquiry/use-enquiry';
import { UsePaginatedEnquiries } from '../../../hooks/order/sales/enquiry/use-paginated-enquiries';
import { UseSalesOrder } from '../../../hooks/order/sales/order/use-order';
import { ManufacturingVoucherInterface } from '../../../models/interfaces/order/work-order/manufacturing-voucher.ts';
import { renderAddSalesEnquiryForm } from './forms/add-sales-enquiry';
import { renderCreateOrderFrom } from './forms/create-order';
import { renderExtendEnquiryFrom } from './forms/extend-enquiry-form';
import { renderSendSalesEnquiryQuotesForm } from './forms/sent-quotes-form';

import { UseUsers } from '../../../hooks/admin/user-admin/use-users-admin';
import { UserRendererInterface } from '../../common/fragments/user';

type RenderSalesEnquiryProps = {
  hooks: {
    useEnquiry: UseSalesEnquiry;
    useEnquiries: UseSalesEnquiries;
    useItems: UseItems;
    useClients: UseClients;
    useOrder: UseSalesOrder;
    usePaginatedEnquiries: UsePaginatedEnquiries;
    useUsers: UseUsers;
  };
  services: {
    manufacturingVoucherService: ManufacturingVoucherInterface;
    userRendererService: UserRendererInterface;
  };
};

function renderSalesEnquiryDetails({
  hooks: {
    useEnquiries,
    useEnquiry,
    useItems,
    useClients,
    useOrder,
    usePaginatedEnquiries,
    useUsers,
  },
  services: { manufacturingVoucherService, userRendererService },
}: RenderSalesEnquiryProps) {
  const Card = renderCardComponent();
  const Table = renderTableWithMapperComponent<
    SalesEnquiry,
    SalesEnquiryFilter
  >();

  return function SalesEnquiryDetails() {
    const { set: setSalesEnquiry } = useEnquiry();
    // const { getAll, data: enquiriesData } = useEnquiries();

    const { set: SetSalesOrder } = useOrder();

    // const [urgentLength, setUrgentLength] = useState(-1);
    // const [missedLength, setMissedLength] = useState(-1);

    // useEffect(() => {
    //   getAll();
    //   // eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //   const sortedArray = _.orderBy(
    //     enquiriesData,
    //     (e) => moment(e.respondBy).format('YYYYMMDD'),
    //     ['desc']
    //   );
    //   const dateToday = moment(new Date());
    //   const range = 5; // in days

    //   const urgent = sortedArray.filter(
    //     (a) =>
    //       moment(a.respondBy).diff(dateToday, 'days') <= range &&
    //       moment(a.respondBy).diff(dateToday, 'days') >= 0
    //   );
    //   const missed = sortedArray.filter(
    //     (a) =>
    //       moment(a.respondBy).diff(dateToday, 'days') >= -range &&
    //       moment(a.respondBy).diff(dateToday, 'days') < 0
    //   );
    //   // setMissedLength(missed.length);
    //   // setUrgentLength(urgent.length);

    //   const arr = [
    //     ...missed,
    //     ...urgent,
    //     ...sortedArray.filter(
    //       (a) =>
    //         moment(a.respondBy).diff(dateToday, 'days') > range ||
    //         moment(a.respondBy).diff(dateToday, 'days') < -range
    //     ),
    //   ];

    //   // setData(arr);
    // }, [enquiriesData]);

    // const [modalState, setModalState] = useState({
    //   visible: false,
    //   title: 'Add enquiry details',
    //   content: <>Add enquiry details</>,
    // });

    // const [activeDataId, setActiveDataId] = useState<string>('');
    // const [data, setData] = useState<SalesEnquiry[]>();

    // useEffect(() => {
    //   getAll();
    //   // eslint-disable-next-line
    // }, []);

    type SalesEnquiryForm = {
      client: string;
      items: Array<{
        id: string;
        quantity: number;
        grade: string;
        stateOfMatter: string;
        color: string;
        packaging: string;
        schedule: {
          batchQuantity: string;
          batchNeededBy: string;
        }[];
      }>;
      name: string;
      remark: string;
      respondBy: Date;
      expireBy: Date;
      attachments: string;
    };
    const handleAddSalesEnquiry = (formData: SalesEnquiryForm) => {
      try {
        // console.log('Final Form Data', formData);
        const finalData: Partial<SalesEnquiry> = {
          name: formData.name,
          details: {
            extendDateReason: '',
            attachments: [],
            items: formData.items.map((i) => ({ id: i.id, name: '' })),
            remark: formData.remark,
          },
          enquiryItems: formData.items.map((i) => ({
            item: {
              id: i.id,
              name: '',
            },
            quantity: i.quantity,
            details: {
              specifications: {
                grade: i.grade || '',
                color: i.color || '',
                stateOfMatter: i.stateOfMatter,
                packaging: i.packaging,
              },
              schedule: i.schedule,
            },
          })) as SalesEnquiryItem[],
          respondBy: formData.respondBy.toString(),
          expireBy: formData.expireBy.toString(),
          client: { id: formData.client } as Client,
        };
        try {
          setSalesEnquiry(finalData as SalesEnquiry);
          toast('Added successfully');
        } catch (error) {
          toast((error as any).message);
        }
      } catch (err) {
        // console.log('Error');
      }
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Name', rowSpan: 1 },
        { name: 'Respond By', rowSpan: 1 },
        { name: 'Expire By', rowSpan: 1 },
        { name: 'Client', rowSpan: 1 },
        { name: 'Items', rowSpan: 1 },
        { name: 'Remarks', rowSpan: 1 },
        { name: 'Attachments', rowSpan: 1 },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        { name: 'Status', rowSpan: 1 },
      ],
    ];

    const bodyMapper = (item: SalesEnquiry) => {
      const cells: Array<TableCell> = [
        { value: item.name, link: `/sales/enquiries/enquiry/${item.id}` },
        {
          value: <div>{item.respondBy}</div>,
        },
        { value: moment(item.expireBy).format('YYYY-MM-DD') },
        { value: item.client.name },
        // eslint-disable-next-line
        {
          value: (
            <div>
              {item.enquiryItems.map((a, i) => (
                <span key={i}> {a.item.name}, </span>
              ))}
            </div>
          ),
        },
        { value: item.details.remark },
        // eslint-disable-next-line
        {
          value: (
            <div>
              {item.details.attachments.map((a, i) => (
                <span key={i}>{a.name}, </span>
              ))}{' '}
            </div>
          ),
        },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={item.createdBy?.id}
              name={item.createdBy?.name}
              extraInfo={moment.utc(item.createdAt).fromNow()}
            />
          ),
        },
        { value: <Status date={item.expireBy} status={item.status} /> },
      ];
      return {
        cells,
        rowData: {
          item,
        },
      };
    };

    const filter: Filter<SalesEnquiryFilter> = {
      sortFields: [
        {
          key: 'Name',
          value: 'name',
          defaultOrder: 'asc',
        },
        // {
        //     key: 'Client',
        //     value: 'client', // TODO: sort filter not working
        //     defaultOrder: 'asc',
        // },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: SalesEnquiryFilter) => {
        const filterData: SalesEnquiryFilter = {};

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }

        return filterData as SalesEnquiryFilter;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const cardHeader: CardHeader = {
      title: 'Sales Enquiries',
      actions: [
        {
          type: 'button',
          button: {
            behaviour: 'modal',
            name: 'add',
            modal: {
              title: 'Add Sales Enquiry',
              content: ({ onClose }) => {
                const Form = renderAddSalesEnquiryForm({
                  useItems,
                  useClients,
                });
                return (
                  <Form
                    data={{}}
                    onSave={async (form) => {
                      await handleAddSalesEnquiry(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table
            header={tableHeader}
            bodyMapper={bodyMapper}
            actions={[
              {
                name: 'Send Quote',
                show: ({ item }) => {
                  const expired =
                    moment().format('YYYY-MM-DD') >
                    moment(item?.expireBy).format('YYYY-MM-DD');
                  if (expired || item?.status === 'order-created') {
                    return false;
                  }
                  return true;
                },
                behaviour: 'modal',
                modal: {
                  title: 'Send Quote',
                  content: ({ data: { item }, onClose }) => {
                    const SendQuotesForm = renderSendSalesEnquiryQuotesForm();
                    return (
                      <SendQuotesForm
                        manufacturingVoucherService={
                          manufacturingVoucherService
                        }
                        enquiryData={item}
                        onSave={(form) => {
                          const finalData = {
                            id: item.id,
                            status: 'quote-sent',
                            quotes: {
                              items: item.enquiryItems
                                .map((i) => ({
                                  id: i.id,
                                  name: i.item.name,
                                  rate: form[i.id],
                                }))
                                .filter((i) => i.rate), // Filtering out those items whom rate not provided by user
                              client: item.client,
                              credit: form.credit,
                              availableBy: form.availableBy,
                            },
                          };
                          setSalesEnquiry(
                            (finalData as unknown) as SalesEnquiry
                          );

                          onClose();
                        }}
                      />
                    );
                  },
                },
              },
              {
                name: 'Extend',
                show: ({ item }) => {
                  const expired =
                    moment().format('YYYY-MM-DD') >
                    moment(item?.expireBy).format('YYYY-MM-DD');
                  if (expired || item?.status === 'order-created') {
                    return false;
                  }
                  return true;
                },
                behaviour: 'modal',
                modal: {
                  title: 'Extend',
                  content: ({ data: { item }, onClose }) => {
                    const ExtendEnquiryForm = renderExtendEnquiryFrom();
                    return (
                      <div>
                        <ExtendEnquiryForm
                          onSave={(form) => {
                            console.log(form);
                            const final: Partial<SalesEnquiry> = {
                              id: item.id,
                              expireBy: form.extendedDate,
                              userReason: form.reason,
                              details: {
                                extendDateReason: form.reason,
                              } as SalesEnquiryDetails,
                            };
                            setSalesEnquiry(final as SalesEnquiry);
                            onClose();
                            toast('Enquiry date extended');
                          }}
                        />
                      </div>
                    );
                  },
                },
              },
              {
                name: 'Create Order',
                show: ({ item }) => {
                  const expired =
                    moment().format('YYYY-MM-DD') >
                    moment(item?.expireBy).format('YYYY-MM-DD');
                  if (expired || item?.status === 'order-created') {
                    return false;
                  }
                  return true;
                },
                behaviour: 'modal',
                modal: {
                  title: 'Create Order',
                  content: ({ data: { item }, onClose }) => {
                    const Form = renderCreateOrderFrom();
                    return (
                      <Form
                        onSave={(form) => {
                          const finalData = {
                            name: form.name,
                            enquiry: item,
                            client: item.client,
                            orderItems: item.enquiryItems.map((ei) => ({
                              name: '',
                              item: ei.item,
                              deliverBy: form.deliverBy,
                              details: ei.details,
                              quantity: ei.quantity,
                            })),
                            details: { remarks: form.remark },
                          };
                          // console.log('finalData', finalData);
                          SetSalesOrder(finalData as SalesOrder);
                          onClose();
                          toast('Order created');
                        }}
                      />
                    );
                  },
                },
              },
              {
                name: 'Close enquiry',
                show: ({ item }) => {
                  const expired =
                    moment().format('YYYY-MM-DD') >
                    moment(item?.expireBy).format('YYYY-MM-DD');
                  if (expired || item?.status === 'order-created') {
                    return false;
                  }
                  return true;
                },
                behaviour: 'click',
                onClick: async () => {
                  console.log('clicked close enquiry');
                },
              },
            ]}
            useResources={useEnquiries}
            filter={filter}
            pagination={{
              enabled: true,
              usePaginatedResources: usePaginatedEnquiries,
            }}
          />
        </>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
        {/* <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal> */}
      </div>
    );
  };
}

function Status({ date, status }: { date: string; status: string }) {
  const now = moment().format('YYYY-MM-DD');
  if (now === moment(date).format('YYYY-MM-DD'))
    return (
      <span>
        {status} <span className='bg-yellow-300'>Expiring today</span>
      </span>
    );
  if (now > moment(date).format('YYYY-MM-DD'))
    return <span className='bg-red-300'>Expired</span>;
  return <span>{status}</span>;
}

export default renderSalesEnquiryDetails;
