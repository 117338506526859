import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  AdvancedLoadingButton,
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseEmployeeProfiles } from '../../../../../hooks/hrd/employee/profile/use-employee-profiles';

type CreateItemAuthoriserCardType = {
  useEmployees: UseEmployeeProfiles;
};

export function renderItemAuthorisers({
  useEmployees,
}: CreateItemAuthoriserCardType): ({
  id,
  item,
  setItem,
  loading,
}: {
  id: string;
  item: ItemRes;
  setItem: (s: ItemRes) => Promise<ItemRes>;
  loading: boolean;
}) => JSX.Element {
  return function Authoriser({
    id,
    item,
    setItem,
    loading,
  }: {
    id: string;
    item: ItemRes;
    setItem: (s: ItemRes) => Promise<ItemRes>;
    loading: boolean;
  }): JSX.Element {
    // const [modalState, setModalState] = useState({
    //   visible: false,
    //   title: 'Edit Authoriser',
    //   content: <>Edit Authoriser form</>,
    // });

    function UpdateAuthorization(prop: {
      onClose: () => void;
      name: string;
      description: string;
      property: string;
    }) {
      const Form = renderFormV2<{ authorizer: any }>({
        formName: `Edit ${prop.name}`,
        initialFormState: {
          authorizer: item?.details?.authorizations?.[prop.property] || {
            id: '',
            name: '',
          },
        },
        mapTToU: (d) => {
          return d as any;
        },
        fieldsData: [
          {
            property: 'authorizer',
            label: 'Authorizer',
            type: 'searchable-select',
            searchOptions: {
              useSearch: useEmployees,
              filter: { crossGroup: true },
              onSearchValueSelect: () => {},
            },
          },
        ],
        onSubmit: async (p) => {
          const authorizations = item.details?.authorizations || {};
          authorizations[prop.property] = p.authorizer;
          await setItem({
            id: id,
            details: {
              authorizations,
            },
          } as any);
          // setModalState({ ...modalState, visible: false });
          prop.onClose();
        },
      });
      return <Form />;
      // setModalState({
      //   ...modalState,
      //   title: `Edit ${prop.name}`,
      //   content: (
      //     <div>
      //     </div>
      //   ),
      //   visible: true,
      // });
    }

    const AuthoriserCard = renderCardComponent();
    const AuthoriserCardHeader: CardHeader = {
      title: 'Authoriser Details',
    };
    const authorizations = [
      {
        name: 'Item Props/Specs Editor',
        description: 'This person will edit item props/specs',
        property: 'itemSpecsEditor',
      },
      {
        name: 'Item Approver',
        description: 'This person will approve items',
        property: 'itemApprover',
      },
      {
        name: 'COA Creator',
        description: 'This person will create COA',
        property: 'coaCreator',
      },
      {
        name: 'COA Approver',
        description: 'This person will approve COA',
        property: 'coaApprover',
      },
      {
        name: 'Vendor Approver',
        description: 'This person will approve vendor',
        property: 'vendorApprover',
      },
    ];

    const AuthoriserCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full flex flex-wrap'>
            {authorizations.map((x, idx) => (
              <div key={idx} className='w-1/3 p-2 bg-slate-50'>
                <div className='border border-gray-200 rounded-lg m-1 h-full'>
                  <div className='text-center text-xl'>{x.name}</div>
                  <div className='text-center italic text-gray-800'>
                    {x.description}
                  </div>
                  <div
                    className='text-center text-lg font-semibold'
                    // onClick={() => {
                    //   updateAuthorization(x);
                    // }}
                  >
                    {loading ? (
                      <span>loading</span>
                    ) : (
                      <>
                        {item?.details?.authorizations?.[x.property]?.name ||
                          'not-set'}
                        <AdvancedLoadingButton
                          text={'Edit'}
                          behaviour='modal'
                          modal={{
                            title: `Edit ${x.name}`,
                            size: 'large',
                            content: ({ onClose }) => {
                              return (
                                <UpdateAuthorization
                                  name={x.name}
                                  property={x.property}
                                  description={x.description}
                                  onClose={onClose}
                                />
                              );
                            },
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    };

    return (
      <>
        <AuthoriserCard
          header={AuthoriserCardHeader}
          body={AuthoriserCardBody}
        />
        {/* <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal> */}
      </>
    );
  };
}
