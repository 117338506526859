/* eslint-disable react-hooks/exhaustive-deps */
import {
  LeadCustomerDetails,
  LeadCustomerType,
} from '@erp_core/erp-types/dist/modules/order';
import {
  Card,
  ColumnizedContent,
  FormDataType,
  FormField,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { LocationContext } from 'contexts/location';
import { useContext } from 'react';
import { UsePublicTransfer } from '../../../hooks/file-transfer/use-public-transfer';
import { createLeadCustomerCard } from './card';

type CreateLeadCustomerCardType = {
  leadCustomer: LeadCustomerType;
  loading: boolean;
  setLeadCustomer: (s: LeadCustomerType) => Promise<LeadCustomerType>;
  usePublicTransfer: UsePublicTransfer;
};

export function renderLeadCustomerProfileInfo({
  leadCustomer,
  setLeadCustomer,
  loading,
  usePublicTransfer,
}: CreateLeadCustomerCardType): () => JSX.Element {
  return function ClientProfile(): JSX.Element {
    const { companyGroup } = useContext(LocationContext);

    const itemProfileInitialData: Card = {
      header: {
        title: 'Client Profile',
        actions: [
          {
            type: 'button',
            button: {
              name: 'Edit',
              behaviour: 'modal',
              modal: {
                title: 'Edit',
                content: ({ onClose }) => {
                  let Form = renderFormV2<any>({
                    style: 'md:w-1/2',
                    fieldsData: getFields(),
                    initialFormState: {
                      logo: leadCustomer.details?.partyProfile?.logo || '',
                      clientType:
                        leadCustomer.details?.partyProfile?.clientType || '',
                      currency:
                        leadCustomer.details?.partyProfile?.currency || '',
                      email: leadCustomer?.details?.email || '',
                      website: leadCustomer.details?.website || '',
                    },
                    onSubmit: async (data) => {
                      await setLeadCustomer({
                        id: leadCustomer.id,
                        details: {
                          email: data?.email || leadCustomer?.details?.email,
                          website:
                            data?.website || leadCustomer?.details?.website,
                          partyProfile: {
                            logo:
                              data.logo ||
                              leadCustomer.details.partyProfile?.logo,
                            currency:
                              data.currency ||
                              leadCustomer.details.partyProfile?.currency,
                            clientType:
                              data.clientType ||
                              leadCustomer.details.partyProfile?.clientType,
                          },
                        } as Partial<LeadCustomerDetails>,
                      } as LeadCustomerType);
                      onClose();
                    },
                    mapTToU: (b: FormDataType<any>) => b,
                    upload: {
                      useFileTransfer: usePublicTransfer,
                      config: [
                        {
                          name: 'logo',
                          path: `${companyGroup.id}/${leadCustomer.id}`,
                          type: 'public',
                        },
                      ],
                    },
                  });

                  return <Form />;
                },
              },
            },
          },
        ],
      },
      body: {
        type: 'columns',
        body: [],
      },
    };
    const initialItemData = {
      profileData: itemProfileInitialData,
    };

    const ProfileCard = createLeadCustomerCard({
      initialData: initialItemData.profileData,
      mapperFunction: mapClientProfileBody,
      leadCustomer,
      loading,
    });
    return (
      <div className='w-full space-y-3'>
        <ProfileCard id={leadCustomer?.id} />
      </div>
    );
  };
}

const mapClientProfileBody = (data: LeadCustomerType): ColumnizedContent => [
  [
    {
      key: 'Name:',
      value: data?.name || '-',
      type: 'text',
    },
    {
      key: 'Client-Type:',
      value: data?.details?.partyProfile?.clientType || '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Currency:',
      value: data?.details?.partyProfile?.currency || '-',
      type: 'text',
    },
    {
      key: 'Email:',
      value: data?.details?.email || '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Website:',
      value: data?.details?.website || '-',
      type: 'text',
    },
  ],
];

function getFields(): FormField<any>[] {
  return [
    { property: 'logo', type: 'file' },
    {
      property: 'currency',
      type: 'select',
      options: [
        { text: 'Select', value: '' },
        { text: 'INR', value: 'INR' },
        { text: 'USD', value: 'USD' },
      ],
    },
    {
      property: 'clientType',
      type: 'select',
      options: [
        { text: 'Select', value: '' },
        { text: 'manufacturer', value: 'manufacturer' },
        { text: 'trader', value: 'trader' },
      ],
    },
    {
      property: 'email',
      type: 'input',
    },
    {
      property: 'website',
      type: 'input',
    },
  ];
}
