/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { SalesOrderItem } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  // Modal,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
// import { Popover } from '@headlessui/react';
// import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
// import toast from 'react-hot-toast';
import { UseBatchItemAllocate } from '../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseBatchItemGodowns } from '../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UsePhysicalStock } from '../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualMFStock } from '../../../hooks/inventory/item/stock/use-virtual-manufacturing-stock';
import { UseSalesOrders } from '../../../hooks/order/sales/order/use-orders';
import { UseManufacturingVouchers } from '../../../hooks/order/work-order/mf-voucher/use-manufacturing-vouchers';
import { UseVirtuallyReserveMfVoucher } from '../../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
// import { renderOrderItemActions } from './components/order-items';

type Props = {
  useSalesOrders: UseSalesOrders;
  usePhysicalStock: UsePhysicalStock;
  useBatchItemGodowns: UseBatchItemGodowns;
  useBatchGodownItemAllocate: UseBatchItemAllocate;
  useVirtualMfStock: UseVirtualMFStock;
  useManufacturingVouchers: UseManufacturingVouchers;
  useVirtuallyReserveMfVoucher: UseVirtuallyReserveMfVoucher;
};

function renderSalesItemOrderDetails({
  useSalesOrders,
  usePhysicalStock,
  useBatchGodownItemAllocate,
  useBatchItemGodowns,
  useVirtualMfStock,
  useManufacturingVouchers,
  useVirtuallyReserveMfVoucher,
}: Props) {
  return function SalesItemOrderDetails() {
    // const [modalState, setModalState] = useState({
    //   visible: false,
    //   title: 'Response',
    //   content: <>Response</>,
    // });

    const [actionsState, setActionsState] = useState({
      visible: false,
      xPosition: 200,
      yPosition: 200,
      menu: ['Allocate', 'Raise Manufacturing Voucher', 'Mark Done'],
    });

    const { getAll, data: salesOrders } = useSalesOrders();
    // eslint-disable-next-line
    const [activeDataId, setActiveDataId] = useState<string>('');
    const [data, setData] = useState<SalesOrderItem[]>();

    useEffect(() => {
      getAll();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (salesOrders) {
        setData(salesOrders.flatMap((u) => u.orderItems));
      }
    }, [salesOrders]);

    const getActionMenu = (id: string) => {
      // eslint-disable-next-line
      const activeFulfillment = data?.find((f) => f.id === id);

      return ['Allocate', 'Raise Manufacturing Voucher', 'Mark Done'];
    };

    // eslint-disable-next-line
    const handleActionClick = (e: any, id: string) => {
      setActionsState({
        ...actionsState,
        visible: true,
        xPosition: e.pageX,
        yPosition: e.pageY,
        menu: getActionMenu(id),
      });
      setActiveDataId(id);
    };

    // const handleActionItemClick = async (action: string) => {
    //     const orderItem  = data?.find((f)=>f.id===id)

    //     if(action==='Allocate'){
    //         setActionsState({
    //             ...actionsState,
    //             visible: false,
    //         })
    //         setModalState({
    //             visible: true,
    //             title: 'Allocate',
    //             content: <OrderAllocationForm
    //                 onSave={() => {}}
    //                 onCancel={() => {}}
    //                 useBatchGodownItemAllocate={useBatchItemAllocate}
    //                 useBatchItemGodowns={useBatchItemGodowns}
    //                 enquiryItemData={{
    //                     id:
    //                 }}

    //             />
    //         })
    //     }

    //     if(action==='Raise Manufacturing Vouture'){
    //         setActionsState({
    //             ...actionsState,
    //             visible: false,
    //         })
    //         const Form = renderManufacturingSalesItemForm()
    //         setModalState({
    //             visible: true,
    //             title: 'Raise Manufacturing Vouture',
    //             content: <Form onSave={()=>{
    //                 toast('Saved Sucessfully')
    //                 setModalState((ms)=>({...ms, visible: false}))
    //             }}/>
    //         })
    //     }

    //     if(action==='Mark Done'){
    //         const activeFulfilment  = data?.find((f)=>f.id===activeDataId)
    //         setActionsState((as)=>({...as, visible: false}))
    //         toast('Marked Done.')

    //     }
    // }

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Item', rowSpan: 2 },
        { name: 'Deliver By', rowSpan: 2 },
        { name: 'Quantity', rowSpan: 2 },
        { name: 'Specs', rowSpan: 2 },
        { name: 'remarks', rowSpan: 2 },
        { name: 'Attachments', rowSpan: 2 },
        { name: 'In stock', rowSpan: 2 },
        { name: 'Status', rowSpan: 2 },
        { name: 'Actions', rowSpan: 2 },
      ],
    ];

    // const handleSaveAllocation = () => {
    //   setModalState((ms) => ({ ...ms, visible: false }));
    //   toast('Allocated Successfully');
    // };

    // const showModal = ({
    //   title,
    //   content,
    // }: {
    //   title: string;
    //   content: JSX.Element;
    // }) => {
    //   setModalState({
    //     visible: true,
    //     title,
    //     content,
    //   });
    // };

    // const ItemAction = renderOrderItemActions({
    //   useCurrentUser,
    //   usePhysicalStock,
    //   showModal,
    //   useVirtualMfStock,
    //   setModalState,
    //   handleSaveAllocation,
    //   useBatchItemGodowns,
    //   useBatchGodownItemAllocate,
    //   useManufacturingVouchers,
    //   useVirtuallyReserveMfVoucher,
    // });

    const tableData =
      data?.map((item) => ({
        cells: [
          { value: item.item.name },
          { value: item.deliverBy },
          { value: item.quantity },
          { value: item.details.specifications?.grade || '' },
          { value: item.order.details.remarks },
          { value: 'email' },
          { value: '' },
          { value: item.order.status },
          // {
          //   value: (
          //     <Popover className='relative'>
          //       {({ open }) => (
          //         <>
          //           <Popover.Button>
          //             <PencilSquareIcon className='w-6 h-6 text-blue-600 cursor-pointer' />
          //           </Popover.Button>
          //           <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
          //             <ItemAction itemOrder={item} />
          //           </Popover.Panel>
          //         </>
          //       )}
          //     </Popover>
          //   ),
          // },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Sales Item Orders',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableBody} />,
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
        {/* <Modal
          showModal={modalState.visible}
          setShowModal={() =>
            setModalState({ ...modalState, visible: !modalState.visible })
          }
          title={modalState.title}
          onOk={() => {}}
          style={{ width: '800px' }}
        >
          {modalState.content}
        </Modal> */}
      </div>
    );
  };
}

export default renderSalesItemOrderDetails;
