import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { CGConfigRenderer, CGConfigs } from '../config-renderer';
import { renderEditGRNNamePatternForm } from './forms/edit-grn-name-pattern';
import { renderEditPEDefaultValidityForm } from './forms/edit-pe-default-validity-form';
import { renderEditPENamePatternForm } from './forms/edit-pe-name-pattern';
import { renderEditPONamePatternForm } from './forms/edit-po-name-pattern';
import { renderEditPRNamePatternForm } from './forms/edit-pr-name-pattern';
import { renderEditLandingCostFactorsForm } from './forms/landing-cost-factors';
import { renderRMGradeCalculatorForm } from './forms/rm-grade-calculator';
import { renderRMQualityMetric } from './forms/rm-quality-metric';

type Props = {
  useCompanyGroupSetting: UseCompanyGroupSetting;
  useCompanyGroupSettings: UseCompanyGroupSettings;
};

export type GenericEditPatternFormProps = {
  closeModal: () => void;
  useCompanyGroupSetting: UseCompanyGroupSetting;
};

export const createPurchaseConfigPage = ({
  useCompanyGroupSetting,
  useCompanyGroupSettings,
}: Props) => {
  const Card = renderCardComponent();
  // const Modal = renderModal();
  return function () {
    const { data: allData, getAll, loading } = useCompanyGroupSettings();

    const [data, setData] = useState<CompanyGroupSetting>();

    useEffect(() => {
      setData(
        (allData || []).length > 0
          ? allData
            ? allData[0]
            : undefined
          : undefined
      );
    }, [allData]);

    useEffect(() => {
      getAll();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const [modalState, setModalState] = useState({
    //   title: '',
    //   isVisible: false,
    //   onClose: () => setModalState((ms) => ({ ...ms, isVisible: false })),
    //   body: (p: { comGrpSet?: CompanyGroupSetting }) => (
    //     <div className='hidden' />
    //   ),
    // });

    const handleEditPEDefaultValidity = ({
      onClose,
    }: {
      onClose: () => void;
    }) => {
      const EditPEDefaultValidityForm = renderEditPEDefaultValidityForm({
        id: data?.id || '',
        comGrpSetDetails: data?.details || {},
        closeModal: onClose,
        useCompanyGroupSetting,
      });
      return <EditPEDefaultValidityForm />;
    };

    const configs: CGConfigs[] = [
      {
        settingName: 'Default Purchase Enquiry Validity',
        settingValue: data?.details?.purchase?.purchaseEnquiry?.defaultValidity?.toString(),
        settingValueSuffix: ' days after sending enquiry',
        editAction: handleEditPEDefaultValidity,
      },
      {
        settingName: 'Purchase Requisition Name Pattern',
        settingValue: data?.details?.purchase?.purchaseRequisition?.prNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderEditPRNamePatternForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={data} />;
        },
      },
      {
        settingName: 'Purchase Order Name Pattern',
        settingValue: data?.details?.purchase?.purchaseOrder?.poNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderEditPONamePatternForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={data} />;
        },
      },
      {
        settingName: 'Purchase Enquiry Name Pattern',
        settingValue: data?.details?.purchase?.purchaseEnquiry?.peNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderEditPENamePatternForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={data} />;
        },
      },
      {
        settingName: 'GRN Name Pattern',
        settingValue: data?.details?.purchase?.grn?.grnNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderEditGRNNamePatternForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={data} />;
        },
      },
      {
        settingName: 'Raw Material Grades Calculator',
        settingValue: (data?.details?.purchase?.rmGrades || [])
          .map((t) => t.gradeName)
          .toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderRMGradeCalculatorForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={data} />;
        },
      },
      {
        settingName: 'Raw Material Quality Metric',
        settingValue: (data?.details?.purchase?.rmQualityMetric || [])
          .map((t) => t.name)
          .toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderRMQualityMetric({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={data} />;
        },
      },
      {
        settingName: 'Landing cost Calculator Factors pattern.',
        settingValue: data?.details?.purchase?.landingCostFactors || '',
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderEditLandingCostFactorsForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={data} />;
        },
      },
    ];

    return (
      <div className='w-full'>
        <div className='flex flex-wrap'>
          {configs.map((config) => (
            <div className='w-1/2' key={config.settingName}>
              <Card
                header={{ title: config.settingName }}
                body={{
                  type: 'jsx-with-behaviour',
                  body: {
                    behaviour: 'modal',
                    content: (
                      <CGConfigRenderer configs={[config]} loading={loading} />
                    ),
                    modal: {
                      title: config.settingName,
                      content: ({ onClose }) => {
                        return config.editAction({ onClose });
                      },
                    },
                  },
                }}
              />
            </div>
          ))}
        </div>
        {/* <Modal
          title={modalState.title}
          isVisible={modalState.isVisible}
          body={<div />}
          onClose={modalState.onClose}
        >
          <modalState.body comGrpSet={data} />
        </Modal> */}
      </div>
    );
  };
};
