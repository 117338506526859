import { ReactNode, useMemo } from 'react';
import { LocationContext } from '../../contexts/location';
import { UseCurrentCompany } from '../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCurrentLocation } from '../../hooks/admin/location-admin/use-current-location';

export type LocationProviderProps = {
  children: ReactNode;
  useCurrentLocation: UseCurrentLocation;
  useCurrentCompany: UseCurrentCompany;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
};

export function LocationProvider({
  children,
  useCurrentLocation,
  useCurrentCompany,
  useCurrentCompanyGroup,
}: LocationProviderProps): JSX.Element {
  const { data: currentLocation, setCurrentLocation } = useCurrentLocation();
  const { data: currentCompany, setCurrentCompany } = useCurrentCompany();
  const {
    currentCompanyGroup,
    setCompanyGroup: setCurrentCompanyGroup,
  } = useCurrentCompanyGroup();

  const value = useMemo(
    () => ({
      company: currentCompany,
      location: currentLocation,
      companyGroup: currentCompanyGroup,
      setCompany: setCurrentCompany,
      setLocation: setCurrentLocation,
      setCompanyGroup: setCurrentCompanyGroup,
    }),
    // eslint-disable-next-line
    [currentLocation, currentCompanyGroup, currentCompany]
  );
  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
}
