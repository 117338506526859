import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import { renderFileViewerUploader } from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import { LocationContext } from '../../../../../contexts/location';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UseMisconduct } from '../../../../../hooks/hrd/disciplinary/misconduct/use-misconduct';

export function ShowMisconductDoc({
  r,
  useFileTransfer,
  useMisconduct,
}: {
  r: MisconductType;
  useFileTransfer: UseFileTransfer;
  useMisconduct: UseMisconduct;
}): JSX.Element {
  const FileViewerUploader = renderFileViewerUploader();

  const answerTypeJSx = DocTypes({
    r,
    FileViewerUploader,
    useFileTransfer,
    useMisconduct,
  });
  return <div>{answerTypeJSx}</div>;
}

export function DocTypes({
  r,
  FileViewerUploader,
  useFileTransfer,
  useMisconduct,
}: {
  r: MisconductType;
  FileViewerUploader;
  useFileTransfer: UseFileTransfer;
  useMisconduct: UseMisconduct;
}): JSX.Element {
  const { company, companyGroup } = useContext(LocationContext);
  const { syncSet: setMisconduct } = useMisconduct();

  function showMisconductDoc(r: MisconductType) {
    if (r.status === 'pending' || r.status === 'approved') {
      return (
        <div>
          {!r.details.document ? 'Pending Misconduct' : 'Misconduct'}{' '}
          <FileViewerUploader
            url={r.details.document || ''}
            useFileTransfer={useFileTransfer}
            path={`${companyGroup.id}/${company.id}/misconduct/${r.id}/proofs.pdf`.replaceAll(
              ' ',
              '-'
            )}
            type='private'
            onUpload={async (path) => {
              let misconductdoc = r.details.document || '';
              if (misconductdoc) {
                misconductdoc = path.url;
                await setMisconduct({
                  id: r.id,
                  details: {
                    document: misconductdoc,
                  },
                } as MisconductType);
              }
            }}
          />
        </div>
      );
    }

    return <div></div>;
  }

  function showEmployeeResp(r: MisconductType) {
    if (r.status === 'approved') {
      return (
        <div>
          {!r.details.employeedocument
            ? 'Pending Employee Response'
            : 'Employee Response'}{' '}
          <FileViewerUploader
            url={r.details.document || ''}
            useFileTransfer={useFileTransfer}
            path={`${companyGroup.id}/${company.id}/misconduct/${r.id}/response.pdf`.replaceAll(
              ' ',
              '-'
            )}
            type='private'
            onUpload={async (path) => {
              const misconductdoc = r.details || '';
              if (misconductdoc) {
                misconductdoc.employeedocument = path.url;
                await setMisconduct({
                  id: r.id,
                  details: {
                    employeedocument: misconductdoc,
                  },
                } as MisconductType);
              }
            }}
          />
        </div>
      );
    }

    return <div></div>;
  }

  if (r.details.issueShowCause) {
    return (
      <div>
        {!r.details.issueShowCause?.document
          ? 'Pending Major Showcause'
          : 'Major Showcause'}
        <FileViewerUploader
          url={r.details.issueShowCause.document || ''}
          useFileTransfer={useFileTransfer}
          path={`${companyGroup.id}/${company.id}/misconduct/${r.id}/showcause.pdf`.replaceAll(
            ' ',
            '-'
          )}
          type='private'
          onUpload={async (path) => {
            const misconductdoc = r.details?.issueShowCause;
            if (misconductdoc) {
              misconductdoc.document = path.url;
              await setMisconduct({
                id: r.id,
                details: {
                  issueShowCause: misconductdoc,
                },
              } as MisconductType);
            }
          }}
        />
        {showMisconductDoc(r)}
        {showEmployeeResp(r)}
      </div>
    );
  } else if (r.details.issueMemo) {
    return (
      <div>
        {!r.details.issueMemo.document ? 'Pending Memo' : 'Memo'}
        <FileViewerUploader
          url={r.details.issueMemo?.document || ''}
          useFileTransfer={useFileTransfer}
          path={`${companyGroup.id}/${company.id}/misconduct/${r.id}/memo.pdf`.replaceAll(
            ' ',
            '-'
          )}
          type='private'
          onUpload={async (path) => {
            const misconductdoc = r.details?.issueMemo;
            if (misconductdoc) {
              misconductdoc.document = path.url;
              await setMisconduct({
                id: r.id,
                details: {
                  issueMemo: misconductdoc,
                },
              } as MisconductType);
            }
          }}
        />
        {showMisconductDoc(r)}
        {showEmployeeResp(r)}
      </div>
    );
  } else if (r.details.issueCriticalShowCause) {
    return (
      <div>
        {!r.details.issueCriticalShowCause.document
          ? 'Pending Critical Showcause'
          : 'Critical Showcause'}
        <FileViewerUploader
          url={r.details.issueCriticalShowCause?.document || ''}
          useFileTransfer={useFileTransfer}
          path={`${companyGroup.id}/${company.id}/misconduct/${r.id}/criticalshowcause.pdf`.replaceAll(
            ' ',
            '-'
          )}
          type='private'
          onUpload={async (path) => {
            const misconductdoc = r.details?.issueCriticalShowCause;
            if (misconductdoc) {
              misconductdoc.document = path.url;
              await setMisconduct({
                id: r.id,
                details: {
                  issueCriticalShowCause: misconductdoc,
                },
              } as MisconductType);
            }
          }}
        />
        {showMisconductDoc(r)}
        {showEmployeeResp(r)}
      </div>
    );
  } else if (r.details.issueCriticalChargeSheet) {
    return (
      <div>
        {!r.details.issueCriticalChargeSheet.document
          ? 'Pending Charge Sheet'
          : 'Charge Sheet'}
        <FileViewerUploader
          url={r.details.issueCriticalChargeSheet?.document || ''}
          useFileTransfer={useFileTransfer}
          path={`${companyGroup.id}/${company.id}/misconduct/${r.id}/criticalchargesheet.pdf`.replaceAll(
            ' ',
            '-'
          )}
          type='private'
          onUpload={async (path) => {
            const misconductdoc = r.details?.issueCriticalChargeSheet;
            if (misconductdoc) {
              misconductdoc.document = path.url;
              await setMisconduct({
                id: r.id,
                details: {
                  issueCriticalChargeSheet: misconductdoc,
                },
              } as MisconductType);
            }
          }}
        />
        {showMisconductDoc(r)}
        {showEmployeeResp(r)}
      </div>
    );
  } else if (r.details.document || r.details.employeedocument) {
    return (
      <div>
        {showMisconductDoc(r)}
        {showEmployeeResp(r)}
      </div>
    );
  } else if (r.details.employeedocument) {
    return showEmployeeResp(r);
  }
  return <div></div>;
}
