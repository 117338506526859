import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { UseCompanyGroupSettings } from '../../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseAppearances } from '../../../../hooks/admin/constants/appearance/use-appearances';
import { UseColors } from '../../../../hooks/admin/constants/color/use-colors';
import { UseGsts } from '../../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../../hooks/admin/constants/metrics/use-metrics';
import { UseOdours } from '../../../../hooks/admin/constants/odour/use-odours';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseRoles } from '../../../../hooks/admin/role-admin/use-roles-admin';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseGrade } from '../../../../hooks/inventory/grade/use-grade';
import { UseRevision } from '../../../../hooks/inventory/revision/use-revision';
import { UseRevisions } from '../../../../hooks/inventory/revision/use-revisions';
import { BomFlowInterface } from '../../../../models/interfaces/inventory/bom-flow';
import { GradeInterface } from '../../../../models/interfaces/inventory/grade';
import { renderProperties } from '../../properties/properties';
import { renderBomChartComponent } from './bom-chart';

export function renderGrade({
  useGrade,
  bomFlowService,
  useCurrentUserRoles,
  useGsts,
  useMetrics,
  useRevisions,
  useCompanyGroupSettings,
  gradeService,
  useRevision,
  useFileTransfer,
  useRoles,
  useUsers,
  useAppearances,
  useColors,
  useOdours,
  useEmployees,
}: {
  useGrade: UseGrade;
  bomFlowService: BomFlowInterface;
  useMetrics: UseMetrics;
  useGsts: UseGsts;
  useAppearances: UseAppearances;
  useColors: UseColors;
  useOdours: UseOdours;
  useCurrentUserRoles: UseCurrentUserRoles;
  useRevisions: UseRevisions;
  useCompanyGroupSettings: UseCompanyGroupSettings;
  gradeService: GradeInterface;
  useRevision: UseRevision;
  useFileTransfer: UseFileTransfer;
  useRoles: UseRoles;
  useUsers: UseUsers;
  useEmployees: UseEmployeeProfiles;
}): () => JSX.Element {
  const Card = renderCardComponent();
  const BomChart = renderBomChartComponent({
    bomFlowService,
  });
  return function Grade(): JSX.Element {
    const { id } = useParams();
    const { syncSet: setRevision } = useRevision();
    const { data: grade, get: getGrade, syncSet: setGrade } = useGrade();

    useEffect(() => {
      if (id) {
        getGrade(id);
      }
      // eslint-disable-next-line
    }, [id]);

    const Tabs = renderTabsV2Component();

    const Properties = renderProperties<Grade>({
      useMetrics,
      useGsts,
      useAppearances,
      useColors,
      useOdours,
      useCurrentUserRoles,
      useRevisions,
      useFileTransfer,
      useRoles,
      useUsers,
      useEmployees,
    });

    const tabs = [
      {
        name: 'properties',
        Fn: () => (
          <Properties
            renderOptions={{
              renderAuth: true,
              renderAdd: false,
              renderEditValue: true,
            }}
            useCompanyGroupSettings={useCompanyGroupSettings}
            entityService={gradeService}
            entity={grade}
            setEntity={setGrade}
            type='grade'
            setRevision={setRevision}
          />
        ),
      },
      {
        name: 'bom-charts',
        Fn: () => <BomChart gradeId={grade?.id || ''} />,
      },
    ];

    return (
      <div>
        <Card
          header={{
            title: (
              <>
                <span>{grade.name || id}</span>{' '}
                <span className='italic font-thin'>
                  version {grade.version || '0.0.0'}
                </span>
              </>
            ),
            subheading: grade?.item?.name,
          }}
          body={{
            type: 'jsx-component',
            body: <Tabs data={tabs} manipulateLocation={true} />,
          }}
        />
      </div>
    );
  };
}
