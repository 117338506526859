import { MachineLearning } from '@erp_core/erp-icons/icons/web/machine-learning';
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import {
  RotationalShiftDayType,
  SwitchShiftScheduleType,
} from '@erp_core/erp-types/dist/modules/planning';
import {
  CardBody,
  CardHeader,
  LoadingButton,
  MonthSelector,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
  useConfirm,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import { PencilIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { renderAuthWrapper } from '../../../../components/auth';
import { UserContext } from '../../../../contexts/user';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseRotationalShiftDay } from '../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-day';
import { UseSwitchShift } from '../../../../hooks/hrd/switch-shift-schedule/use-switch-shift';
import { UseSwitchShifts } from '../../../../hooks/hrd/switch-shift-schedule/use-switch-shifts';
import { SwitchShiftSchduleInterface } from '../../../../models/interfaces/hrd/switch-shift-schedule';
import { UserRendererInterface } from '../../../common/fragments/user';

type RenderEmployeesSwitchShift = {
  useSwitchShifts: UseSwitchShifts;
  useUserAuthorization: UseUserAuthorization;
  useSwitchShift: UseSwitchShift;
  useRotationalShiftDay: UseRotationalShiftDay;
  useEmployeeProfiles: UseEmployeeProfiles;
  userRendererService: UserRendererInterface;
  switchShiftService: SwitchShiftSchduleInterface;
};

export const renderEmployeeSwitchShifts = ({
  useSwitchShifts,
  useUserAuthorization,
  useSwitchShift,
  useRotationalShiftDay,
  useEmployeeProfiles,
  userRendererService,
  switchShiftService,
}: RenderEmployeesSwitchShift) => {
  return function EmployeeSwitchShiftsPage(): JSX.Element {
    const {
      data: switchScheduleData,
      getAll: getAllSwitchShiftScheduleData,
      loading,
    } = useSwitchShifts();

    const { user: currentUser } = useContext(UserContext);

    const { Confirm, openConfirm } = useConfirm();

    const { syncSet: setSwitchShift } = useSwitchShift();

    const { syncSet: setRotationalShiftDay } = useRotationalShiftDay();

    const { data: employees, getAll: getAllEmployees } = useEmployeeProfiles();

    const [month, setMonth] = useState(
      localStorage.getItem('switch-shift-month') ||
        moment.utc().format('YYYY-MM')
    );
    useEffect(() => {
      getAllSwitchShiftScheduleData({
        date: month,
      });
      localStorage.setItem('switch-shift-month', month);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month]);

    useEffect(() => {
      getAllEmployees({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);

    // eslint-disable-next-line
    async function setRotationalShiftDayData(data: SwitchShiftScheduleType) {
      if (data.replaceEmployee.id !== data.currentEmployee.id) {
        await setRotationalShiftDay({
          id: data.currentShiftDay.id,
          employee: data.replaceEmployee,
          details: {
            shiftSwitches: [
              ...(data.currentShiftDay.details.shiftSwitches || []),
              {
                reason: data.details.reason,
                type: data.details.type,
                switchedWithEmployee: {
                  id: data.currentEmployee.id,
                  name: data.currentEmployee.name,
                },
                timeStamp: moment.utc().format(),
                switchedBy: currentUser,
              },
            ],
          },
        } as RotationalShiftDayType);

        // change employee 2 shift
        await setRotationalShiftDay({
          id: data.replaceShiftDay.id,
          employee: data.currentEmployee,
          details: {
            shiftSwitches: [
              ...(data.replaceShiftDay.details.shiftSwitches || []),
              {
                reason: data.details.reason,
                type: data.details.type,
                switchedWithEmployee: {
                  id: data.replaceEmployee.id,
                  name: data.replaceEmployee.name,
                },
                timeStamp: moment.utc().format(),
                switchedBy: currentUser,
              },
            ],
          },
        } as RotationalShiftDayType);
      } else {
        await setRotationalShiftDay({
          id: data.currentShiftDay.id,
          details: {
            shiftId: data.currentShiftDay.details?.newShiftId,
            newShiftId: { id: '', name: '' },
            shiftSwitches: [
              ...(data.currentShiftDay.details.shiftSwitches || []),
              {
                reason: data.details.reason,
                type: data.details.type,
                oldShift: data.currentShiftDay.details?.shiftId,
                timeStamp: moment.utc().format(),
                switchedBy: currentUser,
              },
            ],
          },
        } as RotationalShiftDayType);
      }
    }

    console.log(switchScheduleData);

    const AuthWrapper = renderAuthWrapper({ useUserAuthorization });

    const approveSwitchShiftRequest = async ({
      r,
    }: {
      r: SwitchShiftScheduleType;
    }) => {
      try {
        openConfirm({
          message: `Are you sure, accept switch schedule request for ${r.currentEmployee.name} & ${r.replaceEmployee.name}.`,
          onConfirm: async () => {
            // const final: Partial<SwitchShiftScheduleType> = {
            //   id: r.id,
            //   status: 'approved',
            // };
            // await setRotationalShiftDayData(r);
            // await setSwitchShift(final as SwitchShiftScheduleType);
            await switchShiftService.approveSwitchShift(r.id);
            getAllSwitchShiftScheduleData({
              date: month,
            });
          },
        });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const cancelSwitchShiftRequest = async ({
      r,
    }: {
      r: SwitchShiftScheduleType;
    }) => {
      try {
        openConfirm({
          message: `Are you sure, cancelling switch shift request for ${r.currentEmployee.name}.`,
          onConfirm: async () => {
            const final: Partial<SwitchShiftScheduleType> = {
              id: r.id,
              status: 'cancelled',
            };
            await setSwitchShift(final as SwitchShiftScheduleType);

            toast('Employee Switch Schedule Request Cancelled');
            getAllSwitchShiftScheduleData({
              date: month,
            });
          },
        });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Action = ({ r }: { r: SwitchShiftScheduleType }) => {
      return (
        <div className='flex flex-col space-y-2 items-start justify-start'>
          {r.status === 'pending' ? (
            <AuthWrapper
              action='UI:BTN-APRV-SHFT-SWCH:VIEW'
              children={
                <LoadingButton
                  behaviorFn={async () => {
                    await approveSwitchShiftRequest({ r });
                  }}
                  defaultStyle='font-semibold hover:text-black text-gray-600 w-fit text-xs text-left hover:underline'
                  text='Approve'
                />
              }
            />
          ) : null}
          {r.status === 'pending' ? (
            <LoadingButton
              behaviorFn={async () => {
                await cancelSwitchShiftRequest({ r });
              }}
              defaultStyle='font-semibold hover:text-black text-gray-600 w-fit text-xs text-left hover:underline'
              text='Cancel'
            />
          ) : null}
        </div>
      );
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Employee Switch Shifts',
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select Month</span>
              <MonthSelector
                format='YYYY-MM'
                initialState={month}
                onChange={(m) => setMonth(m)}
              />
            </div>
          ),
        },
      ],
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Date' },
        { name: 'Current Employee' },
        { name: 'Shift Id' },
        { name: 'Replace Shift With Employee' },
        { name: 'Shift Id' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: 'Reason',
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
        { name: 'Status' },
        { name: 'Actions' },
      ],
    ];

    const filteredTableBody: TableBody =
      switchScheduleData
        ?.filter((x) => {
          const emp = employees?.find((y) => x.currentEmployee.id === y.id);
          if (
            emp &&
            emp.details?.authorizations?.attendanceAuthorizer?.id ===
              currentUser.id
          ) {
            return true;
          }
          return false;
        })
        .map((r) => ({
          cells: [
            { value: r.currentShiftDay.date },
            {
              value: (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={r.currentEmployee?.id}
                  name={r.currentEmployee?.name}
                />
              ),
            },
            { value: r.currentShiftDay.details.shiftId?.name || '' },
            {
              value: (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={r.replaceEmployee.id}
                  name={r.replaceEmployee.name}
                />
              ),
            },
            {
              value:
                r.replaceShiftDay.id === r.currentShiftDay.id &&
                r.status === 'pending'
                  ? r.currentShiftDay.details?.newShiftId?.name || ''
                  : r.currentEmployee.id === r.replaceEmployee.id
                  ? r.replaceShiftDay?.details?.shiftSwitches &&
                    (r.replaceShiftDay.details.shiftSwitches[0] as any)
                      ?.oldShift?.name
                  : r.replaceShiftDay.details.shiftId?.name || '',
            },
            {
              value: (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={r.createdBy?.id}
                  name={r.createdBy?.name}
                  extraInfo={moment.utc(r.createdAt).fromNow()}
                />
              ),
            },
            {
              value: (
                <div title={r.details?.type}>
                  {r.details?.type === 'company-mandated' ? (
                    <MachineLearning className='w-5 inline text-blue-500 mr-1' />
                  ) : (
                    <UserIcon className='w-5 inline text-blue-500 mr-1' />
                  )}
                  {r.details?.reason}
                </div>
              ),
            },
            {
              value: (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={r.lastModifiedBy?.id}
                  name={r.lastModifiedBy?.name}
                  extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
                />
              ),
            },
            { value: r.status },
            {
              value: (
                <Popover className='relative'>
                  {({ open }) => (
                    <>
                      <Popover.Button>
                        <PencilIcon className='w-5 h-5 text-blue-500 hover:text-blue-600' />
                      </Popover.Button>
                      <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                        <Action r={r} />
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              ),
            },
          ],
        })) || [];

    const tableBody: TableBody =
      switchScheduleData?.map((r) => ({
        cells: [
          { value: r.currentShiftDay.date },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.currentEmployee?.id}
                name={r.currentEmployee?.name}
              />
            ),
          },
          { value: r.currentShiftDay.details.shiftId?.name || '' },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.replaceEmployee.id}
                name={r.replaceEmployee.name}
              />
            ),
          },
          {
            value:
              r.replaceShiftDay.id === r.currentShiftDay.id &&
              r.status === 'pending'
                ? r.currentShiftDay.details?.newShiftId?.name || ''
                : r.currentEmployee.id === r.replaceEmployee.id
                ? r.replaceShiftDay?.details?.shiftSwitches &&
                  (r.replaceShiftDay.details.shiftSwitches[0] as any)?.oldShift
                    ?.name
                : r.replaceShiftDay.details.shiftId?.name || '',
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <div title={r.details?.type}>
                {r.details?.type === 'company-mandated' ? (
                  <MachineLearning className='w-5 inline text-blue-500 mr-1' />
                ) : (
                  <UserIcon className='w-5 inline text-blue-500 mr-1' />
                )}
                {r.details?.reason}
              </div>
            ),
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.lastModifiedBy?.id}
                name={r.lastModifiedBy?.name}
                extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
              />
            ),
          },
          { value: r.status },
          {
            value: (
              <Popover className='relative'>
                {({ open }) => (
                  <>
                    <Popover.Button>
                      <PencilIcon className='w-5 h-5 text-blue-500 hover:text-blue-600' />
                    </Popover.Button>
                    <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                      <Action r={r} />
                    </Popover.Panel>
                  </>
                )}
              </Popover>
            ),
          },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: loading ? (
        <div className='flex my-24 justify-center'>
          <Recat className='h-5 inline animate-pulse mx-4' />
        </div>
      ) : (
        <div>
          <div className='my-5'>
            <Card
              header={{ title: 'Requests for you' }}
              body={{
                type: 'jsx-component',
                body: <Table header={tableHeader} body={filteredTableBody} />,
              }}
            />
          </div>
          <div className='my-5'>
            <Card
              header={{ title: 'All Requests' }}
              body={{
                type: 'jsx-component',
                body: <Table header={tableHeader} body={tableBody} />,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card header={cardHeader} body={cardBody} />
        <Confirm />
      </>
    );
  };
};
