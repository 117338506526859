import { Book } from '@erp_core/erp-icons/icons/web/book';
import { RuleRequestType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  MisconductRuleBookType,
  MisconductType,
} from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import {
  EyeIcon,
  PencilSquareIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { LocationContext } from '../../../../../contexts/location';
import { UserContext } from '../../../../../contexts/user';
import { UseUserAuthorization } from '../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseMisconductRuleBook } from '../../../../../hooks/hrd/disciplinary/misconduct-rule-book/use-misconduct-rule-book';
import { UseMisconducts } from '../../../../../hooks/hrd/disciplinary/misconduct/use-miscounducts';
import { UseRuleRequest } from '../../../../../hooks/hrd/disciplinary/rule-request/use-rule-request';
import { UseRuleRequests } from '../../../../../hooks/hrd/disciplinary/rule-request/use-rule-requests';
import { UserRendererInterface } from '../../../../common/fragments/user';
import { renderAddRuleRequestForm } from '../form/rule-request-addform';

export function renderRuleRequestDetails({
  useRuleRequests,
  useRuleRequest,
  useMisconductRuleBook,
  useMisconducts,
  useUserAuthorization,
  userRenderService,
}: {
  useRuleRequests: UseRuleRequests;
  useRuleRequest: UseRuleRequest;
  useMisconductRuleBook: UseMisconductRuleBook;
  useMisconducts: UseMisconducts;
  useUserAuthorization: UseUserAuthorization;
  userRenderService: UserRendererInterface;
}): () => JSX.Element {
  return function RuleRequest(): JSX.Element {
    const { user: currentUser } = useContext(UserContext);
    const { company, companyGroup } = useContext(LocationContext);

    const { syncSet: setRuleRequestStatus } = useRuleRequest();

    const { syncSet: setMisconductRuleBook } = useMisconductRuleBook();

    const { data: rules, getAll: getRules } = useRuleRequests();

    const { data: misconducts, getAll: getMisconducts } = useMisconducts();

    const [
      selectedMisconductData,
      setSelectedMisconduct,
    ] = useState<MisconductType | null>();

    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {}, [rules]);

    useEffect(() => {
      getRules();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {}, [misconducts]);

    useEffect(() => {
      getMisconducts();
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Rule Requests',
      icon: <Book className='inline w-6 text-blue-600 self-center' />,
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Description' },
        { name: 'Date' },
        { name: 'Status' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        { name: 'Actions' },
      ],
    ];

    const ApprovalForm = renderAddRuleRequestForm();

    // const Modal = renderModal();

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Approval',
          show: ({ ruleRequest }: { ruleRequest: RuleRequestType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['pending'].includes(ruleRequest.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Approval',
            content: ({
              data: { ruleRequest },
              onClose,
            }: {
              data: {
                ruleRequest: RuleRequestType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <ApprovalForm
                    data={ruleRequest}
                    onSave={async (e) => {
                      await approveRequest(e);
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
      ];
    }

    // const approvalAction = (r: RuleRequestType) => {
    //   setModal({
    //     ...modal,
    //     modalStyle: {
    //       minWidth: 'min-w-[90%]',
    //       minHeight: 'min-h-[90%]',
    //     },
    //     title: 'Approval',
    //     isVisible: true,
    //     body: <ApprovalForm data={r} onSave={approveRequest} />,
    //   });
    // };

    const AddMisconductRuleBook = async (data: RuleRequestType) => {
      var today = new Date();
      if (data.status === 'approved') {
        await setMisconductRuleBook(({
          description: data.description,
          effectiveFrom: moment(today).format('YYYY-MM-DD'),
          type: data.details?.type,
          status: 'approved',
          date: data.date,
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          company: {
            id: company.id,
            name: company.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as unknown) as MisconductRuleBookType);
      }
    };

    const approveRequest = async (data: RuleRequestType) => {
      try {
        const finalData = {
          ...data,
          authorizer: {
            id: currentUser.id,
            name: currentUser.name,
          },
        };
        await setRuleRequestStatus(finalData);
        // setModal((ms) => ({ ...ms, isVisible: false }));
        AddMisconductRuleBook(data);
        toast('Data updated sucessfully');
        getRules();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Action = ({ r }: { r: RuleRequestType }) => {
      return (
        <div>
          {/* {r.status === 'pending' ? (
            <button
              onClick={() => approvalAction(r)}
              className='font-semibold hover:text-black text-gray-600 w-fit text-xs text-left hover:underline'
            >
              Approval
            </button>
          ) : null} */}
        </div>
      );
    };

    const tableData =
      rules?.map((r, idx) => ({
        rowData: {
          ruleRequest: r,
        },
        cells: [
          { value: r.description },
          { value: r.date },
          { value: r.status },
          {
            value: (
              <userRenderService.userCard
                link={true}
                size='small'
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <>
                <Popover className='relative'>
                  {({ open }) => (
                    <>
                      <Popover.Button>
                        <PencilSquareIcon className='w-6 h-6 text-blue-600 cursor-pointer' />
                      </Popover.Button>
                      <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                        <Action r={r} />
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
                {misconducts?.find((x) => x.id === r.details?.misconductId) ? (
                  <EyeIcon
                    className='w-4 mx-1 inline'
                    onClick={() => {
                      setSelectedMisconduct(
                        misconducts.find(
                          (x) => x.id === r.details?.misconductId
                        )
                      );
                    }}
                  />
                ) : null}
              </>
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={addActions()}
              auth={{ actions: getAllowedActions().actions }}
            />
            {selectedMisconductData ? (
              <ShowMisconductRulesDetails
                misconductRcd={selectedMisconductData}
              />
            ) : null}
          </div>
        </div>
      ),
    };

    return (
      <>
        <div>
          <Card header={cardHeader} body={cardBody} />
          {/* <Modal {...modal} /> */}
        </div>
      </>
    );
  };
}

function ShowMisconductRulesDetails({
  misconductRcd,
}: {
  misconductRcd: MisconductType;
}): JSX.Element {
  return (
    <div className='border border-gray-200 rounded-lg p-2'>
      <div className='text-center text-lg font-semibold'>
        Employee Misconduct Details
      </div>
      <div className='p-2 border border-gray-200 flex flex-wrap'>
        <span className='inline-grid grid-cols-2 gap-4'>
          <span>
            <b>Employee Name:</b>{' '}
          </span>
          <span> {misconductRcd.employee.name} </span>
          <span>
            <b>Reason:</b>
          </span>
          <span>{misconductRcd.reason}</span>
        </span>
      </div>
    </div>
  );
}
