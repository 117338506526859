import { renderCardComponent } from '@erp_core/erp-ui-components';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { CGConfigRenderer } from '../config-renderer';
import { renderItemLabelTemplate } from './item-label';
import { renderMSDSTemplate } from './msds';
import { renderSpecificationsTemplate } from './specifications';

type CreateInventoryTemplatesPage = {
  useFileTransfer: UseFileTransfer;
};

export const createInventoryTemplatesPage = ({
  useFileTransfer,
}: CreateInventoryTemplatesPage) => {
  const Card = renderCardComponent();
  return function () {
    const handleMSDSTemplate = () => {
      const MSDSTemplate = renderMSDSTemplate({
        useFileTransfer,
      });
      return <MSDSTemplate />;
    };

    const handleItemLabelTemplate = () => {
      const ItemLabelTemplate = renderItemLabelTemplate({
        useFileTransfer,
      });
      return <ItemLabelTemplate />;
    };

    const handleSpecificationsTemplate = () => {
      const SpecificationsTemplate = renderSpecificationsTemplate({
        useFileTransfer,
      });
      return <SpecificationsTemplate />;
    };

    type Configs = {
      settingName: string;
      editAction: () => JSX.Element;
    };

    const configs: Configs[] = [
      {
        settingName: 'MSDS Template',
        editAction: handleMSDSTemplate,
      },
      {
        settingName: 'Specifications Template',
        editAction: handleSpecificationsTemplate,
      },
      {
        settingName: 'Item Label Template',
        editAction: handleItemLabelTemplate,
      },
    ];

    return (
      <div className='w-full'>
        <div className='flex flex-wrap'>
          {configs.map((config) => (
            <div className='w-1/2'>
              <Card
                header={{ title: config.settingName }}
                body={{
                  type: 'jsx-with-behaviour',
                  body: {
                    content: (
                      <CGConfigRenderer configs={[config]} loading={false} />
                    ),
                    behaviour: 'modal',
                    modal: {
                      title: config.settingName,
                      content: () => config.editAction(),
                    },
                  },
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };
};
