import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { useContext, useEffect, useState } from 'react';
import { renderTextEditor } from '../../../../../components/html-editor';
import { LocationContext } from '../../../../../contexts/location';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';

// eslint-disable-next-line
export function renderItemlabel({
  id,
  useFileTransfer,
}: {
  id: string;
  useFileTransfer: UseFileTransfer;
}): () => JSX.Element {
  const TextEditor = renderTextEditor();

  // eslint-disable-next-line
  return function ItemLabel() {
    // eslint-disable-next-line
    const { get: getTemplate, set: setTemplate } = useFileTransfer();
    const { companyGroup: currentCompanyGroup } = useContext(LocationContext);

    function onTemplateSave(data: any) {
      const file = new File([data], `${id.toLowerCase()}.html`, {
        type: 'text/plain',
      });
      setTemplate(
        `${currentCompanyGroup.id}/item-labels/${id.toLowerCase()}`,
        file,
        'html'
      );
    }

    function OpenLabelTemplate({
      path,
      onTemplateSave,
      getTemplate,
    }: {
      path: string;
      onTemplateSave: (data: any) => void;
      getTemplate: (path: string) => Promise<string>;
    }): JSX.Element {
      const [data, setData] = useState<string>('');
      useEffect(() => {
        getTemplate(path)
          .then((res) => {
            setData(res);
          })
          .catch((e) => console.log(e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      <TextEditor initialHtml={data} onSave={onTemplateSave} />;
      return (
        <>
          <TextEditor initialHtml={data} onSave={onTemplateSave} />
        </>
      );
    }

    const Card = renderCardComponent();

    const cardHeader: CardHeader = {
      title: 'Item Label',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <OpenLabelTemplate
            path={`${
              currentCompanyGroup.id
            }/item-labels/${id.toLowerCase()}.html`}
            onTemplateSave={onTemplateSave}
            getTemplate={getTemplate}
          />
          0
        </>
      ),
    };

    return (
      <>
        <Card body={cardBody} header={cardHeader} />
      </>
    );
  };
}
