import { EducationalDetails } from '@erp_core/erp-icons/icons/web/education-details';
import { ExcelDownload } from '@erp_core/erp-icons/icons/web/excel-download';
import { Hourglass } from '@erp_core/erp-icons/icons/web/hourglass';
import { PurchaseOrder } from '@erp_core/erp-icons/icons/web/purchase-order';
import { Tag } from '@erp_core/erp-icons/icons/web/tag';
import { UnpaidLeave } from '@erp_core/erp-icons/icons/web/unpaid-leave';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import { SalaryType } from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import {
  MonthSelector,
  renderBulkDownload,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import {
  ArrowRightOnRectangleIcon,
  BanknotesIcon,
  CalculatorIcon,
  CheckIcon,
  CurrencyRupeeIcon,
  HandRaisedIcon,
} from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../../contexts/location';
import { UseCompanyGroupSettings } from '../../../hooks/admin/company-group-setting/use-company-group-settings';
import { UseCurrentUserRoles } from '../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseHrdDashboardReport } from '../../../hooks/analytics/hrd-dashboard-report/use-hrd-dashboard-report';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseAttendances } from '../../../hooks/hrd/attendance/use-attendances';
import { UseDepartments } from '../../../hooks/hrd/department/use-departments';
import { UseMisconducts } from '../../../hooks/hrd/disciplinary/misconduct/use-miscounducts';
import { UseEmployeeRegistrations } from '../../../hooks/hrd/employee-registration-dtls/use-registrations';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UsePremiums } from '../../../hooks/hrd/insurance/premiums/use-premiums';
import { UsePayrollReport } from '../../../hooks/hrd/payroll-reports/use-payroll-report';
import { UseSalaryAdvances } from '../../../hooks/hrd/salary-advance/use-salary-advances';
import { UseSalaryParams } from '../../../hooks/hrd/salary-parameter/use-salary-params';
import { UseSalaryRevisions } from '../../../hooks/hrd/salary-revision/use-salary-revisions';
import { UseSalaries } from '../../../hooks/hrd/salary/use-salaries';
import { UseSalary } from '../../../hooks/hrd/salary/use-salary';
import { UseLoans } from '../../../hooks/loans/loan/use-loans';
import { UseRepaymentSchedules } from '../../../hooks/loans/repayment-schedule/use-repayment-schedules';
import { SalaryInterface } from '../../../models/interfaces/hrd/salary';
import { BulkSimulate } from './components/bulk-simulate';
import {
  CreateBatch,
  CreateRecalculateSalaryBatch,
} from './components/create-batch';
import { createStatutoryReportsModal } from './components/create-statutory-reports';
import { DynamicData } from './components/generate-salary';
import { createNewStatutoryReportsModal } from './components/new-create-statutory-reports';
import { EstimatePayableDays } from './components/payable-days-estimation';
import { RecalculateScreen } from './components/recalculate-salary';
import { SalaryComponents } from './components/salary-components';
import { renderSalaryDetailsOneLineReport } from './components/salary-details-one-line';
import { renderSalaryRegulatoryReport } from './components/salary-details-regulatory-report';
import { renderSalaryDetailsReport } from './components/salary-details-report';
import {
  renderSendBatchForRelease,
  renderSendRecalBatchForRelease,
} from './components/send-batch-for-release';
import { renderSimpleSalaryView } from './components/simple-salary-view';
import { DownloadSSEInvoice } from './components/sse-invoice';
import { renderStatutoryReportsV2 } from './components/statutory-reports-v2';
import { computeDynamicData } from './utils/compute-dynamic-data';
import { filter } from './utils/filter-employees';
import { renderForm27 } from './utils/form-27';

export type CreateSalaryPageProps = {
  usePayrollReport: UsePayrollReport;
  useSalaryParams: UseSalaryParams;
  useEmployees: UseEmployeeProfiles;
  useSalaries: UseSalaries;
  useUserAuthorization: UseUserAuthorization;
  useSalaryRevisions: UseSalaryRevisions;
  useSalary: UseSalary;
  useAttendances: UseAttendances;
  useFileTransfer: UseFileTransfer;
  useCurrentUserRoles: UseCurrentUserRoles;
  useCompanyGroupSettings: UseCompanyGroupSettings;
  useMisconducts: UseMisconducts;
  useSalaryAdvances: UseSalaryAdvances;
  usePremiums: UsePremiums;
  useLoans: UseLoans;
  useRepaymentSchedules: UseRepaymentSchedules;
  salaryService: SalaryInterface;
  useDepartments: UseDepartments;
  useHrdDashboardReport: UseHrdDashboardReport;
  useEmployeeRegistrations: UseEmployeeRegistrations;
};

export type SalarySummary = {
  employee: {
    id: string;
    name: string;
  };
  deductions: {
    penalty: number;
    salaryAdvance: number;
    loan: { principle: number; interest: number; total: number };
    insurance: number;
    adhoc: number;
  };
};

export function createSalaryPage({
  usePayrollReport,
  useFileTransfer,
  useCurrentUserRoles,
  useCompanyGroupSettings,
  useSalaryParams,
  useAttendances,
  useEmployees,
  useSalaries,
  useUserAuthorization,
  useSalaryRevisions,
  useSalary,
  useMisconducts,
  useSalaryAdvances,
  usePremiums,
  useLoans,
  useRepaymentSchedules,
  salaryService,
  useDepartments,
  useHrdDashboardReport,
  useEmployeeRegistrations,
}: CreateSalaryPageProps): () => JSX.Element {
  let Table = renderTableComponent();
  let Card = renderCardComponent();

  return function SalaryPage(): JSX.Element {
    const { data: currentUserRoles } = useCurrentUserRoles();
    const { data: misconducts, getAll: getMisconducts } = useMisconducts();
    const {
      company: currentCompany,
      companyGroup: currentCompanyGroup,
    } = useContext(LocationContext);
    const { data: departments, getAll: gteDepartments } = useDepartments();
    const {
      data: salaryAdvances,
      getAll: getSalaryAdvances,
    } = useSalaryAdvances();
    const { data: premiums, getAll: getPremiums } = usePremiums();
    const [companyGroupSetting, setCompanyGroupSetting] = useState<
      CompanyGroupSetting
    >({} as CompanyGroupSetting);

    const { getAllSync: getLoans } = useLoans();
    const { getAllSync: getRepaymentSchedules } = useRepaymentSchedules();
    const { getPayrollReport } = useHrdDashboardReport();

    const { getAll: getAllEmployees, data: emps } = useEmployees();
    const { getAllSync: getCompanyGroupSettings } = useCompanyGroupSettings();
    const [filteredEmployees, setFilteredEmployees] = useState<
      EmployeeProfileType[]
    >([]);
    const { getAll: getAllSalaries, data: salaries, loading } = useSalaries();
    const { syncSet: setSalary } = useSalary();
    const {
      getAll: getAllSalaryParams,
      data: salaryParams,
    } = useSalaryParams();
    const [selectedParams, setSelectedParams] = useState<SalaryParamType[]>([]);
    const {
      data: salaryRevisions,
      getAll: getAllSalaryRevisions,
    } = useSalaryRevisions();
    const { get: getAllowedActions } = useUserAuthorization();
    const { getAllSync: getRegistrations } = useEmployeeRegistrations();
    const [deductions, setDeductions] = useState<
      Array<{
        employee: { id: string; name: string };
        deduction: any;
      }>
    >([]);

    const { getAllSync: getAttendanceSync } = useAttendances();

    const [date, setDate] = useState(
      localStorage.getItem('salary-date') || moment.utc().format('YYYY-MM')
    );

    useEffect(() => {
      setFilteredEmployees(
        filter({
          employees: emps || [],
          currentUserRoles: currentUserRoles || [],
          month: date,
          salaries: salaries || [],
        })
      );

      salaryService.estimateDeductions({ month: date }).then((res) => {
        setDeductions(res || []);
      });

      // eslint-disable-next-line
    }, [emps, currentUserRoles, date, salaries]);

    useEffect(() => {
      getAllSalaryRevisions();
      getAllEmployees();
      getAllSalaries({
        month: date.split('-')[1],
        year: date.split('-')[0],
      });
      getAllSalaryParams();
      getCompanyGroupSettings().then((cgs) => {
        if (cgs.length > 0) setCompanyGroupSetting(cgs[0]);
      });
      getMisconducts();
      getSalaryAdvances();
      getPremiums();
      gteDepartments();
      // eslint-disable-next-line
    }, []);

    function ViewSalary({
      sal,
      emp,
      salParam,
      selSalRev,
      monthDetails,
      misconducts,
    }: {
      sal: SalaryType;
      salParam: SalaryParamType;
      selSalRev: SalaryRevisionType;
      emp: EmployeeProfileType;
      monthDetails: {
        month: number;
        year: number;
        monthName: string;
        totalDays: number;
        totalOfficialDays: number;
      };
      misconducts: MisconductType[];
    }) {
      const [dd, setDD] = useState<DynamicData | null>(null);

      useEffect(() => {
        getAttendanceSync({
          employeeId: `equal::${emp.id}`,
          from: `more-than::${date}-00`,
          to: `less-than::${date}-31`,
        }).then(async (attendanceData) => {
          const dd = await computeDynamicData({
            employee: emp,
            salaryRev: selSalRev.details,
            date,
            attendanceData,
            companyGroupSetting,
            simulate: false,
            monthDetails,
            misconducts,
            salaryAdvances: salaryAdvances || [],
            premiums: premiums || [],
            getLoans,
            getRepaymentSchedules,
          });
          setDD(dd);
        });
        // eslint-disable-next-line
      }, []);

      if (!dd) {
        return <div>Loading...</div>;
      }

      return (
        <SalaryComponents
          salaryId={sal.id}
          monthDetails={monthDetails}
          dd={dd}
          useFileTransfer={useFileTransfer}
          currentCompanyGroupId={currentCompanyGroup.id}
          salaryParam={salParam}
          salaryData={sal.details.salaryParams}
          employee={emp}
        />
      );
    }

    async function downloadSalaryRevisionReport(): Promise<
      {
        mapper: XlsxDownloadMapper;
        data: any[];
        fileName: string;
      }[]
    > {
      const finalResult: {
        mapper: XlsxDownloadMapper;
        data: any[];
        fileName: string;
      }[] = [];

      const result = filteredEmployees
        .filter(
          (x) =>
            x.details?.resigned !== 'yes' && x.details?.status !== 'on-strike'
        )
        .map((e) => {
          const salRev = _.chain(salaryRevisions)
            .filter(
              (x) =>
                x.employee.id.toLowerCase() === e.id.toLowerCase() &&
                x.status === 'published' &&
                moment(x.date).isSameOrBefore(moment(`${date}-01`))
            )
            .sortBy('revisionNum')
            .reverse()
            .first()
            .value();
          return {
            id: e?.details.employeeId,
            name: e.name,
            grade: e.details?.grade?.name || '-',
            department: e.department?.name || e.details?.department || '-',
            'sal-rev': salRev?.name || '',
            'sal-rev-ctc': salRev?.details?.ctcAnnual || '-',
            'sal-rev-date': salRev?.date || '-',
            'next-rev-date': e.details?.nextSalaryRevision || '-',
          };
        });

      const mapper: XlsxDownloadMapper = [
        {
          columnName: 'employee_name',
          dataBinding: { property: 'name' },
        },
        {
          columnName: 'employee_id',
          dataBinding: { property: 'id' },
        },
        {
          columnName: 'employee_grade',
          dataBinding: { property: 'grade' },
        },
        {
          columnName: 'employee_department',
          dataBinding: { property: 'department' },
        },
        {
          columnName: 'current_salary_rev',
          dataBinding: { property: 'sal-rev' },
        },
        {
          columnName: 'effective_from',
          dataBinding: { property: 'sal-rev-date' },
        },
        {
          columnName: 'current_ctc',
          dataBinding: { property: 'sal-rev-ctc' },
        },
        {
          columnName: 'next_rev_date',
          dataBinding: { property: 'next-rev-date' },
        },
      ];

      finalResult.push({
        mapper: mapper,
        data: result,
        fileName: `${currentCompany.shortName}-${date}-salary-revision-reports`,
      });

      return finalResult;
    }

    useEffect(() => {
      if (salaryParams?.length) {
        const result: SalaryParamType[] = [];

        salaryParams.forEach((s) => {
          const existing = result.find((x) => x.name === s.name);
          if (existing) {
            if (existing.revisionNum < s.revisionNum) {
              const index = result.findIndex((x) => x.id === existing.id);
              result.splice(index, 1);
              result.push(s);
            }
          } else {
            result.push(s);
          }
        });
        setSelectedParams(result);
      }
      // eslint-disable-next-line
    }, [salaryParams]);

    const SalaryRevReport = renderBulkDownload({
      mapperFun: downloadSalaryRevisionReport,
      downloadIcon: ExcelDownload,
    });

    const ComparisionReport = renderBulkDownload({
      downloadIcon: ExcelDownload,
      mapperFun: async () => {
        console.log(date);
        const { data } = await getPayrollReport(date, 'comparison-report');

        const finalResult: {
          mapper: XlsxDownloadMapper;
          data: any[];
          fileName: string;
        }[] = [];

        const mapper: XlsxDownloadMapper = [
          {
            columnName: 'Name',
            dataBinding: { property: 'name' },
          },
          {
            columnName: 'EmployeeTakeHome',
            dataBinding: { property: 'employeetakehome' },
          },
        ];

        finalResult.push({
          mapper: mapper,
          data: data,
          fileName: `comparison-report-${date}`,
        });

        return finalResult;
      },
    });

    const tableHeader = [
      [
        { name: 'Emp name' },
        { name: 'Summary' },
        { name: 'Bank' },
        { name: 'Status', style: 'text-center' },
      ],
    ];

    useEffect(() => {
      localStorage.setItem('salary-date', date);
      getAllSalaries({
        month: date.split('-')[1],
        year: date.split('-')[0],
      });
      // eslint-disable-next-line
    }, [date]);

    const tableBody: TableBody =
      filteredEmployees.map((e) => {
        const deduction = deductions?.find(
          (x) => x.employee.id === e.id.toUpperCase()
        );

        const salary = salaries?.find(
          (s) => s.employee.id === e.id.toLowerCase()
        );

        const salaryIssued = salary ? true : false;

        const salRev = _.chain(salaryRevisions)
          .filter(
            (x) =>
              x.employee.id.toLowerCase() === e.id.toLowerCase() &&
              x.status === 'published' &&
              moment(x.date).isSameOrBefore(moment(`${date}-01`))
          )
          .sortBy('revisionNum')
          .reverse()
          .first()
          .value();
        // const summary = salarySummary.find((x) => x.employee.id === e.id);
        const selectedParam = selectedParams.find(
          (x) => x.name === e.details.salaryParam?.name
        );
        const logo =
          salary?.bankAccountName &&
          currentCompany.details.bankAccounts.find(
            (x) => x.name === salary.bankAccountName
          )?.logo;

        let style = '';
        switch (salary?.status) {
          case 'approved': {
            style = 'text-green-600';
            break;
          }
          case 'pending': {
            style = 'text-orange-600';
            break;
          }
          case 'sent-for-release': {
            style = 'text-blue-600';
            break;
          }
          case 'released': {
            style = 'text-blue-600';
            break;
          }
        }

        return {
          rowData: {
            employee: e,
            salary: salary,
            salaryRevision: salRev,
            salaryParam: selectedParam,
          },
          cells: [
            {
              value: (
                <div>
                  <div className='text-lg'>
                    {e.name}
                    {salary?.details?.oldSalaryId ? (
                      <span
                        title='batch-number'
                        className='text-sm text-purple-500'
                      >
                        <CalculatorIcon className='text-purple-500 animate-pulse w-5 inline' />
                        recalculated
                      </span>
                    ) : null}
                    {salary?.releaseDetails?.batchNumber ? (
                      <span
                        title='batch-number'
                        className='text-sm text-blue-500'
                      >
                        <Tag className='text-blue-500 animate-pulse w-5 inline' />
                        {salary?.releaseDetails?.batchNumber}
                      </span>
                    ) : null}
                    {e.details.resigned === 'yes' ? (
                      <span className='text-sm text-red-500'>
                        <ArrowRightOnRectangleIcon className='text-red-500 animate-pulse w-5 inline' />
                        Resigned
                      </span>
                    ) : null}
                    {e.details.status === 'on-strike' ? (
                      <span className='text-sm text-purple-500'>
                        <HandRaisedIcon className='text-purple-500 animate-pulse w-5 inline' />
                        On-Strike
                      </span>
                    ) : null}
                  </div>

                  {salRev ? (
                    <div className='text-gray-900 text-sm'>
                      <EducationalDetails className='inline stroke-red-500 text-red-200 w-6' />
                      {salRev.name} |
                      <PurchaseOrder className='inline stroke-blue-600 text-blue-200 w-3 ml-4 mr-1' />
                      {selectedParam?.name}-{selectedParam?.revisionNum}
                    </div>
                  ) : (
                    <div>
                      <EducationalDetails className='inline stroke-red-500 text-red-200 w-6' />
                      <span className='italic text-gray-700 text-sm'>
                        No published Salary Revision
                      </span>
                    </div>
                  )}
                </div>
              ),
            },
            {
              value: (
                <div>
                  <div className='text-lg'>Deductions</div>
                  {deduction ? (
                    <div>
                      {deduction.deduction?.salaryAdvances ? (
                        <div>
                          Salary Advance:{' '}
                          {deduction.deduction?.salaryAdvances || 0}
                        </div>
                      ) : null}
                      {deduction.deduction?.insurancePremium ? (
                        <div>
                          Insurance:{' '}
                          {deduction.deduction?.insurancePremium || 0}
                        </div>
                      ) : null}
                      {deduction.deduction?.loanEmi?.total ? (
                        <div>
                          Loan: {deduction.deduction?.loanEmi?.total || 0}
                        </div>
                      ) : null}
                      {deduction.deduction?.incomeTax ? (
                        <div>
                          Income tax: {deduction.deduction?.incomeTax || 0}
                        </div>
                      ) : null}
                      {deduction.deduction?.fines ? (
                        <div>Fines: {deduction.deduction?.fines || 0}</div>
                      ) : null}
                      {deduction.deduction?.adhoc ? (
                        <div>Adhoc: {deduction.deduction?.adhoc || 0}</div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ),
            },
            {
              value: (
                <div className='text-center'>
                  {logo ? (
                    <img
                      src={logo}
                      className='h-4 mx-auto'
                      alt={salary?.bankAccountName}
                    />
                  ) : null}{' '}
                  <div>{salary?.bankAccountName || '-'}</div>
                  <div>{salary?.releaseDetails?.bulkTransactionId}</div>
                </div>
              ),
            },
            {
              style: 'text-center',
              value: (
                <span className='bg-gray-100 border rounded-lg p-1'>
                  {salaryIssued ? (
                    <>
                      <span className='text-green-700'>
                        {salary?.status === 'approved' ? (
                          <CheckIcon className='inline stroke-green-600 text-green-200 w-4' />
                        ) : null}
                      </span>
                      <span className='text-amber-700'>
                        {salary?.status === 'pending' ? (
                          <Hourglass className='inline stroke-orange-600 text-orange-200 w-4' />
                        ) : null}
                      </span>
                      <span className='text-blue-700'>
                        {salary?.status === 'sent-for-release' ? (
                          <BanknotesIcon className='inline stroke-blue-600 text-blue-200 w-4' />
                        ) : null}
                        {salary?.status === 'released' ? (
                          <BanknotesIcon className='inline stroke-blue-600 text-blue-200 w-4' />
                        ) : null}
                        <span className={style}>{salary?.status}</span>
                      </span>
                    </>
                  ) : (
                    <span className='text-red-700'>
                      <UnpaidLeave className='inline stroke-red-500 text-red-200 w-4' />
                      not created
                    </span>
                  )}
                </span>
              ),
            },
          ],
        };
      }) || [];

    const today = moment().format('YYYY-MM');

    return (
      <div className='pb-52'>
        <Card
          header={{
            title: 'Employee Salaries',
            icon: (
              <CurrencyRupeeIcon className='w-8 h-8 stroke-indigo-900 self-center' />
            ),
            subheading: (
              <div
                className='flex items-center italic text-blue-600'
                title={selectedParams
                  .map((selectedParam) => {
                    return `${selectedParam?.name || 'name'} - version ${
                      selectedParam.revisionNum || '?'
                    }`;
                  })
                  .join(', ')}
              >
                {selectedParams.length} Salary Params
              </div>
            ),
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
                    <span className='font-bold truncate'>Select date</span>
                    <MonthSelector
                      format='YYYY-MM'
                      initialState={date}
                      onChange={(m) => setDate(m)}
                    />
                  </div>
                ),
              },
            ],
            menu: {
              actions: [
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Bulk Generate',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Bulk Generate',
                    content: ({ onClose }) => {
                      return (
                        <BulkSimulate
                          salaryService={salaryService}
                          currentCompany={currentCompany}
                          filteredEmployees={filteredEmployees}
                          date={date}
                          salaryRevisions={salaryRevisions || []}
                          companyGroupSetting={companyGroupSetting}
                          selectedParams={selectedParams}
                        />
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'SSE Invoice',
                  behaviour: 'modal',
                  show: () => {
                    if (currentCompany.name === 'Shree Sai Enterprises') {
                      return true;
                    }
                    return false;
                  },
                  modal: {
                    title: 'SSE Invoice',
                    content: ({ onClose }) => {
                      return (
                        <DownloadSSEInvoice
                          useFileTransfer={useFileTransfer}
                          salaries={salaries || []}
                        />
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Send Batch for Release',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Send Batch for Salary Release',
                    content: ({ onClose }) => {
                      const SendBatchForRelease = renderSendBatchForRelease({
                        onClose,
                        useFileTransfer,
                        currentCompanyGroupId: currentCompanyGroup.id,
                        currentCompanyId: currentCompany.id,
                        usePayrollReport,
                      });
                      const e =
                        salaries?.filter(
                          (x) =>
                            ['approved'].includes(x.status) &&
                            !x.details?.oldSalaryId &&
                            filteredEmployees?.find(
                              (q) =>
                                q.id.toLowerCase() ===
                                x.employee.id.toLowerCase()
                            )
                        ) || [];
                      return (
                        <SendBatchForRelease
                          getRegistrations={getRegistrations}
                          setSalary={setSalary}
                          date={date}
                          salaries={
                            salaries?.filter(
                              (x) =>
                                x.status === 'approved' &&
                                !x.details?.oldSalaryId &&
                                x.company.id.toLowerCase() ===
                                  currentCompany.id.toLowerCase()
                            ) || []
                          }
                          data={e.map((x) => {
                            const employee = filteredEmployees?.find(
                              (q) =>
                                q.id.toLowerCase() ===
                                x.employee.id.toLowerCase()
                            ) as EmployeeProfileType;
                            return {
                              salary: {
                                ...x,
                                selectedSalaryParam: selectedParams.find(
                                  (x) =>
                                    x.name ===
                                    employee.details.salaryParam?.name
                                ) as SalaryParamType,
                              },
                              company: currentCompany,
                              salRevision: salaryRevisions?.find(
                                (s) => s.id === x.salaryRevision.id
                              ) as any,
                              bank:
                                currentCompany.details.bankAccounts.find(
                                  (y) => y.account === x.bankAccountNumber
                                ) || ({} as any),
                              employee,
                            };
                          })}
                          month={date}
                        />
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Send Recalculate Batch for Release',
                  behaviour: 'modal',
                  show: () => true,
                  modal: {
                    title: 'Send Recalculate Batch for Salary Release',
                    content: ({ onClose }) => {
                      const SendRecalBatchForRelease = renderSendRecalBatchForRelease(
                        {
                          onClose,
                          useFileTransfer,
                          currentCompanyGroupId: currentCompanyGroup.id,
                          currentCompanyId: currentCompany.id,
                          usePayrollReport,
                        }
                      );
                      const e =
                        salaries?.filter(
                          (x) =>
                            ['approved'].includes(x.status) &&
                            x.details?.oldSalaryId &&
                            x.details?.action === 'disburse' &&
                            filteredEmployees?.find(
                              (q) =>
                                q.id.toLowerCase() ===
                                x.employee.id.toLowerCase()
                            )
                        ) || [];
                      return (
                        <SendRecalBatchForRelease
                          getRegistrations={getRegistrations}
                          setSalary={setSalary}
                          date={date}
                          salaries={
                            salaries?.filter(
                              (x) =>
                                x.status === 'approved' &&
                                x.details?.oldSalaryId &&
                                x.details?.action === 'disburse' &&
                                x.details?.oldSalaryId &&
                                x.company.id.toLowerCase() ===
                                  currentCompany.id.toLowerCase()
                            ) || []
                          }
                          data={e.map((x) => {
                            const employee = filteredEmployees?.find(
                              (q) =>
                                q.id.toLowerCase() ===
                                x.employee.id.toLowerCase()
                            ) as EmployeeProfileType;
                            return {
                              salary: {
                                ...x,
                                selectedSalaryParam: selectedParams.find(
                                  (x) =>
                                    x.name ===
                                    employee.details.salaryParam?.name
                                ) as SalaryParamType,
                              },
                              company: currentCompany,
                              salRevision: salaryRevisions?.find(
                                (s) => s.id === x.salaryRevision.id
                              ) as any,
                              bank:
                                currentCompany.details.bankAccounts.find(
                                  (y) => y.account === x.bankAccountNumber
                                ) || ({} as any),
                              employee,
                            };
                          })}
                          month={date}
                        />
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Salary Revision Report',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Salary Revision Report',
                    content: ({ onClose }) => {
                      return (
                        <div className='mx-auto w-32 p-2 border border-green-500 rounded flex'>
                          <SalaryRevReport />
                          <span className='m-1'>Download</span>
                        </div>
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Estimate Payable Days',
                  behaviour: 'modal',
                  show: () => true,
                  modal: {
                    title: 'Estimate Payable Days',
                    content: ({ onClose }) => {
                      return (
                        <EstimatePayableDays
                          currentCompany={currentCompany}
                          filteredEmployees={filteredEmployees}
                          date={`${date}-01`}
                          getAttendanceSync={getAttendanceSync}
                        />
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Statutory Reports',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Statutory Reports',
                    content: ({ onClose }) => {
                      const Card = createStatutoryReportsModal();
                      const e =
                        salaries?.filter(
                          (x) =>
                            [
                              'approved',
                              'sent-for-release',
                              'released',
                            ].includes(x.status) &&
                            filteredEmployees?.find(
                              (q) =>
                                q.id.toLowerCase() ===
                                x.employee.id.toLowerCase()
                            )
                        ) || [];

                      return (
                        <Card
                          data={{
                            data: e.map((x) => {
                              const employee = filteredEmployees?.find(
                                (q) =>
                                  q.id.toLowerCase() ===
                                  x.employee.id.toLowerCase()
                              ) as EmployeeProfileType;
                              return {
                                salary: {
                                  ...x,
                                  selectedSalaryParam: selectedParams.find(
                                    (x) =>
                                      x.name ===
                                      employee.details.salaryParam?.name
                                  ) as SalaryParamType,
                                },
                                company: currentCompany,
                                salRevision: salaryRevisions?.find(
                                  (s) => s.id === x.salaryRevision.id
                                ) as any,
                                bank:
                                  currentCompany.details.bankAccounts.find(
                                    (y) => y.account === x.bankAccountNumber
                                  ) || ({} as any),
                                employee,
                              };
                            }),
                            month: date,
                          }}
                          getRegistrations={getRegistrations}
                        />
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'New Dept Statutory Reports',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Statutory Reports (Department)',
                    content: ({ onClose }) => {
                      const Card = createNewStatutoryReportsModal({
                        departments: departments || [],
                        getRegistrations,
                      });
                      const e =
                        salaries?.filter(
                          (x) =>
                            [
                              'approved',
                              'sent-for-release',
                              'released',
                            ].includes(x.status) &&
                            filteredEmployees?.find(
                              (q) =>
                                q.id.toLowerCase() ===
                                x.employee.id.toLowerCase()
                            )
                        ) || [];

                      return (
                        <Card
                          data={e.map((x) => {
                            const employee = filteredEmployees?.find(
                              (q) =>
                                q.id.toLowerCase() ===
                                x.employee.id.toLowerCase()
                            ) as EmployeeProfileType;
                            return {
                              salary: {
                                ...x,
                                selectedSalaryParam: selectedParams.find(
                                  (x) =>
                                    x.name ===
                                    employee.details.salaryParam?.name
                                ) as SalaryParamType,
                              },
                              company: currentCompany,
                              salRevision: salaryRevisions?.find(
                                (s) => s.id === x.salaryRevision.id
                              ) as any,
                              bank:
                                currentCompany.details.bankAccounts.find(
                                  (y) => y.account === x.bankAccountNumber
                                ) || ({} as any),
                              employee,
                            };
                          })}
                          month={date}
                        />
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Statutory Reports V2',
                  behaviour: 'modal',
                  show: () => true,
                  modal: {
                    title: 'Statutory Reports V2',
                    content: ({ onClose }) => {
                      const Card = renderStatutoryReportsV2({
                        getPayrollReport,
                      });
                      return <Card month={date} />;
                    },
                  },
                },
                {
                  auth: 'UI:EXEC-SAL-REP:VIEW',
                  name: 'Salary Comparison Report',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Salary Comparison Report',
                    content: ({ onClose }) => {
                      return (
                        <div className='mx-auto w-32 p-2 border border-green-500 rounded flex'>
                          <ComparisionReport />
                          <span className='m-1'>Download</span>
                        </div>
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Salary Sheet - Regulatory',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    size: 'large',
                    title: 'Salary Sheet - Regulatory',
                    content: ({ onClose }) => {
                      const SalaryRegulatoryReport = renderSalaryRegulatoryReport();
                      const e =
                        salaries?.filter(
                          (x) =>
                            [
                              'approved',
                              'sent-for-release',
                              'released',
                            ].includes(x.status) &&
                            filteredEmployees?.find(
                              (q) =>
                                q.id.toLowerCase() ===
                                x.employee.id.toLowerCase()
                            )
                        ) || [];

                      return (
                        <SalaryRegulatoryReport
                          srList={{
                            data: e.map((x) => {
                              const employee = filteredEmployees?.find(
                                (q) =>
                                  q.id.toLowerCase() ===
                                  x.employee.id.toLowerCase()
                              ) as EmployeeProfileType;

                              return {
                                salary: {
                                  ...x,
                                  selectedSalaryParam: selectedParams.find(
                                    (x) =>
                                      x.name ===
                                      employee.details.salaryParam?.name
                                  ) as SalaryParamType,
                                },
                                salRevision: salaryRevisions?.find(
                                  (s) => s.id === x.salaryRevision.id
                                ) as any,
                                bank:
                                  currentCompany.details.bankAccounts.find(
                                    (y) => y.account === x.bankAccountNumber
                                  ) || ({} as any),
                                company: currentCompany,
                                employee,
                              };
                            }),
                            month: date,
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Salary Sheet - Details',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    size: 'large',
                    title: 'Salary Sheet - Details',
                    content: ({ onClose }) => {
                      const SalaryDetailsReport = renderSalaryDetailsReport();
                      const e =
                        salaries?.filter(
                          (x) =>
                            [
                              'approved',
                              'sent-for-release',
                              'released',
                            ].includes(x.status) &&
                            filteredEmployees?.find(
                              (q) =>
                                q.id.toLowerCase() ===
                                x.employee.id.toLowerCase()
                            )
                        ) || [];

                      return (
                        <SalaryDetailsReport
                          srList={{
                            data: e.map((x) => {
                              const employee = filteredEmployees?.find(
                                (q) =>
                                  q.id.toLowerCase() ===
                                  x.employee.id.toLowerCase()
                              ) as EmployeeProfileType;

                              return {
                                salary: {
                                  ...x,
                                  selectedSalaryParam: selectedParams.find(
                                    (x) =>
                                      x.name ===
                                      employee.details.salaryParam?.name
                                  ) as SalaryParamType,
                                },
                                salRevision: salaryRevisions?.find(
                                  (s) => s.id === x.salaryRevision.id
                                ) as any,
                                bank:
                                  currentCompany.details.bankAccounts.find(
                                    (y) => y.account === x.bankAccountNumber
                                  ) || ({} as any),
                                company: currentCompany,
                                employee,
                              };
                            }),
                            month: date,
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Salary Sheet One line - Details',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    size: 'large',
                    title: 'Salary Sheet - Details',
                    content: ({ onClose }) => {
                      const SalaryDetailsReport = renderSalaryDetailsOneLineReport();
                      const e =
                        salaries?.filter(
                          (x) =>
                            [
                              'approved',
                              'sent-for-release',
                              'released',
                            ].includes(x.status) &&
                            filteredEmployees?.find(
                              (q) =>
                                q.id.toLowerCase() ===
                                x.employee.id.toLowerCase()
                            )
                        ) || [];

                      return (
                        <SalaryDetailsReport
                          srList={{
                            data: e.map((x) => {
                              const employee = filteredEmployees?.find(
                                (q) =>
                                  q.id.toLowerCase() ===
                                  x.employee.id.toLowerCase()
                              ) as EmployeeProfileType;

                              return {
                                salary: {
                                  ...x,
                                  selectedSalaryParam: selectedParams.find(
                                    (x) =>
                                      x.name ===
                                      employee.details.salaryParam?.name
                                  ) as SalaryParamType,
                                },
                                salRevision: salaryRevisions?.find(
                                  (s) => s.id === x.salaryRevision.id
                                ) as any,
                                bank:
                                  currentCompany.details.bankAccounts.find(
                                    (y) => y.account === x.bankAccountNumber
                                  ) || ({} as any),
                                company: currentCompany,
                                employee,
                              };
                            }),
                            month: date,
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                  name: 'Form 27',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    size: 'large',
                    title: 'Form 27 Details',
                    content: ({ onClose }) => {
                      const Form27 = renderForm27({
                        useEmployees,
                        month: date,
                        salaries: salaries || [],
                      });

                      return <Form27 />;
                    },
                  },
                },
              ],
            },
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                {date <= today ? (
                  <Table
                    isDataLoading={loading}
                    multiSelect={{
                      actions: [
                        {
                          name: 'Create Batch',
                          show: () => true,
                          behaviour: 'modal',
                          modal: {
                            title: 'Create Batch',
                            content: ({ entries, onClose }) => {
                              const result = filteredEmployees
                                .filter((a, idx) => entries.includes(idx))
                                .map((e) => {
                                  const salary = salaries?.find(
                                    (s) =>
                                      s.employee.id === e.id.toLowerCase() &&
                                      s.status === 'approved'
                                  );
                                  return {
                                    employee: e,
                                    salary: salary,
                                  };
                                });

                              if (
                                result.find(
                                  (x) =>
                                    x.salary?.status !== 'approved' ||
                                    x.salary?.releaseDetails?.batchNumber ||
                                    x.salary?.details?.oldSalaryId
                                )
                              ) {
                                alert(
                                  'Some of the selected entries either are not in approved state or are already part of a batch or are recalculated salaries'
                                );
                                return <></>;
                              }

                              const e =
                                salaries?.filter(
                                  (x) =>
                                    x.status === 'approved' &&
                                    result?.find(
                                      (q) =>
                                        q.employee.id.toLowerCase() ===
                                          x.employee.id.toLowerCase() &&
                                        !q.salary?.releaseDetails?.batchNumber
                                    )?.employee
                                ) || [];

                              return (
                                <div>
                                  <CreateBatch
                                    setSalary={setSalary}
                                    onClose={onClose}
                                    data={{
                                      data: e.map((x) => {
                                        const employee = result.find(
                                          (q) =>
                                            q.employee.id.toLowerCase() ===
                                            x.employee.id.toLowerCase()
                                        )?.employee as EmployeeProfileType;
                                        return {
                                          salary: {
                                            ...x,
                                            selectedSalaryParam: selectedParams.find(
                                              (x) =>
                                                x.name ===
                                                employee.details.salaryParam
                                                  ?.name
                                            ) as SalaryParamType,
                                          },
                                          company: currentCompany,
                                          salRevision: salaryRevisions?.find(
                                            (s) => s.id === x.salaryRevision.id
                                          ) as any,
                                          bank:
                                            currentCompany.details.bankAccounts.find(
                                              (y) =>
                                                y.account ===
                                                x.bankAccountNumber
                                            ) || ({} as any),
                                          employee,
                                        };
                                      }),
                                      month: date,
                                    }}
                                  />
                                </div>
                              );
                            },
                          },
                        },
                        {
                          show: () => true,
                          behaviour: 'modal',
                          name: 'Create Recalculate Batch',
                          modal: {
                            title: 'Create Recalculate Batch',
                            content: ({ entries, onClose }) => {
                              const result = filteredEmployees
                                .filter((a, idx) => entries.includes(idx))
                                .map((e) => {
                                  const salary = salaries?.find(
                                    (s) =>
                                      s.employee.id === e.id.toLowerCase() &&
                                      s.status === 'approved' &&
                                      s.details?.oldSalaryId &&
                                      !s.releaseDetails?.batchNumber &&
                                      s.details?.action === 'disburse'
                                  );
                                  return {
                                    employee: e,
                                    salary: salary,
                                  };
                                });

                              if (
                                result.find(
                                  (x) =>
                                    !x.salary ||
                                    !x.salary?.details?.oldSalaryId ||
                                    x.salary?.status !== 'approved' ||
                                    x.salary?.releaseDetails?.batchNumber ||
                                    x.salary?.details?.action !== 'disburse'
                                )
                              ) {
                                alert(
                                  'Some of the selected entries either are not recalculated salaries or they are not in approved state or are already part of a batch or doesnt isnt of disburse category'
                                );
                                return <></>;
                              }

                              return (
                                <div>
                                  <CreateRecalculateSalaryBatch
                                    setSalary={setSalary}
                                    onClose={onClose}
                                    list={result as any}
                                  />
                                </div>
                              );
                            },
                          },
                        },
                      ],
                    }}
                    header={tableHeader}
                    body={tableBody}
                    actions={[
                      {
                        name: 'Simulate Real Days Salary',
                        auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                        show: () => true,
                        behaviour: 'modal',
                        modal: {
                          title: 'Simulate Salary V2 with real data',
                          size: 'large',
                          content: ({
                            data: { employee, salaryRevision, salaryParam },
                            onClose,
                          }) => {
                            const SalV2 = renderSimpleSalaryView({
                              employee,
                              salaryRevision,
                              selectedParam: salaryParam,
                              salaryService,
                              availableSalaryRevisions:
                                salaryRevisions?.filter(
                                  (s) =>
                                    s.employee.id.toLowerCase() ===
                                    employee.id.toLowerCase()
                                ) || [],
                              mode: 'real',
                              persist: false, // This ensures if a salary is saved or not
                            });

                            return (
                              <SalV2
                                companyGroupSetting={companyGroupSetting}
                                date={date}
                              />
                            );
                          },
                        },
                      },
                      {
                        name: 'Simulate Salary',
                        auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                        show: () => true,
                        behaviour: 'modal',
                        modal: {
                          title: 'Simulate Salary V2 with Fake days data',
                          size: 'large',
                          content: ({
                            data: { employee, salaryRevision, salaryParam },
                            onClose,
                          }) => {
                            const SalV2 = renderSimpleSalaryView({
                              employee,
                              salaryRevision,
                              selectedParam: salaryParam,
                              salaryService,
                              mode: 'simulate',
                              availableSalaryRevisions:
                                salaryRevisions?.filter(
                                  (s) =>
                                    s.employee.id.toLowerCase() ===
                                    employee.id.toLowerCase()
                                ) || [],
                              persist: false, // This ensures if a salary is saved or not
                            });

                            return (
                              <SalV2
                                companyGroupSetting={companyGroupSetting}
                                date={date}
                              />
                            );
                          },
                        },
                      },
                      {
                        name: 'Generate Salary V2 (Persist)',
                        auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                        show: ({
                          employee,
                          salary,
                          salaryRevision,
                          salaryParam,
                        }) => {
                          if (!salary && salaryParam && salaryRevision) {
                            return true;
                          }

                          return false;
                        },
                        modal: {
                          title: 'Generate Salary V2 and Persist',
                          size: 'large',
                          content: ({
                            data: { employee, salaryRevision, salaryParam },
                            onClose,
                          }) => {
                            const SalV2 = renderSimpleSalaryView({
                              employee,
                              salaryRevision,
                              selectedParam: salaryParam,
                              salaryService,
                              mode: 'real',
                              availableSalaryRevisions: [],
                              persist: true, // This ensures if a salary is saved or not
                            });

                            return (
                              <SalV2
                                companyGroupSetting={companyGroupSetting}
                                date={date}
                              />
                            );
                          },
                        },
                        behaviour: 'modal',
                      },
                      {
                        auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                        name: 'Recalculate Salary V2',
                        show: ({
                          employee,
                          salary,
                          salaryRevision,
                          salaryParam,
                        }) => {
                          if (
                            salary &&
                            salaryParam &&
                            salaryRevision &&
                            salary.status === 'released'
                          ) {
                            return true;
                          }

                          return false;
                        },
                        behaviour: 'modal',
                        modal: {
                          title: 'Recalculate Salary V2',
                          size: 'large',
                          content: ({
                            data: {
                              employee,
                              salary,
                              salaryRevision,
                              salaryParam,
                            },
                            onClose,
                          }) => {
                            return (
                              <RecalculateScreen
                                employee={employee}
                                salaryId={salary.id}
                                salaryParam={salaryParam}
                                salaryRevisionId={salaryRevision.id}
                                companyGroupSetting={companyGroupSetting}
                                month={date}
                                salaryService={salaryService}
                                onClose={onClose}
                              />
                            );
                          },
                        },
                      },
                      {
                        auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                        name: 'View Recalculate Summary',
                        show: ({
                          employee,
                          salary,
                          salaryRevision,
                          salaryParam,
                        }) => {
                          if (
                            salary?.details?.oldSalaryId &&
                            salary?.details?.requestText
                          ) {
                            return true;
                          }

                          return false;
                        },
                        behaviour: 'modal',
                        modal: {
                          title: 'View Recalculate Summary',
                          size: 'normal',
                          content: ({
                            data: {
                              employee,
                              salary,
                              salaryRevision,
                              salaryParam,
                            },
                            onClose,
                          }) => {
                            return <div>{salary.details?.requestText}</div>;
                          },
                        },
                      },
                      {
                        auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                        name: 'View Salary',
                        show: ({
                          employee,
                          salary,
                          salaryRevision,
                          salaryParam,
                        }) => {
                          if (salaryParam && salary) {
                            return true;
                          }
                          return false;
                        },
                        behaviour: 'modal',
                        modal: {
                          title: 'View Salary',
                          size: 'large',
                          content: ({
                            data: {
                              employee,
                              salary,
                              salaryRevision,
                              salaryParam,
                            },
                            onClose,
                          }) => {
                            const dt = `${date}-01`;

                            const monthDetails = {
                              month: parseInt(
                                moment(dt, 'YYYY-MM-DD').format('MM')
                              ),
                              year: moment(dt, 'YYYY-MM-DD').year(),
                              monthName: moment(dt, 'YYYY-MM-DD').format('MMM'),
                              totalDays: moment(dt, 'YYYY-MM-DD').daysInMonth(),
                              totalOfficialDays: moment(
                                dt,
                                'YYYY-MM-DD'
                              ).daysInMonth(),
                            };
                            const joiningDate = employee.details?.workTimeLines?.find(
                              (x) => x.activity === 'joined'
                            )?.date;
                            const joiningIsSameAsCurrentMonth =
                              moment(joiningDate).format('YYYY-MM') ===
                              moment(date).format('YYYY-MM');
                            const resignedDate = employee.details?.workTimeLines?.find(
                              (x) => x.activity === 'resigned'
                            )?.date;
                            const resignedIsSameAsCurrentMonth =
                              moment(resignedDate).format('YYYY-MM') ===
                              moment(date).format('YYYY-MM');

                            if (joiningIsSameAsCurrentMonth) {
                              monthDetails.totalOfficialDays =
                                monthDetails.totalOfficialDays -
                                parseInt(moment(joiningDate).format('DD')) +
                                1;
                            }

                            if (resignedIsSameAsCurrentMonth) {
                              monthDetails.totalOfficialDays =
                                monthDetails.totalOfficialDays -
                                (moment(date, 'YYYY-MM-DD').daysInMonth() -
                                  parseInt(moment(resignedDate).format('DD')));
                            }

                            return ViewSalary({
                              sal: salary,
                              salParam: salaryParam,
                              selSalRev: salaryRevision,
                              emp: employee,
                              monthDetails,
                              misconducts: misconducts || [],
                            });
                          },
                        },
                      },
                      {
                        auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                        name: 'Set Bank',
                        show: ({
                          employee,
                          salary,
                          salaryRevision,
                          salaryParam,
                        }) => {
                          if (
                            salaryParam &&
                            salary &&
                            salary.status === 'pending'
                          ) {
                            return true;
                          }
                          return false;
                        },
                        behaviour: 'modal',
                        modal: {
                          title: 'Set Bank',
                          content: ({
                            data: {
                              employee,
                              salary,
                              salaryRevision,
                              salaryParam,
                            },
                            onClose,
                          }) => {
                            const Form = renderFormV2<{
                              bankName: string;
                            }>({
                              formName: 'Set Bank',
                              fieldsData: [
                                {
                                  type: 'input',
                                  property: 'bankName',
                                },
                              ],
                              initialFormState: {
                                bankName: salary.bankAccountName || '',
                              },
                              onSubmit: async (data) => {
                                await setSalary({
                                  id: salary.id,
                                  bankAccountName: data.bankName,
                                } as SalaryType);
                                getAllSalaries({
                                  month: date.split('-')[1],
                                  year: date.split('-')[0],
                                });
                                onClose();
                              },
                              mapTToU: (p) => {
                                return p as any;
                              },
                            });
                            return <Form />;
                          },
                        },
                      },
                      {
                        auth: 'UI:BTN-GENERATE-SALARY:VIEW',
                        name: 'Approve',
                        show: ({
                          employee,
                          salary,
                          salaryRevision,
                          salaryParam,
                        }) => {
                          if (
                            salaryParam &&
                            salary &&
                            salary.status === 'pending'
                          ) {
                            return true;
                          }
                          return false;
                        },
                        behaviour: 'confirm',
                        onConfirm: ({
                          employee,
                          salary,
                          salaryRevision,
                          salaryParam,
                        }) => {
                          return {
                            title: `Are you sure you want to approve ${employee.name}'s Salary ?`,
                            type: 'warning',
                            onConfirm: async () => {
                              await setSalary({
                                id: salary.id,
                                status: 'approved',
                              } as SalaryType);
                              getAllSalaries({
                                month: date.split('-')[1],
                                year: date.split('-')[0],
                              });
                            },
                          };
                        },
                      },
                      {
                        auth: 'UI:BTN-DELETE-SALARY:VIEW',
                        name: 'Delete',
                        show: ({
                          employee,
                          salary,
                          salaryRevision,
                          salaryParam,
                        }) => {
                          if (
                            (salaryParam &&
                              salary &&
                              salary?.status === 'pending') ||
                            salary?.status === 'approved'
                          ) {
                            return true;
                          }
                          return false;
                        },
                        behaviour: 'confirm',
                        onConfirm: ({
                          employee,
                          salary,
                          salaryRevision,
                          salaryParam,
                        }) => {
                          return {
                            title: `Are you sure you want to delete ${employee.name}'s Salary ?`,
                            type: 'warning',
                            onConfirm: async () => {
                              await setSalary({
                                id: salary.id,
                                disabled: true,
                              } as SalaryType);
                              getAllSalaries({
                                month: date.split('-')[1],
                                year: date.split('-')[0],
                              });
                            },
                          };
                        },
                      },
                    ]}
                    auth={{ actions: getAllowedActions().actions }}
                  />
                ) : (
                  <div className='w-full h-48 font-semibold flex justify-center items-center'>
                    Invalid date, select date till {today}
                  </div>
                )}
              </div>
            ),
          }}
          auth={{ actions: getAllowedActions().actions }}
        />
      </div>
    );
  };
}
