import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import { UserContext } from '../../../../contexts/user';
import { ChangeAssesmentType } from '../../../../models/types/quality-assurance/change-assesment';

// eslint-disable-next-line

export type ManagementCommentsType = {
  comments: string;
  approval: string;
};

export function renderManagementCommentsForm() {
  return function AddManagementCommentsForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    isEdit?: boolean;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const { user: currentUser } = useContext(UserContext);

    const formProps: FormProps<ManagementCommentsType> = {
      fieldsData: [
        {
          type: 'text-area',
          label: 'Comments',
          property: 'comments',
        },
        {
          type: 'select',
          label: 'Status',
          property: 'approval',
          options: [
            { value: 'select', text: 'select' },
            { value: 'accepted', text: 'accepted' },
            {
              value: 'accepted with modifications',
              text: 'accepted with modifications',
            },
            { value: 'rejected', text: 'rejected' },
          ],
        },
      ],
      initialFormState: {
        comments: '',
        approval: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        // const managementComiteeComments = data?.details.managementComiteeComments
        await onSave(({
          details: {
            ...data?.details,
            managementCommitteeComments: {
              comments: f.comments,
              commentedBy: {
                id: currentUser.id,
                name: currentUser.name,
              },
              approval: f.approval,
            },
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<ManagementCommentsType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<ManagementCommentsType>
    ): ManagementCommentsType {
      return (b as unknown) as ManagementCommentsType;
    }
  };
}
