import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import _ from 'lodash';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../../../contexts/location';
import { UseGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';
import { UseGodownsTree } from '../../../../hooks/inventory/godown-inventory/use-godowns-tree';
import { FileTransferInterface } from '../../../../models/interfaces/fileTransfer/fileTransfer';
import { TreeIcon } from '../../../../utils/common';
import { createGodownSideView } from './godown-side-view';

type TreeNodeType = {
  type: string;
  key: string;
  isLeaf: boolean;
  children: Array<TreeNodeType>;
  title: string;
};

type RenderGodownTree = {
  useGodownsTree: UseGodownsTree;
  useGodown: UseGodown;
  fileTransferService: FileTransferInterface;
};

export function renderGodownTree({
  useGodownsTree,
  useGodown,
  fileTransferService,
}: RenderGodownTree): () => JSX.Element {
  return function GodownTree() {
    const { data: godowns, getAll } = useGodownsTree();

    const { data: currentGodown, get } = useGodown();

    const { location } = useContext(LocationContext);

    const [nodeData, setNodeData] = useState<TreeNodeType>();

    useEffect(() => {
      if (godowns && location) {
        setNodeData(renderTreeNode(location, _.sortBy(godowns, 'name')));
      }
    }, [godowns, location]);

    const onRightClick = (event, node) => {
      if (node.key && node.key !== 'root') get(node.key);
    };

    const GodownSideView = createGodownSideView({
      useGodown,
      fileTransferService,
    });

    return (
      <div className='flex min-h-96'>
        {nodeData ? (
          <Tree
            onClick={onRightClick}
            onCheck={onRightClick}
            defaultExpandAll
            className='max-h-[600px] p-2 text-lg scrollbar-none overflow-y-auto w-3/5'
            treeData={[renderTree(nodeData)]}
          />
        ) : null}
        {currentGodown && currentGodown.id ? (
          <GodownSideView
            reload={() => {
              get(currentGodown.id);
              getAll();
            }}
            godownData={currentGodown}
          />
        ) : null}
      </div>
    );
  };
}

function renderTreeNode(location: LocationType, godowns: Array<Godown>) {
  const data: TreeNodeType = {
    type: 'root',
    key: 'root',
    isLeaf: false,
    children: [],
    title: location.name || 'Root',
  };

  const recursiveFun = (godowns: Godown[], parent): TreeNodeType[] => {
    return godowns
      .filter((g) => g.parentGodownId === parent)
      .map((g) => {
        return {
          type: 'godown',
          key: g.id,
          isLeaf: false,
          children: recursiveFun(godowns, g.id),
          title: g.legacyGodownId || g.name,
        };
      });
  };
  data.children = recursiveFun(godowns, 'root');
  return data;
}

function renderTree(data: TreeNodeType) {
  const result = {
    key: data.key,
    children: data.children.map((d) => renderTree(d)),
    title: data.title,
    icon: (
      <div className='w-5 text-indigo-900 self-center'>
        <TreeIcon type={data.type} />
      </div>
    ),
    expandAction: 'click',
  };
  return result;
}
